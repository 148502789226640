<template>
  <div>
    <!-- 价格-框 -->
    <div class="price-wrap">
      <div class="one-line">
        <!-- 订单总览 -->
        {{L.orderSummary}}
      </div>
      <div class="two-line">
        <div class="total-price">
          <!-- 不含税总金额 -->
          <b>{{L.totalAmount}}:</b>
          <!-- 个产品项 -->
          <span>({{$store.state.cartCount}} {{L.orderltems}})</span>
        </div>
        <div class="price">
          <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
            {{formatMoney(deliveryData.totalPrice)}}
          </template>
        </div>
        <div class="delivery-btn">
          <!-- v-show="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('COR')" -->
          <!-- 去结算 -->
          <span 
            class="btn" 
            :class="{'btn-active':$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('COR') && $store.state.userInfo.accountRules.includes('CSB')}"
            @click="placeOrder"
          >{{L.goCheckout}}</span>
        </div>
      </div>
    </div>
    <!-- 客户信息 -->
    <div class="customer">
      <div class="title">
        <!-- 客户信息 -->
        {{L.customerInfo}}
      </div>
      <div class="content">
        <!-- sold to暂时先不展示 -->
        <div class="line" v-if="false">
          <div class="left">
            <!-- 售至 -->
            <span class="left-text1">{{L.soldTo}}:</span>
          </div>
          <div class="right">
            <span class="right-text1">{{addressInfo.soldTo && addressInfo.soldTo[0] && addressInfo.soldTo[0].addressTitle}} {{addressInfo.soldTo && addressInfo.soldTo[0] && addressInfo.soldTo[0].siteUseLocation}}</span>
            <span class="right-text2">{{addressInfo.soldTo && addressInfo.soldTo[0] && addressInfo.soldTo[0].addressInfo}}</span>
          </div>
        </div>
        <div class="line">
          <div class="left">
            <!-- 发货至 -->
            <span class="left-text1">{{L.shipTo}}:</span>
            <span class="left-text2">*</span>
          </div>
          <div class="right">
            <span class="right-text1">{{addressInfo.shipTo && addressInfo.shipTo[0] && addressInfo.shipTo[0].addressTitle}} {{addressInfo.shipTo && addressInfo.shipTo[0] && addressInfo.shipTo[0].siteUseLocation}}</span>
            <span class="right-text2">{{addressInfo.shipTo && addressInfo.shipTo[0] && addressInfo.shipTo[0].addressInfo}}</span>
          </div>
        </div>
        <!-- 只有存在的时候才显示 -->
        <div class="line" v-if="addressInfo.deliveryTo && addressInfo.deliveryTo.length>0">
          <div class="left">
            <!-- 送货方 -->
            <span class="left-text1">{{L.deliveryTo}}:</span>
            <span class="left-text2">*</span>
          </div>
          <div class="right">
            <span class="right-text1">{{addressInfo.deliveryTo && addressInfo.deliveryTo[0] && addressInfo.deliveryTo[0].addressTitle}} {{addressInfo.deliveryTo && addressInfo.deliveryTo[0] && addressInfo.deliveryTo[0].siteUseLocation}}</span>
            <span class="right-text2">{{addressInfo.deliveryTo && addressInfo.deliveryTo[0] && addressInfo.deliveryTo[0].addressInfo}}</span>
          </div>
        </div>
        <div class="line">
          <div class="left">
            <!-- 开票至 -->
            <span class="left-text1">{{L.billTo}}:</span>
            <span class="left-text2">*</span>
          </div>
          <div class="right">
            <span class="right-text1">{{addressInfo.billTo && addressInfo.billTo[0] && addressInfo.billTo[0].addressTitle}} {{addressInfo.billTo && addressInfo.billTo[0] && addressInfo.billTo[0].siteUseLocation}}</span>
            <span class="right-text2">{{addressInfo.billTo && addressInfo.billTo[0] && addressInfo.billTo[0].addressInfo}}</span>
          </div>
        </div>
        <!-- payer暂时先不展示 -->
        <div class="line" v-if="false">
          <div class="left">
            <!-- 付款方 -->
            <span class="left-text1">{{L.payer}}:</span>
            <span class="left-text2">*</span>
          </div>
          <div class="right">
            <span class="right-text1">{{addressInfo.payer && addressInfo.payer[0] && addressInfo.payer[0].addressTitle}} {{addressInfo.payer && addressInfo.payer[0] && addressInfo.payer[0].siteUseLocation}}</span>
            <span class="right-text2">{{addressInfo.payer && addressInfo.payer[0] && addressInfo.payer[0].addressInfo}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 采购订单信息 -->
    <div class="customer">
      <div class="title">
        {{L.purchaseOrderInfo}}
      </div>
      <div class="content">
        <div class="line">
          <div class="left">
            <!-- 采购订单编号 -->
            <span class="left-text1">{{L.PONumber}}:</span>
            <span class="left-text2">*</span>
          </div>
          <div class="right-inp">
            <input type="text" v-model="poNumber">
          </div>
        </div>
      </div>
    </div>
    <!-- 订单备注 -->
    <div class="order-remark">
      <div class="title">
        {{L.orderRemarks}}
      </div>
      <div class="content">
        <div class="remark">
          <!-- 备注 -->
          <span class="text1">{{L.comment}}:</span>
          <!-- (客服代表将会跟进您的留言信息。) -->
          <span class="text2">{{L.delayOrder}}</span>
          <el-tooltip 
            effect="dark"
            placement="top"
          >
            <!-- 客服代表需要阅读您的留言，然后才能进一步处理订单。因此，请只填写需要客服人员特殊跟进的必要信息。 -->
            <div slot="content">{{L.remarkIconInfo}}</div>
            <span class="iconfont icon-info">&#xed50;</span>
          </el-tooltip>
        </div>
        <div class="import">
          <textarea v-model="comment"></textarea>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import {postAction} from '@/api/tool'
import {api_place_order} from '@/api/api'

export default {
  name:'info',
  components:{
    
  },
  props:['addressInfo','deliveryData'],
  data(){
    return {
      comment:'',
      poNumber:''
    }
  },
  methods:{
    /* 下单 */
    placeOrder(){
      if(!this.$store.state.userInfo.accountRules.includes('COR') || !this.$store.state.userInfo.accountRules.includes('CSB')){
        this.$message.warning(this.L.noPermission)
        return
      }
      if(!this.poNumber){
        // 请填写采购订单编号
        this.$message.warning(this.L.fillPONumber)
        return
      }
      let poLength = this.poNumber.replace(/[\u0391-\uFFE5]/g,'aaa').length
      if(poLength>40){
        this.$message.warning(this.L.poLengthMessage)
        return
      }
      let params={
        soldToId:(this.addressInfo.soldTo[0] && this.addressInfo.soldTo[0].addressId) || '',
        shipToId:(this.addressInfo.shipTo[0] && this.addressInfo.shipTo[0].addressId) || '',
        billToId:(this.addressInfo.billTo[0] && this.addressInfo.billTo[0].addressId) || '',
        payerId:(this.addressInfo.payer[0] && this.addressInfo.payer[0].addressId) || '',
        comment:this.comment,
        poNumber:this.poNumber
      }
      this.$emit('openLoading')
      postAction(api_place_order,params).then(res=>{
        this.$emit('closeLoading')
        if(res.ifSuccess){
          this.$message({
            message: 'Order placed successfully',
            type: 'success',
            customClass:'message-tooltip'
          });
          this.$emit('setOrderInfo',res)
          this.$emit('changeFocusFlag',4)
          this.getCartCount()
        }else{
          this.$message.warning(res.errMsg)
        }
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../../public/css/public.less');
@import url('./index.less');
</style>