<template>
  <div class="quick-wrap" v-show="show" @touchmove.prevent>
    <div class="quick-order" v-loading="loadingDialogue">
      <div class="icon-close" @click="closeQuickOrder">
        <span class="iconfont icon1">&#xe614;</span>
        <span class="iconfont icon2">&#xe606;</span>
      </div>
      <div class="quick-title">{{L.createWishlist}}</div>
      <div class="remark">{{L.nameOfWishlist}} :</div>
      <div class="inp-wrap">
        <input type="text" class="input-common" v-model="wishlistName"/>
      </div>
      <div class="checkout-wrap">
        <el-checkbox v-model="isDefault"><span class="is-default">{{L.isDefault}}</span></el-checkbox>
      </div>
      <div class="btn-wrap" @click="handleSave">
        <span class="iconfont icon-cart">&#xe67c;</span>
        <span class="text">{{L.save}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {api_wishlist_add} from '@/api/api'
import {postAction} from '@/api/tool'

export default {
  name:'AddNewWishlist',
  data() {
    return {
      show:false,
      loadingDialogue:false,
      wishlistName:'',
      isDefault:false
    }
  },
  methods:{
    /* 保存按钮 */
    handleSave(){
      if(!this.wishlistName.trim()){
        this.$message.warning(this.L.pleaseEnterNameOfWishlist)
      }
      let params = {
        wishlistName:this.wishlistName,
        isDefault:this.isDefault?1:0
      }
      this.loadingDialogue=true
      postAction(api_wishlist_add,params).then(()=>{
        this.loadingDialogue=false
        this.closeQuickOrder()
        this.$emit('getWishlistList')
      }).catch(()=>{
        this.loadingDialogue=false
      })
    },
    /* 关闭对话框的时候重置下数据 */
    closeQuickOrder(){
      this.wishlistName=''
      this.isDefault=false
      this.show=false
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
/* 快速下单 */
.quick-wrap{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 999;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
  .quick-order{
    border-radius: 2px;
    position: absolute;
    &:extend(.main-box-shadow);
    width:450px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    padding:40px;
    box-sizing: border-box;
    text-align: left;
    .icon-close{
      // background-color: red;
      position: absolute;
      right:14px;
      top:14px;
      .icon1{
        font-size: 24px;
        display: block;
        color:#888;
      }
      .icon2{
        font-size: 24px;
        display: none;
        color:@mainColor;
      }
      &:hover{
        cursor: pointer;
        .icon1{
          display: none;
        }
        .icon2{
          display: block;
        }
      }
    }
    .quick-title{
      font-size: 16px;
      font-weight: 600;
    }
    .remark{
      color:#000;
      margin-top: 16px;
      font-size: 13px;
    }
    .inp-wrap{
      margin-top: 10px;
      input{
        border:none;
        outline: none;
        width:100%;
        border:1px solid #ccc;
        border-radius: 2px;
        padding:10px;
        box-sizing: border-box;
        &:focus{
          border-color:@mainColor;
          border:1px solid @mainColor;
        }
      }
    }
    .checkout-wrap{
      margin-top: 20px;
      .is-default{
        color:#000;
        font-size: 13px;
      }
    }
    .btn-wrap{
      margin-top: 20px;
      text-align: center;
      width:100%;
      color:#fff;
      background-color: @mainColor;
      padding:8px 6px;
      box-sizing: border-box;
      border:1px solid @mainColor;
      border-radius: 2px;
      &:extend(.transtion-time);
      .icon-cart{
        margin-right: 6px;
      }
      &:hover{
        cursor: pointer;
        color:@mainColor;
        background-color: #fff;
        &:extend(.transtion-time);
      }
    }
  }
}

</style>