<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap my-wishlist">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <!-- 我的订单 -->
        <a
          class="text2"
          href="/#/wishlist" 
        >{{L.myWishlists}}</a>
      </div>
      <!-- 标题 -->
      <div class="wishlist-title">
        <div class="title">{{L.myWishlists}}</div>
        <div class="btn-wrap">
          <div class="btn" @click="showNewDialogue">{{L.createWishlist}}</div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="content">
        <div class="line" v-for="item in list" :key="item.wishlistId">
          <div class="left">
            <div class="column1" @click="goWishlistDetail(item)">{{item.wishlistName}}</div>
            <div class="column2">{{formatDateNoTime(item.createTime)}}</div>
            <div class="column3">{{item.updateUserName}}</div>
            <div class="column4">{{item.isShare?'Shared':'not Shared'}}</div>
          </div>
          <div class="right">
            <div class="default" v-if="!item.isDefault" @click="handleSetDefault(item)">{{L.setAsDefault}}</div>
            <div class="default-text" v-if="item.isDefault">{{L.default}}</div>
            <div class="clone" @click="handleClone(item)">{{L.clone}}</div>
            <div class="edit" @click="goWishlistDetail(item)">{{L.edit}}</div>
            <span class="iconfont icon-delete" @click="handleDelete(item)">&#xec7b;</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <AddNewWishlist ref="AddNewWishlist" @getWishlistList="getWishlistList"/>
  </div>
</template>

<script>
import {api_wishlist_list,api_wishlist_set_default,api_wishlist_clone,api_wishlist_delete} from '@/api/api'
import {getAction,postAction} from '@/api/tool'
import AddNewWishlist from './AddNewWishlist'

export default {
  name:'Wishlist',
  components:{
    AddNewWishlist
  },
  data(){
    return {
      list:[]
    }
  },
  methods:{
    /* 获取列表 */
    getWishlistList(){
      this.allLoading=true
      let params = {
        isShare:1
      }
      getAction(api_wishlist_list,params).then(res=>{
        this.allLoading=false
        this.list=res
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 展示添加wishlist的对话框 */
    showNewDialogue(){
      this.$refs.AddNewWishlist.show=true
    },
    /* 设为默认值 */
    handleSetDefault(item){
      this.$confirm(this.L.sureToDefault, item.wishlistName, {
        confirmButtonText: this.promptConfirmBtn,
        cancelButtonText: this.propmtCancelBtn,
        closeOnClickModal:this.promptCloseFlag,
        lockScroll:false
      }).then(() => {
        let params = {
          wishlistId:item.wishlistId
        }
        this.allLoading=true
        postAction(api_wishlist_set_default,params).then(()=>{
          this.allLoading=false
          this.getWishlistList()
        }).catch(()=>{
          this.allLoading=false
        })
      }).catch(() => {
                
      });
    },
    /* 克隆 */
    handleClone(item){
      this.$confirm(this.L.sureToClone, item.wishlistName, {
        confirmButtonText: this.promptConfirmBtn,
        cancelButtonText: this.propmtCancelBtn,
        closeOnClickModal:this.promptCloseFlag,
        lockScroll:false
      }).then(() => {
        let params = {
          wishlistId:item.wishlistId
        }
        this.allLoading=true
        postAction(api_wishlist_clone,params).then(()=>{
          this.allLoading=false
          this.getWishlistList()
        }).catch(()=>{
          this.allLoading=false
        })
      }).catch(() => {
                
      });
    },
    /* 删除 */
    handleDelete(item){
      this.$confirm(this.L.sureToDelete, item.wishlistName, {
        confirmButtonText: this.promptConfirmBtn,
        cancelButtonText: this.propmtCancelBtn,
        closeOnClickModal:this.promptCloseFlag,
        type:'warning',
        lockScroll:false
      }).then(() => {
        let params = {
          wishlistId:item.wishlistId
        }
        this.allLoading=true
        postAction(api_wishlist_delete,params).then(()=>{
          this.allLoading=false
          this.getWishlistList()
        }).catch(()=>{
          this.allLoading=false
        })
      }).catch(() => {
                
      });
    },
    /* 去wishlist详情页 */
    goWishlistDetail(item){
      this.$router.push({
        path:'/wishlistDetail',
        query:{
          wishlistId:item.wishlistId
        }
      })
    }
  },
  mounted(){
    this.getWishlistList()
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>

