<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap general-wrap">
      <div class="title1">GUARANTEE STATEMENT</div>
      <div class="note1">
        <p class="p1">N.B.</p>
        <p class="p2">If you did not purchase the product directly from Inventronics, for the management of your return please contact the distributor or reseller from whom you purchased it.</p>
      </div>
      <div class="content">Here you will find the guarantee statement</div>
      <!-- <div class="title2">Inventronics Netherlands B.V.</div> -->
      <div class="table-title">File name</div>
      <!-- <div class="doc-line">
        <span class="iconfont icon-pdf">&#xe60a;</span>
        <a 
          href="https://www.inventronics-co.com/warranty/" 
          class="text"
          target="_blank"
        >INV Products Guarantee Policy</a>
      </div> -->
      <div class="doc-line">
        <span class="iconfont icon-pdf">&#xe60a;</span>
        <a 
          href="https://www.inventronics-light.com/multilevel-guarantees" 
          class="text"
          target="_blank"
        >Products Guarantee Policy</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name:'Term',
  components:{
    
  },
  data(){
    return {
      
    }
  },
  methods:{
    
  },
  mounted(){
    
  }
  
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>