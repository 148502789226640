<template>
  <div class="wrap" v-loading="allLoading">
    <!-- 头部 -->
    <div class="head-all-wrap">
      <div class="main-wrap term-no-enter-wrap">
        <div class="head-wrap">
          <div class="one main-wrap flex-space-between">
            <div class="left distance-bottom">
              <img src="../../assets/logo_en.png">
            </div>
            <div class="right">
              <!-- 语言 -->
              <div class="box box0" @mouseenter="mouseenter(0)" @mouseleave="mouseleave(0)">
                <span class="iconfont iconfont0">&#xe618;</span>
                <span class="text">{{$store.state.nowLanguage}}</span>
                <span class="iconfont down" :class="{'active-down':!showArrow.show0}">&#xeb8b;</span>
                <transition
                  @enter="enter"
                  @afterEnter="afterEnter"
                  @leave="leave"
                  @afterLeave="afterLeave"
                >
                  <div class="popover-wrap" v-show="!showArrow.show0">
                    <div 
                      v-for="item in $store.state.languageList" 
                      :key="item.languageId"
                      @click="changeLanguage(item)"
                    >
                      {{item.languageName}}
                    </div>
                  </div>
                </transition>
              </div>
              <!-- 登录 -->
              <div class="box box3" @click="goLogin">
                <span class="vertical-line"></span>
                <span class="iconfont iconfont6">&#xe653;</span>
                <!-- 登录 -->
                <span class="text no-down-text">{{L.login}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- cookie提示 -->
    <transition enter-active-class="animate__animated animate__fadeInDown">
    <div class="cookie-wrap" v-show="showArrow.showCookie">
      <!-- 本网站使用cookies和跟踪技术为您提供改进的功能和优化的服务 -->
      <div class="one">{{L.cookieWindowTitle}}</div>
      <!-- 营销cookies暂时不展示 -->
      <div class="two" v-show="false">
        <el-checkbox v-model="checkedCookie1" disabled></el-checkbox>
        <!-- 营销cookies -->
        <span class="label">{{L.marketingCookies}} : </span>
        <span>{{L.marketingCookiesContent}}</span>
      </div>
      <div class="two">
        <el-checkbox v-model="checkedCookie2"></el-checkbox>
        <!-- 十分必要 -->
        <span class="label">{{L.strictlyNecessary}} : </span>
        <span>{{L.strictlyNecessaryContent}}</span>
      </div>
      <div class="four">
        <div class="left">
          <!-- 确认选择 -->
          <button @click="chooseCookie(1)">{{L.confirmSelect}}</button>
          <!-- 接受所有 -->
          <button @click="chooseCookie(2)">{{L.acceptAll}}</button>
          <!-- 拒绝所有 -->
          <button @click="chooseCookie(3)">{{L.rejectAll}}</button>
        </div>
        <div class="right">
          <!-- Cookie设置可以随时更改。更多详细信息 -->
          <span>{{L.cookieSetting}} : </span>
          <!-- Cookie政策 -->
          <a class="click-policy" :href="goTermEnterOrNoEnter('20004',1)" target="_blank">{{L.cookiePolicy}}</a>
          <span class="line">|</span>
          <!-- 隐私政策 -->
          <a class="click-policy" :href="goTermEnterOrNoEnter('20003',1)" target="_blank">{{L.privacyPolicy}}</a>
        </div>
      </div>
    </div>
    </transition>
    <!-- 正文 -->
    <div class="main-wrap term-wrap">
      <div class="btn-wrap" v-show="$route.query.id=='20004'">
        <span class="btn" @click="showCookie">{{L.cookieSettingBtn}}</span>
      </div>
      <div class="ql-editor">
        <div v-html="info"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {api_terms} from '@/api/api'
import {postAction} from '@/api/tool'

export default {
  name:'Term',
  components:{
    
  },
  data(){
    return {
      showArrow:{
        show0:true, //语言
        showCookie:false //cookie选择对话框
      },
      info:'',
      checkedCookie1:true, //cookie1是否勾选
      checkedCookie2:false
    }
  },
  watch:{
    $route(){
      this.getInfo()
    }
  },
  methods:{
    mouseenter(flag){
      if(flag==0){
        this.showArrow.show0=false
      }
    },
    mouseleave(flag){
      if(flag==0){
        this.showArrow.show0=true
      }
    },
    getInfo(){
      let params={
        articleCode:this.$route.query.id,
        languageId:this.$cookies.get('languageId')
      }
      this.allLoading=true
      postAction(api_terms,params).then(res=>{
        this.info=res.languageArticle
        this.allLoading=false
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 展示/关闭cookie弹窗 */
    showCookie(){
      let token=this.$cookies.get('token')
      let user_id=this.$cookies.get('user_id')
      this.showArrow.showCookie=!this.showArrow.showCookie
      if(token){
        this.checkedCookie2=this.$cookies.get('user_id_'+user_id)==1?true:false
      }else{
        this.checkedCookie2=this.$cookies.get('guest_accept_cookie')==1?true:false
      }
    },
    /* 3个设置cookie的按钮 */
    chooseCookie(flag){
      if(flag==2){
        this.checkedCookie2=true
      }
      if(flag==3){
        this.checkedCookie2=false
      }
      let token=this.$cookies.get('token')
      let user_id=this.$cookies.get('user_id')
      let val=this.checkedCookie2?1:0
      /* 存在token，存在user_id123里 */
      if(token){
        this.$cookies.set('user_id_'+user_id,val,'180d')
      }
      /* 不存在token，存在公共的guest_accept_cookie里 */
      if(!token){
        this.$cookies.set('guest_accept_cookie',val,'180d')
      }
      this.showArrow.showCookie=false
    }
  },
  mounted(){
    this.getInfo()
  }
  
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('../../../public/css/quill.snow.css'); 
@import url('./index.less');
</style>