<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap address">
      <!-- 地址本 -->
      <div class="address-title">{{L.addressBook}}</div>
      <!-- sold to暂时不展示,用v-show先控制一下 -->
      <div class="sold" v-show="false">
        <div class="title">
          <!-- 售至 -->
          <span class="column1">{{L.soldTo}}</span>
          <span class="column2"></span>
          <span class="column3"></span>
          <!-- 交货条款 -->
          <span class="column4">{{L.incoterms}}</span>
          <!-- 付款条款 -->
          <span class="column5">{{L.paymentTerms}}</span>
        </div>
        <div class="content">
          <div class="line" v-for="item,i in soldTo" :key="i">
            <span class="column1">{{item.addressTitle}}</span>
            <span class="column2">{{item.postalCode}}</span>
            <span class="column3">{{item.addressInfo}}</span>
            <span class="column4">{{item.deliveryClause}}</span>
            <span class="column5">{{item.paymentClause}}</span>
          </div>
        </div>
      </div>
      <div class="sold">
        <div class="title">
          <!-- 发货至 -->
          <span class="column1">{{L.shipTo}}</span>
          <span class="column2"></span>
          <span class="column3"></span>
          <!-- 交货条款 -->
          <span class="column4">{{L.incoterms}}</span>
          <!-- 付款条款 -->
          <span class="column5">{{L.paymentTerms}}</span>
        </div>
        <div class="content">
          <div class="line" v-for="item,i in shipTo" :key="i">
            <span class="column1">{{item.addressTitle}}</span>
            <span class="column2">{{item.postalCode}}</span>
            <span class="column3">{{item.addressInfo}}</span>
            <span class="column4">{{item.deliveryClause}}</span>
            <span class="column5">{{item.paymentClause}}</span>
          </div>
        </div>
      </div>
      <div class="sold">
        <div class="title">
          <!-- 开票至 -->
          <span class="column1">{{L.billTo}}</span>
          <span class="column2"></span>
          <span class="column3"></span>
          <span class="column4"></span>
          <span class="column5"></span>
        </div>
        <div class="content">
          <div class="line" v-for="item,i in billTo" :key="i">
            <span class="column1">{{item.addressTitle}}</span>
            <span class="column2">{{item.postalCode}}</span>
            <span class="column3">{{item.addressInfo}}</span>
            <span class="column4">{{item.deliveryClause}}</span>
            <span class="column5">{{item.paymentClause}}</span>
          </div>
        </div>
      </div>
      <!-- payer暂时不展示,用v-show先控制一下 -->
      <div class="sold" v-show="false">
        <div class="title">
          <!-- 付款方 -->
          <span class="column1">{{L.payer}}</span>
          <span class="column2"></span>
          <span class="column3"></span>
          <span class="column4"></span>
          <span class="column5"></span>
        </div>
        <div class="content">
          <div class="line" v-for="item,i in payer" :key="i">
            <span class="column1">{{item.addressTitle}}</span>
            <span class="column2">{{item.postalCode}}</span>
            <span class="column3">{{item.addressInfo}}</span>
            <span class="column4">{{item.deliveryClause}}</span>
            <span class="column5">{{item.paymentClause}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {api_cus_address_info} from '@/api/api'
import {getAction} from '@/api/tool'

export default {
  name:'Address',
  data(){
    return {
      soldTo:[],
      shipTo:[],
      billTo:[],
      payer:[]
    }
  },
  methods:{
    /* 获取客户地址信息 */
    getCusAddressInfo(){
      this.allLoading=true
      getAction(api_cus_address_info).then(res=>{
        this.soldTo=res.soldTo
        this.shipTo=res.shipTo
        this.billTo=res.billTo
        this.payer=res.payer
        this.allLoading=false
      }).catch(()=>{
        this.allLoading=false
      })
    }
  },
  mounted(){
    this.getCusAddressInfo()
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>