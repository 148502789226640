<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import {getAction} from './api/tool'
import {api_languageList,api_languagePackage,api_user_info,api_beside_img,api_home_banner,api_country_list} from './api/api'

export default {
  name: 'App',
  data(){
    return {
      // lanmao:"http://localhost:8080/other/font.otf"
    }
  },
  methods:{
    /* 请求获取语言列表 */
    getLanguageList(params){
      getAction(api_languageList,params).then(res=>{
        res.forEach((val)=>{
          if(val.languageId==params.languageId){
            this.$store.dispatch('setNowLanguage',val.languageName)
          }
        })
        this.$store.dispatch('changeLanguageList',res)
      })
    },
    /* 请求获取语言包 */
    getLangPackage(params){
      getAction(api_languagePackage,params).then(res=>{
        this.$store.dispatch('changeLanguagePackage',res)
      })
    },
    /* 获取用户信息 */
    getUserInfo(){
      getAction(api_user_info).then(res=>{
        //这个地方不用设置user_id，因为这个user_id其实是跟着token走的，只有token设置时候user_id才需要设置，只有token消失的时候user_id才跟着一起消失
        // this.$cookies.set('user_id',res.accountId,'1d')
        this.$store.dispatch('setUserInfo',res)
        this.$store.dispatch('setCurrency',res.currency)
        this.$store.dispatch('setArea',res.area)
        this.$store.dispatch('setOU',res.ou)
      })
    },
    /* 获取右侧小图片 */
    getRightImg(){
      getAction(api_beside_img).then(res=>{
        this.$store.dispatch('setBesideImgs',res.homeRight)
      })
    },
    /* 获取banner数据 */
    getBannerInfo(){
      getAction(api_home_banner).then(res=>{
        this.$store.dispatch('setBannerInfo',res)
      })
    },
    /* 
      获取国家列表数据，并把url的国家放入cookie 
      获取完以后判断当前链接中是否带有Catalogue参数，如果有的话，直接把url中的国家，放到cookie中去（或者覆盖原来的国家）
    */
    getCountryList(){
      getAction(api_country_list).then(res=>{
        this.$store.dispatch('setCountryList',res)
        if(this.$route.query.Catalogue){
          res.forEach((val)=>{
            if(val.eshopUrl==this.$route.query.Catalogue){
              this.$cookies.set('select_country',val.eshopUrl+';'+val.eshopCountry,'180d')
            }
          })
        }
      })
    },
    /* 字体样式插入 */
    setFont(){
      var fontUrl = window.location.origin + '/other/font.otf';
      var style = document.createElement('style');
      style.appendChild(document.createTextNode(`
        @font-face {
          font-family: 'Noto Sans SC';
          src: url('${fontUrl}');
        }
      `));
      document.head.appendChild(style);
    }
  },
  created(){
    this.$cookies.set('languageId',this.$cookies.get('languageId') || '1598199171759341568','180d')
    let params = {
      languageId:this.$cookies.get('languageId') //这个是英语
    }
    /* 请求获取语言列表 */
    this.getLanguageList(params)
    /* 请求获取语言包 */
    this.getLangPackage(params)
    /* 
      如果有token，就请求获取用户信息，没有token就不需要获取，这里写一个主要是为了防止刷新的情况再拿到一次用户信息，因为vuex刷新之后会没有 
      有token的话，还要再获取一下右侧小图片的数据
    */
    if(this.$cookies.get('token')){
      this.getUserInfo()
      this.getRightImg()
    }
    /* 有没有token都要获取banner的数据 */
    this.getBannerInfo()
    /* 有没有token都要获取国家列表的数据 */
    this.getCountryList()
    /* 插入字体样式 */
    this.setFont()
  }
}

</script>