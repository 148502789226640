import VueRouter from 'vue-router'
import Vue from 'vue'
// import store from '../store'
import Login from '@/pages/Login'
import AcceptTerms from '@/pages/AcceptTerms'
import Register from '@/pages/Register'
import ChangePassword from '@/pages/ChangePassword'
import TermNoEnter from '@/pages/TermNoEnter'
import Home from '@/pages/Home'
import Catalogue from '@/pages/Catalogue'
import GoodList from '@/pages/GoodList'
import Wishlist from '@/pages/Wishlist'
import WishlistDetail from '@/pages/WishlistDetail'
import Cart from '@/pages/Cart'
import Detail from '@/pages/Detail'
import Detail2 from '@/pages/Detail2'
import MyAccount from '@/pages/MyAccount'
import Address from '@/pages/Address'
import CustomerSupport from '@/pages/CustomerSupport'
import Term from '@/pages/Term'
import MyOrders from '@/pages/MyOrders'
import OrderDetail from '@/pages/OrderDetail'
import General from '@/pages/General'
import HasSendEmail from '@/pages/HasSendEmail'
import Guarantee from '@/pages/Guarantee'
import Transfer from '@/pages/Transfer'
import MyAccountStatement from '@/pages/MyAccountStatement'
import QuotesList from '@/pages/Quotes/List'
import QuotesDetail from '@/pages/Quotes/Detail'
import InvoiceList from '@/pages/Invoice/List'
import InvoiceDetail from '@/pages/Invoice/Detail'
import CreditNotesList from '@/pages/CreditNotes/List'
import CreditNotesDetail from '@/pages/CreditNotes/Detail'
import DebitNotesList from '@/pages/DebitNotes/List'
import DebitNotesDetail from '@/pages/DebitNotes/Detail'
/* CQM相关的 */
import SelectRMAtype from '@/pages/RMA/SelectRMAtype'
import Enquiry from '@/pages/RMA/Enquiry'
import EnquiryDetail from '@/pages/RMA/EnquiryDetail'
import Commercial from '@/pages/RMA/Commercial'
import CommercialDetail from '@/pages/RMA/CommercialDetail'
import FieldFailuers from '@/pages/RMA/FieldFailuers'
import FieldFailuresDetail from '@/pages/RMA/FieldFailuresDetail'
import FailuresProd from '@/pages/RMA/FailuresProd'
import FailuresProdDetail from '@/pages/RMA/FailuresProdDetail'
import RMAList from '@/pages/RMA/RMAList'
import DraftsRMAlist from '@/pages/RMA/DraftsRMAlist'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push=function push(location){
  return originalPush.call(this,location).catch(err=>err)
}
/* 
  tokenVerify:有token,就不能进去的页面。（设置成true代表有token不能进去）
  mustLogin：没token，就不能进去的页面。(设置成true代表没token不能进去)
  mustSelectCountry：没有国家，不能进去的页面。（设置成true代表没国家不能进去）
  needRecord: 需要记录链接里的参数，带记忆功能的

  tokenVerify和mustLogin不能同时设置成true（可以同时false）
  mustLogin和mustSelectCountry不能同时设置成true（可以同时false）

  简单总结：tokenVerify只有登录页会用到。剩下的：mustLogin代表只能正常用户访问。mustSelectCountry代表访客也可以访问。
*/
const router = new VueRouter({
  routes:[
    //登录页面
    {
      name:'Login',
      path:'/login',
      component:Login,
      meta:{
        tokenVerify:true, //有token不能进入
        mustLogin:false, //没token可以进入
        mustSelectCountry:false //没国家可以进入
      }
    },
    //接受协议页面【正常用户】
    {
      name:'AcceptTerms',
      path:'/acceptTerms',
      component:AcceptTerms,
      meta:{
        mustLogin:true
      }
    },
    //注册页面【都可进】
    {
      name:'Register',
      path:'/register',
      component:Register
    },
    //修改密码页面【都可进】
    {
      name:'ChangePassword',
      path:'/changePassword',
      component:ChangePassword
    },
    //cookie条款【都可进】
    {
      name:'TermNoEnter',
      path:'/termNoEnter',
      component:TermNoEnter
    },
    //中转页面【都可进】
    {
      name:'Transfer',
      path:'/transfer',
      component:Transfer
    },
    //发送邮件后的提醒【都可进】
    {
      name:'HasSendEmail',
      path:'/hasSendEmail',
      component:HasSendEmail
    },
    /*
      登录之后的页面,能进入的话，mustLogin和mustSelectCountry必须设置其中的一个为true,并且只能一个为true。
    */
    {
      name:'Home',
      path:'/',
      component:Home,
      //这里必须直接跳到login而不是跳到catalogue页面的原因是:
      //如果该用户没有token，如果自动去到catalogue页面，那么会请求catalogue中的几个不必要请求的接口而导致报错token失效，比如获取产品一级目录的接口
      //注意，这里跳到login页面，和login页面的tokenVerify参数相互制约。所以说不存在有token但是又去到login页面的情况。因为有token，又去到login页面以后，又会被弹到catalogue的页面。
      redirect:'/login', 
      children:[
        //商品分类页面【访客也可以】
        {
          name:'Catalogue',
          path:'catalogue',
          component:Catalogue,
          meta:{
            mustSelectCountry:true,
            needRecord:true
          }
        },
        //商品列表页面【访客也可以】
        {
          name:'GoodList',
          path:'goodList',
          component:GoodList,
          meta:{
            mustSelectCountry:true,
            needRecord:true
          }
        },
        //商品详情页面【访客也可以】
        {
          name:'Detail',
          path:'detail',
          component:Detail,
          meta:{
            mustSelectCountry:true,
            needRecord:true
          }
        },
        {
          name:'Detail2',
          path:'detail2',
          component:Detail2,
          meta:{
            mustSelectCountry:true
          }
        },
        //我的收藏清单【正常用户】
        {
          name:'Wishlist',
          path:'wishlist',
          component:Wishlist,
          meta:{
            mustLogin:true
          }
        },
        //收藏详情【正常用户】
        {
          name:'WishlistDetail',
          path:'wishlistDetail',
          component:WishlistDetail,
          meta:{
            mustLogin:true
          }
        },
        //购物车页面【正常用户】
        {
          name:'Cart',
          path:'cart',
          component:Cart,
          meta:{
            mustLogin:true,
            needRecord:true
          }
        },
        //我的账户页面【正常用户】
        {
          name:'MyAccount',
          path:'myAccount',
          component:MyAccount,
          meta:{
            mustLogin:true,
            needRecord:true
          }
        },
        //地址【正常用户】
        {
          name:'Address',
          path:'address',
          component:Address,
          meta:{
            mustLogin:true,
            needRecord:true
          }
        },
        //客户支持【正常用户】
        {
          name:'CustomerSupport',
          path:'customerSupport',
          component:CustomerSupport,
          meta:{
            mustLogin:true
          }
        },
        //cookie条款【正常用户】
        {
          name:'Term',
          path:'term',
          component:Term,
          meta:{
            mustLogin:true
          }
        },
        //我的订单【正常用户】
        {
          name:'MyOrders',
          path:'myOrders',
          component:MyOrders,
          meta:{
            mustLogin:true,
            needRecord:true
          }
        },
        //订单详情页【正常用户】
        {
          name:'OrderDetail',
          path:'orderDetail',
          component:OrderDetail,
          meta:{
            mustLogin:true
          }
        },
        //我的发票【正常用户】
        {
          name:'InvoiceList',
          path:'invoiceList',
          component:InvoiceList,
          meta:{
            mustLogin:true,
            needRecord:true
          }
        },
        //发票详情页【正常用户】
        {
          name:'InvoiceDetail',
          path:'invoiceDetail',
          component:InvoiceDetail,
          meta:{
            mustLogin:true
          }
        },
        //我的报价单【正常用户】
        {
          name:'QuotesList',
          path:'quotesList',
          component:QuotesList,
          meta:{
            mustLogin:true,
            needRecord:true
          }
        },
        //报价单详情页【正常用户】
        {
          name:'QuotesDetail',
          path:'quotesDetail',
          component:QuotesDetail,
          meta:{
            mustLogin:true
          }
        },
        //我的收款通知单【正常用户】
        {
          name:'CreditNotesList',
          path:'creditNotesList',
          component:CreditNotesList,
          meta:{
            mustLogin:true,
            needRecord:true
          }
        },
        //收款通知单详情页【正常用户】
        {
          name:'CreditNotesDetail',
          path:'creditNotesDetail',
          component:CreditNotesDetail,
          meta:{
            mustLogin:true
          }
        },
        //我的付款通知单【正常用户】
        {
          name:'DebitNotesList',
          path:'debitNotesList',
          component:DebitNotesList,
          meta:{
            mustLogin:true,
            needRecord:true
          }
        },
        //付款通知单详情页【正常用户】
        {
          name:'DebitNotesDetail',
          path:'debitNotesDetail',
          component:DebitNotesDetail,
          meta:{
            mustLogin:true
          }
        },
        //我的对账单
        {
          name:'MyAccountStatement',
          path:'myAccountStatement',
          component:MyAccountStatement,
          meta:{
            mustLogin:true,
            needRecord:true
          }
        },
        //通用条款【正常用户】
        {
          name:'General',
          path:'general',
          component:General,
          meta:{
            mustLogin:true,
            needRecord:true
          }
        },
        //QM-客诉页面
        {
          name:'Commercial',
          path:'commercial',
          component:Commercial,
          meta:{
            mustLogin:true
          }
        },
        {
          name:'Enquiry',
          path:'enquiry',
          component:Enquiry,
          meta:{
            mustLogin:true
          }
        },
        {
          name:'EnquiryDetail',
          path:'enquiryDetail',
          component:EnquiryDetail,
          meta:{
            mustLogin:true
          }
        },
        //QM-客诉页面
        {
          name:'FieldFailuers',
          path:'fieldFailuers',
          component:FieldFailuers,
          meta:{
            mustLogin:true
          }
        },
        {
          name:'FieldFailuresDetail',
          path:'fieldFailuresDetail',
          component:FieldFailuresDetail,
          meta:{
            mustLogin:true
          }
        },
        //QM-客诉页面
        {
          name:'FailuresProd',
          path:'failuresProd',
          component:FailuresProd,
          meta:{
            mustLogin:true
          }
        },
        {
          name:'FailuresProdDetail',
          path:'failuresProdDetail',
          component:FailuresProdDetail,
          meta:{
            mustLogin:true
          }
        },
        //我的RMA
        {
          name:'SelectRMAtype',
          path:'selectRMAtype',
          component:SelectRMAtype,
          meta:{
            mustLogin:true
          }
        },
        //我的RMA
        {
          name:'RMAList',
          path:'rmaList',
          component:RMAList,
          meta:{
            mustLogin:true
          }
        },
        //RMA-commercial详情
        {
          name:'CommercialDetail',
          path:'commercialDetail',
          component:CommercialDetail
        },
        {
          name:'Guarantee',
          path:'guarantee',
          component:Guarantee
        },
        //Drafts RMA
        {
          name:'DraftsRMAlist',
          path:'draftsRMAlist',
          component:DraftsRMAlist
        }
      ]
    }
  ]
})

router.beforeEach((to,from,next)=>{
  let flag = false
  //注意：tokenVerify和mustLogin不可能同时设置成true。所以这两个if，最多只会去到一个，并不会两个if都进行判断。并且mustSelectCountry和mustLogin也不可能同时设置成true。
  //mustLogin说明只能正常用户访问，mustSelectCountry说明可以是访客访问。如果设置了mustLogin就绝对不会设置mustSelectCountry。如果设置了mustSelectCountry也绝对不会设置mustLogin。
  //并且登录之后的页面，除了协议页面之外。mustLogin和mustSelectCountry是必须选择一个设置的。
  //next()指定路径跳转时，要注意死循环的情况，要保证所有的逻辑路径都不会重叠!!!

  /* 去登录页或者带token的中转页面直接去，不要去乱跳 */
  if(to.name=='Login' || to.name=='Transfer'){
    next()
    return
  }
  /* 
    登录页特殊处理
    有token验证标记，并且有token，必须强制去主页。【比如，不能去登录页面。有token不能去登录页面!!!】
    这里不用在query里加Catalogue参数，因为这个是有token的情况
  */
  if(to.meta.tokenVerify && Vue.$cookies.get('token')){
    flag = true
    next({
      path:'/catalogue'
    })
  }
  /* 
    正常用户访问的页面
    有mustLogin验证标记，并且没有token，必须强制去登录页。【比如，不能去购物车页面】
    如果强制跳到login页面了，说明是需要先登陆进去的，那么就记录一下之前想过来的页面，然后再跳回去
  */
  if(to.meta.mustLogin && !Vue.$cookies.get('token')){
    flag = true
    //如果过来的页面本身就含有routeName参数的，那就说明，这个人虽然是想通过登录后直接进入到该页面，但是该页面又不允许这个客户进入，所以又只能强制到登录页面，那么这个时候就出现了一个死循环
    //比如，分享的链接是cart页面，到登录页的时候含参数?routeName=cart。选择国家后系统将跳到cart页面，但是cart页面又是不允许访客登录的，所以又无限循环回到了登录页面
    if(from.query.routeName){
      //说明进入了死循环，直接去首页，不要去登录页了
      next({
        path:'/catalogue'
      })
    }else{
      //第一次的时候记录下上一个页面的参数，选择国家后可直接进入
      let newQuery = {}
      if(to.needRecord){
        newQuery = to.query
        newQuery.routeName = to.path.split('/')[1]
      }
      next({
        path:'/login',
        query:newQuery
      })
    }
  }
  /* 
    访客访问的页面
    有mustSelectCountry验证标记，没有token，并且也没有select_country，并且链接中也不带国家，必须强制去登录页。【比如，不能去产品列表页面】
    如果强制跳到login页面了，说明是需要先选择国家进去的，那么就记录一下之前想过来的页面，然后再跳回去
  */
  if(to.meta.mustSelectCountry && !Vue.$cookies.get('token') && !Vue.$cookies.get('select_country') && !to.query.Catalogue){
    flag = true
    //这里不用跟上面写的一样if else那么复杂是因为，访客能进入的页面，登录过的用户一定能进入，所以没那么复杂。复杂的就在于有些普通用户能进入的页面访客不能进入
    let newQuery = {}
    if(to.needRecord){
      newQuery = to.query
      newQuery.routeName = to.path.split('/')[1]
    }
    next({
      path:'/login',
      query:newQuery
    })
  }
  /* 
    如果以上两种情况都不满足，那么就直接到下一页就可以了，说明是正常的情况
  */
  if(!flag){
    next()
  }
})

export default router
