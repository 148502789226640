<template>
  <div class="quick-wrap" v-show="showQuickOrder" @touchmove.prevent>
    <div class="quick-order" v-loading="loadingQuick">
      <div class="icon-close" @click="closeQuickOrder">
        <span class="iconfont icon1">&#xe614;</span>
        <span class="iconfont icon2">&#xe606;</span>
      </div>
      <div class="quick-title">{{L.quickOrder}}</div>
      <!-- 输入我们目录中的产品代码以实现快速订购。 -->
      <div class="remark">{{L.enterToQuick}}</div>
      <div class="inp-wrap">
        <textarea type="text" class="input-common" v-model="quickOrder" :placeholder="quickOrderPlacholder"/>
      </div>
      <div class="btn-wrap" @click="addToCart">
        <span class="iconfont icon-cart">&#xe62c;</span>
        <!-- 加入购物车 -->
        {{L.addCart}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'QuickOrder',
  data() {
    return {
      loadingQuick:false,
      showQuickOrder:false,
      quickOrder:'',
      quickOrderPlacholder:'4051111111111 120\n4052222222222 25\n4053333333333 80'
    }
  },
  methods:{
    addToCart(){
      this.$emit('addRequest') //触发父组件添加购物车的请求
    },
    closeQuickOrder(){
      this.quickOrder=''
      this.showQuickOrder=false
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../public/css/public.less');
/* 快速下单 */
.quick-wrap{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 999;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
  .quick-order{
    border-radius: 2px;
    position: absolute;
    &:extend(.main-box-shadow);
    width:450px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    padding:40px;
    box-sizing: border-box;
    text-align: left;
    .icon-close{
      // background-color: red;
      position: absolute;
      right:14px;
      top:14px;
      .icon1{
        font-size: 24px;
        display: block;
        color:#888;
      }
      .icon2{
        font-size: 24px;
        display: none;
        color:@mainColor;
      }
      &:hover{
        cursor: pointer;
        .icon1{
          display: none;
        }
        .icon2{
          display: block;
        }
      }
    }
    .quick-title{
      font-size: 16px;
      font-weight: 600;
    }
    .remark{
      margin-top: 16px;
    }
    .inp-wrap{
      margin-top: 20px;
      textarea{
        border:none;
        outline: none;
        width:100%;
        height:200px;
        border:1px solid #ccc;
        border-radius: 2px;
        padding:10px;
        box-sizing: border-box;
        &:focus{
          border-color:@mainColor;
          border:1px solid @mainColor;
        }
      }
      textarea::placeholder{
        color:#ccc;
      }
    }
    .btn-wrap{
      margin-top: 20px;
      text-align: center;
      width:100%;
      color:#fff;
      background-color: @mainColor;
      padding:8px 6px;
      box-sizing: border-box;
      border:1px solid @mainColor;
      border-radius: 2px;
      &:extend(.transtion-time);
      .icon-cart{
        margin-right: 6px;
      }
      &:hover{
        cursor: pointer;
        color:@mainColor;
        background-color: #fff;
        &:extend(.transtion-time);
      }
    }
  }
}

</style>