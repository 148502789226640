<template>
  <div class="no-content">
    <div class="img">
      <img src="../assets/no_content.png"/>
    </div>
    <div class="text">
      <span class="iconfont icon-sad">&#xe65d;</span>
      <span class="text">{{L.noData}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name:'NoContent'
}
</script>

<style lang="less" scoped>
@import url('../../public/css/public.less');
/* 缺省页 */
.no-content{
  // background-color: red;
  padding-top:20px;
  padding-bottom: 50px;
  .img{
    text-align: center;
    // background-color: yellow;
    img{
      width:280px;
    }
  }
  .text{
    color:#aaa;
    .icon-sad{
      display: inline-block;
      vertical-align: top;
      font-size: 20px;
      color:#aaa;
    }
    .text{
      font-size: 14px;
      margin-left: 6px;
      display: inline-block;
    }
  }
}
</style>