<template>
  <div class="quick-wrap" v-show="show" @touchmove.prevent>
    <div class="quick-order">
      <div class="icon-close" @click="close">
        <span class="iconfont icon1">&#xe614;</span>
        <span class="iconfont icon2">&#xe606;</span>
      </div>
      <template v-if="flag==0 || flag==1">
        <div class="remark">{{L.addSuccess}}</div>
        <div class="remark2">{{L.pleaseGo}}</div>
      </template>
      <template v-if="flag==1 || flag==2">
        <div class="remark">Some product could not be added to the cart due to an invalid product code. Please review the code(s) and contact your local Customer Service representative if needed.</div>
        <ul class="remark3">
          <li class="remark3" v-for="item,i in failList" :key="i">{{item}}</li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name:'AddCartResult',
  data() {
    return {
      flag:null, //0都成功，1部分成功，2都失败
      failList:[], //失败的EAN的列表
      show:false
    }
  },
  methods:{
    open(flag,failList){
      this.flag=flag
      this.failList=failList || []
      this.show=true
    },
    close(){
      this.show=false
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../public/css/public.less');
/* 快速下单 */
.quick-wrap{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 999;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
  .quick-order{
    border-radius: 2px;
    position: absolute;
    &:extend(.main-box-shadow);
    width:500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    padding:40px;
    box-sizing: border-box;
    text-align: left;
    .icon-close{
      // background-color: red;
      position: absolute;
      right:14px;
      top:14px;
      .icon1{
        font-size: 24px;
        display: block;
        color:#888;
      }
      .icon2{
        font-size: 24px;
        display: none;
        color:@mainColor;
      }
      &:hover{
        cursor: pointer;
        .icon1{
          display: none;
        }
        .icon2{
          display: block;
        }
      }
    }
    .remark{
      text-align: left;
      margin-top: 16px;
      font-weight: 800;
    }
    .remark2{
      margin-top: 16px;
      font-weight: 800;
      color:@mainColor;
    }
    .remark3{
      margin-top: 10px;
      list-style-type: disc;
    }
  }
}

</style>