<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap general-wrap">
      <div class="title1">General Terms and Conditions (GTC)</div>
      <div class="content">Here you will find the General Terms and Conditions for Delivery and Payment</div>
      <div class="title2">{{ouName}}</div>
      <div class="table-title">File name</div>
      <div class="doc-line" v-for="item,i in gtcList" :key="i">
        <span class="iconfont icon-pdf">&#xe60a;</span>
        <a 
          :href="item.url" 
          class="text"
          target="_blank"
        >{{item.descritpion}}</a>
      </div>
      <!-- <div class="doc-line">
        <span class="iconfont icon-pdf">&#xe60a;</span>
        <a 
          href="https://www.inventronics-light.com/_resources/Inventronics-Product-Warranty.pdf" 
          class="text"
          target="_blank"
        >Inventronics Limited Warranty</a>
      </div> -->
    </div>
  </div>
</template>

<script>
import {api_gtc_link} from '@/api/api'
import {getAction} from '@/api/tool'

export default {
  name:'Term',
  components:{
    
  },
  data(){
    return {
      ouName:'',
      gtcList:[]
    }
  },
  methods:{
    /* 获取列表 */
    getGtcList(){
      this.allLoading=true
      getAction(api_gtc_link).then(res=>{
        this.allLoading=false
        this.ouName=res.ouName
        this.gtcList=res.gtcList
      }).catch(()=>{
        this.allLoading=false
      })
    }
  },
  mounted(){
    this.getGtcList()
  }
  
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>