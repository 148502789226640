<template>
  <div class="request-wrap" v-show="show" @touchmove.prevent>
    <div class="quick-order" v-loading="loading">
      <div class="icon-close" @click="close">
        <span class="iconfont icon1">&#xe614;</span>
        <span class="iconfont icon2">&#xe606;</span>
      </div>
      <div class="quick-title">{{L.reuqestForQuo}}</div>
      <div class="small-title" v-if="cartOrWishlist==1">{{L.quoReqNotice}}</div>
      <div class="small-title" v-if="cartOrWishlist==2">{{L.quoReqNotice2}}</div>
      <div class="quotation-name-wrap">
        <div class="quotation-name">{{L.quoName}}</div>
        <div class="quotation-inp-wrap">
          <input type="text" class="quotation-inp" v-model="quoteReference" />
          <span class="characters">{{L.charcaters35}}</span>
        </div>
      </div>
      
      <!-- 输入我们目录中的产品代码以实现快速订购。 -->
      <div class="remark">{{L.messageSales}}</div>
      <div class="inp-wrap">
        <textarea type="text" class="input-common" v-model="quoteMessage"/>
      </div>
      <div class="btn-wrap" @click="handleSubmit">
        {{L.submitEnauiry}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'RequestQuotation',
  data() {
    return {
      show:false,
      loading:false,
      cartOrWishlist:1,
      quoteReference:'',
      quoteMessage:''
    }
  },
  methods:{
    open(flag){
      this.show=true
      this.cartOrWishlist=flag
    },
    close(){
      this.quoteReference=''
      this.quoteMessage=''
      this.loading=false
      this.show=false
    },
    /* 提交 */
    handleSubmit(){
      if(!this.quoteReference){
        this.$message.warning(this.L.pleaseEnterQuoName)
        return
      }
      if(this.quoteReference.length>80){
        this.$message.warning(this.L.quoNameLimit35)
        return
      }
      let params = {
        quoteReference:this.quoteReference,
        quoteMessage:this.quoteMessage
      }
      this.$emit('requestQuatation',params) //触发父组件
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
.request-wrap{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 9;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
  .quick-order{
    border-radius: 2px;
    position: absolute;
    &:extend(.main-box-shadow);
    width:600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    padding:40px;
    box-sizing: border-box;
    text-align: left;
    .icon-close{
      // background-color: red;
      position: absolute;
      right:14px;
      top:14px;
      .icon1{
        font-size: 24px;
        display: block;
        color:#888;
      }
      .icon2{
        font-size: 24px;
        display: none;
        color:@mainColor;
      }
      &:hover{
        cursor: pointer;
        .icon1{
          display: none;
        }
        .icon2{
          display: block;
        }
      }
    }
    .quick-title{
      font-size: 16px;
      font-weight: 600;
    }
    .small-title{
      margin-top:14px;
      color:#666;
      font-weight: 600;
    }
    .quotation-name-wrap{
      text-align: left;
      .quotation-name{
        margin-top: 14px;
        font-weight: 200;
        color:#333;
      }
      .quotation-inp-wrap{
        margin-top: 10px;
        display: flex;
        align-items: center;
        .quotation-inp{
          width: 200px;
          border:1px solid #ccc;
          border-radius: 2px;
          box-sizing: border-box;
          height:34px;
          // padding:8px 6px;
          padding:0 6px;
          &:focus{
            border-color:@mainColor;
            border:1px solid @mainColor;
          }
        }
        .characters{
          flex-grow: 1;
          margin-left: 10px;
          color:#666;
          font-weight: 600;
        }
      }
    }
    .remark{
      margin-top: 20px;
      font-weight: 200;
      color:#333;
    }
    .inp-wrap{
      margin-top: 10px;
      textarea{
        border:none;
        outline: none;
        width:100%;
        height:100px;
        border:1px solid #ccc;
        border-radius: 2px;
        padding:10px;
        box-sizing: border-box;
        &:focus{
          border-color:@mainColor;
          border:1px solid @mainColor;
        }
      }
    }
    .btn-wrap{
      margin-top: 20px;
      text-align: center;
      width:100%;
      color:#fff;
      background-color: @mainColor;
      padding:8px 6px;
      box-sizing: border-box;
      border:1px solid @mainColor;
      border-radius: 2px;
      &:extend(.transtion-time);
      &:hover{
        cursor: pointer;
        color:@mainColor;
        background-color: #fff;
        &:extend(.transtion-time);
      }
    }
  }
}

</style>