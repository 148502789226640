<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap fieldFailuers-wrap">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <!-- 我的订单 -->
        <a
          class="text1"
          href="/#/selectRMAtype" 
        >My Service Point</a>
        <span class="line">/</span>
        <!-- 这部分是正常单据进来的路由 -->
        <template v-if="$route.query.from=='normal'">
          <a
            class="text2"
            href="/#/failuresProd" 
          >Production Incident</a>
        </template>
        <!-- 这部分是草稿单据的路由 -->
        <template v-if="$route.query.from=='draft'">
          <a
            class="text1"
            href="/#/rmaList" 
          >My Tickets</a>
          <span class="line">/</span>
          <a
            class="text1"
            href="/#/draftsRMAlist" 
          >Drafts</a>
          <span class="line">/</span>
          <a
            class="text2"
            :href="'/#/failuresProd?flag=3&from=draft&draftNo='+$route.query.draftNo" 
          >{{$route.query.draftNo}}</a>
        </template>
      </div>
      <!-- 我的订单-标题 -->
      <div class="my-orders-title">
        <span>Production Incident</span>
        <span class="draft-number" v-if="$route.query.from=='draft'">{{$route.query.draftNo}}</span>
      </div>
      <!-- 提交按钮 -->
      <div class="submit-wrap">
        <div class="submit submit2" @click="handleSubmit(1)">
          <span class="iconfont icon2">&#xe747;</span>
          <span>Submit</span>
        </div>
        <div class="submit" @click="handleSubmit(2)">
          <span class="iconfont icon3">&#xe695;</span>
          <span>Save as draft</span>
        </div>
        
      </div>
      <div class="line-title">
        <span class="text">General data</span>
      </div>

      <!-- 单头 -->
      <el-form class="form" :model="formCommon" :rules="rules1" ref="formHead">

        <div class="square" v-if="$route.query.from=='draft'">
          <span class="text">Draft No.:</span>
          <el-form-item prop="caseNo">
            <el-input disabled v-model="formCommon.caseNo" class="inp-field"></el-input>
          </el-form-item>
        </div>

        <div class="square">
          <span class="text">Date:</span>
          <el-form-item prop="caseDate">
            <el-date-picker
              disabled
              v-model="formCommon.caseDate"
              class="inp-field"
              value-format="yyyy-MM-dd"
              format="dd-MM-yyyy"
              type="date">
            </el-date-picker>
          </el-form-item>
        </div>

        <div class="square">
          <span class="text">Customer name:</span>
          <el-form-item>
            <el-input disabled v-model="customerName" class="inp-field"></el-input>
          </el-form-item>
        </div>

        <div class="square">
          <span class="text">Customer No.:</span>
          <el-form-item>
            <el-input disabled v-model="customerAccountNo" class="inp-field"></el-input>
          </el-form-item>
        </div>

        <div class="square">
          <span class="text">Customer Contact Name:</span>
          <el-form-item>
            <el-input disabled v-model="contactName" class="inp-field"></el-input>
          </el-form-item>
        </div>

        <div class="square square-long">
          <span class="text">Customer Contact Email <span class="asterisk">*</span>:</span>
          <el-form-item>
            <el-input disabled v-model="contactEmail" class="inp-field inp-field-long"></el-input>
          </el-form-item>
        </div>

        <div class="square">
          <span class="text">
            Request type 
            <span class="asterisk">*</span>:
          </span>
          <el-form-item prop="requestType">
            <el-select multiple collapse-tags :clearable="true" v-model="formCommon.requestType" class="inp-field" placeholder="Please select">
              <el-option
                v-for="item in requestTypeArr"
                :key="item.typeId"
                :label="item.typeName"
                :value="item.typeId">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="square square-long">
          <span class="text">Request type desc <span class="asterisk" v-show="formCommon.requestType.includes('7')">*</span>:</span>
          <el-form-item prop="requestTypeDesc" :rules="[{required:formCommon.requestType.includes('7'),trigger:'blur',message:'Request type desc is required'}]">
            <el-input v-model="formCommon.requestTypeDesc" class="inp-field inp-field-long"></el-input>
          </el-form-item>
        </div>

        <div class="square square-long">
          <span class="text">Your reference:</span>
          <el-form-item prop="yourReference">
            <el-input v-model="formCommon.yourReference" class="inp-field inp-field-long"></el-input>
          </el-form-item>
        </div>

      </el-form>

      <!-- 单身 -->
      <el-form v-for="(item,i) in productList" :key="i" :model="item" :rules="rules2" ref="formBody" :show-message="true">
        <div class="line-title">
          <span class="text">{{i+1}}° Product information</span>
          <div class="delete-wrap" v-show="productList.length>=2" @click="deleteProduct(i)">
            <span class="iconfont icon-delete">&#xe81f;</span>
            <span class="text-delete">Delete {{i+1}}° Product information</span>
          </div>
        </div>

        <div class="form">

          <div class="square square-long">
            <span class="text">Product <span class="asterisk">*</span>:</span>
            <el-form-item prop="claimedProduct">
              <el-input v-model="item.claimedProduct" class="inp-field inp-field-long"></el-input>
            </el-form-item>
          </div>

          <div class="square">
            <span class="text">Quantity delivered:</span>
            <el-form-item prop="qtyDelivered">
              <el-input-number controls-position="right" v-model="item.qtyDelivered" class="inp-field"></el-input-number>
            </el-form-item>
          </div>

          <div class="square">
            <span class="text">Claimed Qty <span class="asterisk">*</span>:</span>
            <el-form-item prop="claimedQty">
              <el-input-number controls-position="right" v-model="item.claimedQty" class="inp-field"></el-input-number>
            </el-form-item>
          </div>

          <div class="square">
            <span class="text">Quantity to be returned <span class="asterisk">*</span>:</span>
            <el-form-item prop="qtyToReturn">
              <el-input-number controls-position="right" v-model="item.qtyToReturn" class="inp-field"></el-input-number>
            </el-form-item>
          </div>

          <div class="square">
            <span class="text">Identcode:</span>
            <el-form-item prop="identcode">
              <el-input v-model="item.identcode" class="inp-field"></el-input>
            </el-form-item>
          </div>

          <div class="square">
            <span class="text">Date code:</span>
            <el-form-item prop="dateCode">
              <el-input v-model="item.dateCode" class="inp-field"></el-input>
            </el-form-item>
          </div>

          <div class="square">
            <span class="text">Problem <span class="asterisk">*</span>:</span>
            <el-form-item prop="problem">
              <el-select v-model="item.problem" class="inp-field" placeholder="">
                <el-option
                  v-for="item in problemArr"
                  :key="item.typeId"
                  :label="item.typeName"
                  :value="item.typeId">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="square square-long">
            <span class="text">Problem details 
              <span class="asterisk">*</span>:
            </span>
            <el-form-item prop="problemDetails">
              <el-input v-model="item.problemDetails" class="inp-field inp-field-long" @focus="focusProblemDetail(item,i)" v-show="!item.showProblemDetail"></el-input>
              <el-input ref="problemDetailRef" :autosize="{ minRows: 5}" type="textarea" @blur="blurProblemDetail(item,i)" v-model="item.problemDetails" v-show="item.showProblemDetail"></el-input>
            </el-form-item>
          </div>

          <div class="square">
            <span class="text">Your reference:</span>
            <el-form-item prop="yourReference">
              <el-input v-model="item.yourReference" class="inp-field"></el-input>
            </el-form-item>
          </div>

          <div class="square">
            <span class="text">Delivery date:</span>
            <el-form-item prop="deliveryDate">
              <el-date-picker
                v-model="item.deliveryDate"
                class="inp-field"
                value-format="yyyy-MM-dd"
                format="dd-MM-yyyy"
                type="date">
              </el-date-picker>
            </el-form-item>
          </div>

          <div class="square">
            <span class="text">Delivery note:</span>
            <el-form-item prop="deliveryNote">
              <el-input v-model="item.deliveryNote" class="inp-field"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <!-- 上传文件 -->
      <div class="upload-wrap">
        <el-tooltip 
          class="upload-tooltip" 
          effect="dark"
          placement="top"
        >
          <div slot="content">Please click here for uploading documents, pictures, list of scanned barcodes, …</div>
          <el-upload
            :action="uploadApi"
            :multiple="false"
            :show-file-list="false"
            :http-request="handleUpload">
            <div class="upload-btn">
              <span class="iconfont icon-upload">&#xe63f;</span>
              <span class="upload-text">Upload</span>
            </div>
          </el-upload>
        </el-tooltip>
        <div class="file-list">
          <div class="line" v-for="item in fileList" :key="item.fileId">
            <span class="iconfont icon-file2" @click="handleDeleteFile(item)">&#xec7b;</span>
            <span class="file-text" @click="handleDownFile(item)">{{item.fileName}}</span>
          </div>
        </div>
      </div>

      <!-- 再加一行 -->
      <div class="add-wrap">
        <div class="line"></div>
        <p class="text-wrap" @click="addProduct">
          <span class="iconfont icon-add">&#xe689;</span>
          <span class="add-text">Add {{productList.length+1}}° Product information</span>
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import {
  api_claim_upload_file,
  api_claim_select,
  api_claim_detail,
  api_delete_file_common,
  api_order_confirm_download,
  api_claim_pro_add
} from '@/api/api'
import {getAction,postFormAction,postAction,downFileGet} from '@/api/tool'
import Vue from 'vue'

export default {
  name:'FailuresProd',
  data(){
    var checkClaimQty = (rule,value,callback)=>{
      if(value<=0){
        return callback(new Error('Claimed Qty must be greater than 0'));
      }else{
        callback()
      }
    }
    return {
      requestTypeArr:[], //下拉
      problemArr:[], //下拉
      formCommon:{
        caseNo:'', //这个caseNo相当于是单据编号，并不是CRM的Case No. CRM的Case No.请参考下面这个字段
        caseDate:'',
        requestType:[],
        requestTypeDesc:'',
        yourReference:''
      },
      //记得这里的字段跟下面的，加一个产品的方法里的字段一起改
      productList:[{
        problem:'',
        problemDetails:'',
        qtyDelivered:undefined,
        deliveryDate:'',
        deliveryNote:'',
        yourReference:'',
        claimedProduct:'',
        claimedQty:undefined,
        qtyToReturn:undefined,
        identcode:'',
        dateCode:''
      }],
      fileList:[], //文件列表
      rules1:{
        requestType:[{required:true,trigger:'blur',message:'Request type is required'}]
      },
      rules2:{
        problem:[{required:true,trigger:'blur',message:'Problem is required'}],
        problemDetails:[{required:true,trigger:'blur',message:'Problem Details is required'}],
        claimedProduct:[{required:true,trigger:'blur',message:'Product is required'}],
        claimedQty:[
          {required:true,trigger:'blur',message:'Claimed Qty is required'},
          {validator:checkClaimQty,trigger:'blur'}
        ],
        qtyToReturn:[{required:true,trigger:'blur',message:'Quantity to be returned is required'}]
      }
    }
  },
  computed:{
    uploadApi(){
      return api_claim_upload_file
    },
    //这几个值，因为是从vuex里面取的。刷新页面的时候会消失，所以不能放在formCommon里面。只能向后端提交数据的时候再塞进去
    customerName(){
      return this.$store.state.userInfo.customerName
    },
    customerAccountNo(){
      return this.$store.state.userInfo.customerAccountNo
    },
    contactName(){
      return this.$store.state.userInfo.username
    },
    contactEmail(){
      return this.$store.state.userInfo.email
    }
  },
  methods:{
    /* 获取下拉数据 */
    getSelect(){
      let params = {
        formType:'fai_request_type,pi_problem_type'
      }
      getAction(api_claim_select,params).then(res=>{
        res.forEach((val)=>{
          if(val.formType=='fai_request_type'){
            this.requestTypeArr=val.claimSelect
          }
          if(val.formType=='pi_problem_type'){
            this.problemArr=val.claimSelect
          }
        })
      })
    },
    /* 单据首次进入默认赋值 */
    setDefaultValue(){
      this.formCommon.caseDate = this.getNowDate()
    },
    /* 加一个产品-记得这里的字段跟上面的，初始化data里的字段一起改 */
    addProduct(){
      this.productList.push({
        problem:'',
        problemDetails:'',
        qtyDelivered:undefined,
        deliveryDate:'',
        deliveryNote:'',
        yourReference:'',
        claimedProduct:'',
        claimedQty:undefined,
        qtyToReturn:undefined,
        identcode:'',
        dateCode:''
      })
    },
    /* 删除一个产品 */
    deleteProduct(idx){
      this.productList.splice(idx,1)
    },
    /* 上传附件的请求 */
    uploadRequest(params){
      this.allLoading=true
      postFormAction(api_claim_upload_file,params).then(()=>{
        this.allLoading=false
        this.$message.success('Upload successful.')
        this.getDetail().then(res=>{
          this.fileList=res.files
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 上传附件的按钮 */
    handleUpload(file){
      //限制文件大小为5MB
      const maxSize = 5 * 1024 * 1024; // bytes
      if(file.file.size>maxSize){
        this.$message.warning('The size of each individual file cannot exceed 5MB.')
        return
      }
      let params = {
        flag:4, //这个flag要跟着单据走
        file:file.file,
        keyId:this.formCommon.caseNo
      }
      if(!this.formCommon.caseNo){
        this.getCaseNo().then(res=>{
          this.formCommon.caseNo=res
          params.keyId=res
          this.uploadRequest(params)
        })
      }else{
        this.uploadRequest(params)
      }
    },
    /* 获取详情(含附件列表) */
    getDetail(){
      let params = {
        flag:4, //这个flag要跟着单据走
        caseNo:this.formCommon.caseNo
      }
      this.allLoading=true
      return new Promise((resolve,reject)=>{
        getAction(api_claim_detail,params).then(res=>{
          this.allLoading=false
          resolve(res)
        }).catch(res=>{
          this.allLoading=false
          reject(res)
        })
      })
    },
    /* 删除附件 */
    handleDeleteFile(obj){
      let params = {
        fileId:obj.fileId
      }
      this.allLoading=true
      postAction(api_delete_file_common,params).then(()=>{
        this.$message.success('Delete successfully')
        this.allLoading=false
        this.getDetail().then(res=>{
          this.fileList=res.files
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载附件 */
    handleDownFile(obj){
      let params = {
        filezIds:obj.filezId
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 准备执行提交请求 */
    implement(flag){
      let params = {
        ...this.formCommon,
        lineDtoList:this.productList,
        customerName:this.customerName,
        customerAccountNo:this.customerAccountNo,
        contactName:this.contactName,
        contactEmail:this.contactEmail
      }
      params.formType=flag //1说明是正常的单据
      //没有case no.就先获取case no.
      if(!this.formCommon.caseNo){
        this.getCaseNo().then(res=>{
          this.formCommon.caseNo=res
          params.caseNo=res
          this.addRequest(flag,params)
        })
      }else{
        this.addRequest(flag,params)
      }
    },
    /* 提交的请求 */
    addRequest(flag,params){
      //提交请求之前把requestType这个字段转化成字符串给后端
      params.requestType=params.requestType.join(',')
      this.allLoading=true
      postAction(api_claim_pro_add,params).then(()=>{
        this.allLoading=false
        this.$message.success('Operation successful')
        if(flag==1){
          //跳转到正常的详情页面
          this.goFailuresProdDetail(this.formCommon.caseNo)
        }
        //传进来的参数是2，但当前的参数是draft，说明就要去到草稿页面了。否则就说明本身就是在草稿页面的，那就不用跳了
        if(flag==2 && this.$route.query.from=='normal'){
          this.goFailuresProd2('draft',this.formCommon.caseNo)
        }
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 判空校验+其他规则校验 */
    verifyHeadAndBody(){
      let isPass = true
      //单头判空
      this.$refs.formHead.validate(valid=>{
        if(!valid){
          isPass=false
          this.$message.warning('Please complete the required fields.');
        }
      })
      //如果单头为空，就不用往下走了
      if(!isPass){
        return isPass
      }
      //单身判空
      let formBodyArr = this.$refs['formBody']
      for(let i=0; i<formBodyArr.length; i++){
        let val = this.$refs['formBody'][i]
        val.validate((valid)=>{
          if(!valid){
            isPass=false;
            this.$message.warning('Please complete the required fields.');
          }
        })
        if(!isPass){
          break; //这里直接跳出，不需要再继续循环了，否则报错就是多个
        }
      }
      return isPass //如果走到最后了，再return一下结果
    },
    /* 提交的按钮,1正常,2草稿 */
    handleSubmit(flag){
      //正常的单子必须校验通过才能请求
      if(flag==1 && this.verifyHeadAndBody()){
        this.implement(flag)
      }
      //草稿的单子不用校验通过直接请求
      if(flag==2){
        this.implement(flag)
      }
    },
    /* input和textarea之间的切换 */
    focusProblemDetail(item,i){
      item.showProblemDetail=true
      this.$set(this.productList,i,item) //触发渲染
      this.$nextTick(()=>{
        this.$refs.problemDetailRef[i].focus()
      })
    },
    blurProblemDetail(item,i){
      item.showProblemDetail=false
      this.$set(this.productList,i,item) //触发渲染
    }
  },
  mounted(){
    this.getSelect()
    this.setDefaultValue()
    if(this.$route.query.from=='draft'){
      this.formCommon.caseNo=this.$route.query.draftNo
      this.getDetail().then(res=>{
        if(res.requestType){
          this.formCommon.requestType=res.requestType.split(',')
        }else{
          this.formCommon.requestType=[]
        }
        this.formCommon.requestTypeDesc=res.requestTypeDesc
        this.formCommon.yourReference=res.yourReference
        this.productList=res.lineVoList
        this.productList.forEach(val=>{
          for(const key in val){
            if(val[key]==null){
              val[key]=undefined
            }
          }
        })
        this.fileList=res.files
      })
    }
  }
  
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>