<template>
  <div class="wrap">
    <div class="main-wrap good-detail">
      <!-- 上半部分 产品选择 -->
      <div class="product-choose">
        <div class="left">
          <div class="pic">
            <span class="iconfont icon-magnifier" @click="showBigImg">&#xe622;</span>
            <img :src="chooseMoveImgInfo.url"/>
          </div>
          <div class="scroll">
            <div class="arrow-left" @click="movePic(1)">
              <span class="iconfont icon-left-arrow">箭头</span>
              <div class="tip" v-show="show.showLeftArrowTip">That's the end of it.</div>
            </div>
            <div class="img-wrap">
              <ul ref="picUl">
                <li 
                  v-for="item in moveImgArr" 
                  :key="item.id" 
                  @click="chooseMoveImg(item)" 
                  :class="{'li-active':chooseMoveImgInfo.id===item.id}"
                >
                  <img :src="item.url"/>
                </li>
              </ul>
            </div>
            <div class="arrow-right" @click="movePic(2)">
              <span class="iconfont icon-right-arrow">箭头</span>
              <div class="tip" v-show="show.showRightArrowTip">That's the end of it.</div>
            </div>
          </div>
          <!-- 下载sheet -->
          <div class="download-sheet">
            <span class="iconfont icon-file">&#xe60a;</span>
            <span>Family Datasheet</span>
          </div>
        </div>
        <div class="right">
          <!-- 标题 -->
          <div class="title">DALI ECO BT RTC</div>
          <!-- 类型 -->
          <div class="classify">
            <span class="text">Variants : 1</span>
            <span class="iconfont icon-arrow">箭头朝下</span>
          </div>
          <!-- 横线 -->
          <div class="line"></div>
          <!-- 选择框 -->
          <div class="select" @mouseleave="mouseleave">
            <div class="choose" @click="productDetail.showChoose=!productDetail.showChoose">
              <div class="text">
              <div class="text1">OT FIT 80/220-240/24 P</div>
                <div class="text2">EAN: 4062172177924</div>
              </div>
              <span class="iconfont arrow" :class="{'active-arrow':productDetail.showChoose}">&#xe627;</span>
            </div>
            <!-- 弹框 -->
            <div class="choose-wrap" v-show="productDetail.showChoose">
              <div class="choose" @click="handleChooseSelect(item)" v-for="item in productDetail.chooseList" :key="item.id">
                <div class="line1">{{item.text1}}</div>
                <div class="line2">{{item.text2}}</div>
              </div>
            </div>
          </div>
          <!-- 装箱信息 -->
          <div class="package-wrap">
            <div class="package">
              <div class="content">Pack Type (Order Unit):</div>
              <div class="num">Unpacked</div>
            </div>
            <div class="package">
              <div class="content">Pieces in 1 Unpacked:</div>
              <div class="num">1</div>
            </div>
            <div class="package">
              <div class="content">Shipping Quantity (Order Units):</div>
              <div class="num">1</div>
            </div>
          </div>
          <!-- 数量选择 价格 -->
          <div class="num-price">
            <div class="num">
              <AddNumber paddingTopBottom="12px"/>
            </div>
            <div class="price">
              <div v-show="true">
                <span>Price on request</span>
              </div>
              <div v-show="false">
                <div class="price-detail">€74,00</div>
                <div>(€74,00 per Folding carton box)</div>
              </div>
            </div>
          </div>
          <!-- 可用库存 -->
          <div class="available">
            Available quantity：280
            <el-tooltip 
              class="item" 
              effect="dark"
              placement="top"
            >
            <div slot="content">Check available quantity by selecting a<br/> product and clicking the price & stock button.<br/> Stock may beg available for your requested<br/> delivery date:g add product quantity<br/> to cart andg run the delivery<br/> & price check..</div>
              <span class="iconfont">&#xed50;</span>
            </el-tooltip>
          </div>
          <!-- 添加到购物车 -->
          <div class="add">
            <span class="iconfont">&#xe62c;</span>
            &nbsp;
             Add to cart
          </div>
          <!-- 下载software -->
          <div class="download-software">Download Software for this Product</div>
        </div>
      </div>
      <!-- 中间部分 产品描述 -->
      <div class="product-describe">
        <!-- 简介 -->
        <div class="introduce">Now with integrated clock for time-of-day-dependent lighting control. The DALIeco BT RTC (real time clock) is a stand-alone control, ideal for installation into floor standing luminaires with DALI DT8 (Tunable white) drivers, due to its compact design. With the ECO CI KIT also independent mounting is possible. It is optimized for Human Centric Lighting (HCL) Applications. Meaning for time-of-day-dependent, automatic change of color temperature and lightlevel, for example in offices, classrooms, meeting rooms and foyers. The integrated timer functions enables automatic time-of-day- and weekday-dependent lighting control. It is therefore the perfect choice for all those areas in a building where the illumination must be adapted to specific times of day, such as the opening hours of a shopping mall or the staffing times in a lobby.</div>
        <div class="content-wrap">
          <Accordion :list="accordionList"/>
        </div>
      </div>
      <!-- 下半部分 产品分类 -->
      <div class="product-classify">
        <div class="title">Product Variants</div>
        <!-- 标题 -->
        <div class="line-title">
          <div class="product">
            Product
          </div>
          <div class="detail">
            <div class="info" v-for="item,i in variantTitleList" :key="i">
              <span>{{item}}</span>
              <span class="iconfont icon-arrow">&#xe627;</span>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="line" v-for="item,i in variantList" :key="i">
          <!-- 非折叠框内容区 -->
          <div class="content-wrap">
            <!-- 产品信息 -->
            <div class="product">
              <span 
                class="iconfont icon-arrow" 
                @click="showCollapse(item)"
                :class="{'icon-active':item.showCollapse==1}"
              >&#xe66c;</span>
              <img src="https://dammedia.osram.info/media/img/osram-dam-19253592/s,y,400/xxx.png">
              <div class="content">
                <div class="text1"> OT FIT 80/220-240/24 P</div>
                <div class="text2">EAN: 4062172177900</div>
                <div class="text3">Download Software for this Product</div>
              </div>
              <span class="iconfont icon-info">&#xed50;</span>
            </div>
            <!-- 产品详情 -->
            <div class="detail">
              <div class="info" 
                v-for="item2,i in item.info" 
                :key="i"
                v-show="item.info.length!=0"
              >{{item2}}</div>
              <div class="info no-info" v-show="item.info.length==0">no information</div>
            </div>
            <!-- 购物车 -->
            <div class="cart">
              <!-- 价格 -->
              <div class="money-wrap">
                <div class="money">€29,80</div>
                <div class="notice">(€29,80 per Unpacked)</div>
              </div>
              <!-- 购物车 收藏 -->
              <div class="wish-wrap">
                <div class="add-num"><AddNumber/></div>
                <div class="car">
                  <span class="iconfont icon-cart">&#xe62c;</span>
                </div>
                <div class="compare">Compare</div>
                <span class="iconfont icon-wishlist">&#xe8b9;</span>
              </div>
              <!-- 可用库存 -->
              <div class="quantity">
                <span class="text">Avaliable quantity:</span>
                <span class="num">0</span>
                <span class="iconfont icon-info">&#xed50;</span>
              </div>
            </div>
          </div>
          <!-- 折叠框 -->
          <div class="collapse" ref="collapse" :class="{'collapse-active':item.showCollapse==1}" :data-lineid="item.id">
            <!-- 内容区 -->
            <el-row class="main-row" :gutter="24" type="flex" justify="start">
              <!-- 左边 -->
              <el-col 
                :xs="24" 
                :sm="24" 
                :md="24" 
                :lg="15" 
                class="left-col"
              >
                <div class="title">
                  <div class="detail" @click="item.focusDetailDownload=1" :class="{'detail-active':item.focusDetailDownload==1}">
                    <span class="text">Product details</span>
                    <div class="row-line">
                      <span></span>
                    </div>
                  </div>
                  <div class="detail detail2" :class="{'detail-active':item.focusDetailDownload==2}" @click="item.focusDetailDownload=2">
                    <span class="text">Downloads</span>
                    <div class="row-line">
                      <span></span>
                    </div>
                  </div>
                </div>
                <!-- 左边-手风琴 -->
                <div class="product-detail" v-show="item.focusDetailDownload==1">
                  <AccordionColumn :list="item.accordionList" ref="AccordionColumn"/>
                </div>
                <!-- 左边 下载 -->
                <div class="download" v-show="item.focusDetailDownload==2">
                  <!-- 左边-文档 -->
                  <div class="document-wrap">
                    <div class="content">
                      <span class="span1">Documents</span>
                      <span class="span2">Type</span>
                      <span class="span3">Format</span>
                      <span class="span4">Size</span>
                    </div>
                    <div class="content">
                      <span class="span1">Datasheet - OT FIT 80_220-240_24_P</span>
                      <span class="span2">Product Datasheet</span>
                      <span class="span3">pdf</span>
                      <span class="span4">2.7MB</span>
                      <span class="btn">download</span>
                    </div>
                    <div class="content">
                      <span class="span1">Datasheet - OT FIT 80_220-240_24_P</span>
                      <span class="span2">Product Datasheet</span>
                      <span class="span3">pdf</span>
                      <span class="span4">2.7MB</span>
                      <span class="btn">download</span>
                    </div>
                    <div class="content">
                      <span class="span1">Datasheet - OT FIT 80_220-240_24_P</span>
                      <span class="span2">Product Datasheet</span>
                      <span class="span3">pdf</span>
                      <span class="span4">2.7MB</span>
                      <span class="btn">download</span>
                    </div>
                    <div class="content">
                      <span class="span1">Datasheet - OT FIT 80_220-240_24_P</span>
                      <span class="span2">Product Datasheet</span>
                      <span class="span3">pdf</span>
                      <span class="span4">2.7MB</span>
                      <span class="btn">download</span>
                    </div>
                  </div>
                  <!-- 左边-图片 -->
                  <div class="document-wrap">
                    <div class="content">
                      <span class="span1">Images</span>
                      <span class="span2">Type</span>
                      <span class="span3">Format</span>
                      <span class="span4">Size</span>
                    </div>
                    <div class="content">
                      <span class="span1 img-hover">OT FIT 80/220-240/24 P</span>
                      <span class="span2">Background: white, horizontal</span>
                      <span class="span3">png</span>
                      <span class="span4">2.7MB</span>
                      <span class="btn">download</span>
                    </div>
                    <div class="content">
                      <span class="span1 img-hover">OT FIT 80/220-240/24 P</span>
                      <span class="span2">Background: white, horizontal</span>
                      <span class="span3">png</span>
                      <span class="span4">2.7MB</span>
                      <span class="btn">download</span>
                    </div>
                    <div class="content">
                      <span class="span1 img-hover">OT FIT 80/220-240/24 P</span>
                      <span class="span2">Background: white, horizontal</span>
                      <span class="span3">png</span>
                      <span class="span4">2.7MB</span>
                      <span class="btn">download</span>
                    </div>
                    <div class="content">
                      <span class="span1 img-hover">OT FIT 80/220-240/24 P</span>
                      <span class="span2">Background: white, horizontal</span>
                      <span class="span3">png</span>
                      <span class="span4">2.7MB</span>
                      <span class="btn">download</span>
                    </div>
                  </div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col 
                :xs="24" 
                :sm="24" 
                :md="24" 
                :lg="9" 
                class="right-col"
              >
                <div class="title">
                  <div class="detail">
                    <span class="text">Packaging information</span>
                    <div class="row-line">
                      <span></span>
                    </div>
                  </div>
                </div>
                <div class="content">
                  <div class="content-suqare" v-for="i in 1" :key="i">
                    <div class="content-line">
                      <span>Shipping Box EAN:</span>
                      <span>4062172119207</span>
                    </div>
                    <div class="content-line">
                      <span>Pieces in 1 Shipping carton box:</span>
                      <span>15</span>
                    </div>
                    <div class="content-line">
                      <span>Dimension LxWxH:</span>
                      <span>287 x 262 x 201 mm</span>
                    </div>
                    <div class="content-line">
                      <span>Weight:</span>
                      <span>8193.00 g</span>
                    </div>
                    <div class="content-line">
                      <span>Volume:</span>
                      <span>11.96 dm³</span>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <!-- 对话框 -->
    <el-dialog
      title="CV Power supplies with DALI"
      :visible.sync="dialogVisible"
      width="70%">
      <img :src="chooseMoveImgInfo.url" style="height:80%;"/>
    </el-dialog>
  </div>
</template>

<script>
import AddNumber from '@/components/AddNumber'
import Accordion from '@/components/Accordion'
import AccordionColumn from '@/components/AccordionColumn'

export default {
  name:'Detail',
  components:{
    AddNumber,
    Accordion,
    AccordionColumn
  },
  data(){
    return {
      show:{
        showChoose:false,//下拉选择框是否展示
        showLeftArrowTip:false, //展示左边箭头的提示
        showRightArrowTip:false //展示右边箭头的提示
      },
      timeFlag:false, //滑动图片是否开始下一次动画的标记
      chooseMoveImgInfo:{}, //选中滑动的其中一张图片
      moveImgArr:[
        {id:1,url:'https://dammedia.osram.info/media/img/osram-dam-21779256/s,y,400/scale.png'},
        {id:2,url:'https://dammedia.osram.info/media/img/osram-dam-21779256/s,y,400/scale.png'},
        {id:3,url:'https://dammedia.osram.info/media/img/osram-dam-21779256/s,y,400/scale.png'},
        {id:4,url:'https://dammedia.osram.info/media/img/osram-dam-21779256/s,y,400/scale.png'},
        {id:5,url:'https://dammedia.osram.info/media/img/osram-dam-21779256/s,y,400/scale.png'},
        {id:6,url:'https://dammedia.osram.info/media/img/osram-dam-21779256/s,y,400/scale.png'},
        {id:7,url:'https://dammedia.osram.info/media/img/osram-dam-21779256/s,y,400/scale.png'},
        {id:8,url:'https://dammedia.osram.info/media/img/osram-dam-21779256/s,y,400/scale.png'},
        {id:9,url:'https://dammedia.osram.info/media/img/osram-dam-21779256/s,y,400/scale.png'},
        {id:10,url:'https://dammedia.osram.info/media/img/osram-dam-21779256/s,y,400/scale.png'}
      ], //移动图片的列表（左边放大镜下面的图片）
      productDetail:{id:1,showChoose:false,chooseList:[
          {id:1,text1:'OTI DALI 50/220-240/24',text2:'EAN: 4062172177924 '},
          {id:2,text1:'OTI DALI 50/220-240/24',text2:'EAN: 4062172177924'},
          {id:3,text1:'OTI DALI 50/220-240/24',text2:'EAN: 4062172177924'},
          {id:4,text1:'OTI DALI 50/220-240/24',text2:'EAN: 4062172177924'}
      ]}, //当前产品的详情信息
      dialogVisible:false,//是否展示放大图片的对话框
      accordionList:[
        {id:1,show:0,title:'Product family features',content:[
          'Small housing design for mounting in coves or linear luminaires',
          'Versatile scope of application thanks to an output power range of up to 300 W',
          'Robust and durable design for outdoor applications'
        ]},
        {id:2,show:0,title:'Areas of application',content:[
          'For example luminous signage in areas such as hotels',
          'Public squares and architecture lighting',
          'Suitable for indoor and outdoor SELV installations'
        ]},
        {id:3,show:0,title:'Ecodesign regulation information',content:[
          'Intended for use with LED modules.The forward voltage of the LED light source shall be within the defined operating window of the control gear in all operating conditions including dimming if applicable.Separate control gear and light sources must be disposed of at certified disposal companies in accordance with Directive 2012/19/EU (WEEE) in the EU and with Waste Electrical and Electronic Equipment (WEEE) Regulations 2013 in the UK. For this purpose, collection points for recycling centres and take-back systems (CRSO) are available from retailers or private disposal companies, which accept separate control gear and light sources free of charge. In this way, raw materials are conserved and materials are recycled.'
        ]}
      ],//手风琴内容
      variantTitleList:['Nominal output power','Dimmable','Length','Width','Height'], //种类标题的列表
      variantList:[],//下面种类的内容列表
    }
  },
  methods:{
    //图片向左向右滑动
    movePic(flag){
      let picUl = this.$refs.picUl
      let left = getComputedStyle(picUl,null)['transform'].substring(7).split(',')[4]
      if(this.timeFlag===false){
        this.timeFlag=true
        if(flag===1){
          /* 精度问题只有小数问题才会有，整数是没有精度问题的，所以这里不需要用toFixd等工具 */
          let finalLeft=(Number(left)+152)
          if(finalLeft>0){
            finalLeft=0
            this.show.showLeftArrowTip=true
          }
          picUl.style.transform = `translate(${finalLeft}px)`
          picUl.style.transitionDuration = "0.8s"
          setTimeout(()=>{
            this.timeFlag=false
            this.show.showLeftArrowTip=false
          },900)
        }
        if(flag===2){
          /* 精度问题只有小数问题才会有，整数是没有精度问题的，所以这里不需要用toFixd等工具 */
          let finalLeft=(left-152)
          if(finalLeft<-(this.moveImgArr.length-1)*152){
            finalLeft=-(this.moveImgArr.length-1)*152
            this.show.showRightArrowTip=true
          }
          picUl.style.transform = `translate(${finalLeft}px)`
          picUl.style.transitionDuration = "0.8s"
          setTimeout(()=>{
            this.timeFlag=false
            this.show.showRightArrowTip=false
          },900)
        }
      }
    },
    //选中滑动的图片
    chooseMoveImg(item){
      this.chooseMoveImgInfo=item
    },
    //选中右边下拉框中的其中一个
    handleChooseSelect(){
      this.productDetail.showChoose=false
    },
    //右边下拉框的鼠标移出
    mouseleave(){
      this.productDetail.showChoose=false
    },
    //初始化下面产品具体信息的手风琴高度
    getInitialAccordionHeight(){
      console.log('getInitialAccordionHeight')
      let objs = this.$refs.collapse
      if(Array.isArray(objs) && objs.length>0){
        objs.forEach((val)=>{
          val.dataset.height=Number(val.offsetHeight)+"px"
          val.style.height="0px"
        })
      }
    },
    //展示/隐藏下面产品列表的折叠面板
    showCollapse(item){
      let relatedIndex ;
      this.variantList.forEach((val,i)=>{
        if(val.id===item.id){
          relatedIndex=i
        }
      })
      let objs = this.$refs.collapse
      if(Array.isArray(objs) && objs.length>0){
        objs.forEach((val)=>{
          if(item.id==val.dataset.lineid){
            if(item.showCollapse==0){
              //展示
              val.style.height=val.dataset.height
              item.showCollapse=1
              setTimeout(()=>{
                if(item.showCollapse==1){
                  val.style.height='auto'
                }
              },500)
            }else{
              //不展示
              val.style.height=val.offsetHeight+'px'
              setTimeout(()=>{
                val.style.height='0px'
                this.$refs.AccordionColumn[relatedIndex].resetShow()
              },0)
              setTimeout(()=>{
                item.focusDetailDownload=1
              },500)
              item.showCollapse=0
            }
          }
        })
      }
      this.$set(this.variantList,relatedIndex,item)
    },

    showBigImg(){
      this.dialogVisible=true
    },
    goBottom(){
      let wrap=document.getElementsByClassName('wrap')[0]
      let wrapHeight=getComputedStyle(wrap,null)['height']
      wrapHeight=parseInt(wrapHeight)-1000
      document.documentElement.scrollTop=wrapHeight
    }
  },
  mounted(){
    this.chooseMoveImgInfo=this.moveImgArr[0]
    //请求拿到下面分类种类的列表数据
    setTimeout(()=>{
      this.variantList=[
        {id:1,showCollapse:0,focusDetailDownload:1,info:['80 W','No','170.0 mm','53.0 mm','31.5 mm'],accordionList:[
          {id:1,show:0,title:'Dimensions & weight',content:[
            {label:'Product weight',val:'513.00 g'},
            {label:'Mounting hole spacing, width',val:'27.0'},
            {label:'Mounting hole spacing, length',val:'153 mm'}
          ]},
          {id:2,show:0,title:'Logistical data',content:[
            {label:'Commodity code',val:'850440829000'}
          ]},
          {id:3,show:0,title:'Electrical data',content:[
            {label:'Device power loss',val:'10.3'},
            {label:'Nominal output voltage',val:'24'},
            {label:'Inrush current',val:'< 50'},
            {label:'Mains frequency',val:'50…60 Hz'},
            {label:'Maximum output power REM',val:'at steady state'},
            {label:'Efficiency in full-load',val:'88.5 %'}
          ]}
        ]},
        {id:2,showCollapse:0,focusDetailDownload:1,info:['80 W','No','170.0 mm','53.0 mm','31.5 mm'],accordionList:[
          {id:1,show:0,title:'Dimensions & weight',content:[
            {label:'Product weight',val:'513.00 g'},
            {label:'Mounting hole spacing, width',val:'27.0'},
            {label:'Mounting hole spacing, length',val:'153 mm'}
          ]},
          {id:2,show:0,title:'Logistical data',content:[
            {label:'Commodity code',val:'850440829000'}
          ]},
          {id:3,show:0,title:'Electrical data',content:[
            {label:'Device power loss',val:'10.3'},
            {label:'Nominal output voltage',val:'24'},
            {label:'Inrush current',val:'< 50'},
            {label:'Mains frequency',val:'50…60 Hz'},
            {label:'Maximum output power REM',val:'at steady state'},
            {label:'Efficiency in full-load',val:'88.5 %'}
          ]}
        ]},
        {id:3,showCollapse:0,focusDetailDownload:1,info:['80 W','No','170.0 mm','53.0 mm','31.5 mm'],accordionList:[
          {id:1,show:0,title:'Dimensions & weight',content:[
            {label:'Product weight',val:'513.00 g'},
            {label:'Mounting hole spacing, width',val:'27.0'},
            {label:'Mounting hole spacing, length',val:'153 mm'}
          ]},
          {id:2,show:0,title:'Logistical data',content:[
            {label:'Commodity code',val:'850440829000'}
          ]},
          {id:3,show:0,title:'Electrical data',content:[
            {label:'Device power loss',val:'10.3'},
            {label:'Nominal output voltage',val:'24'},
            {label:'Inrush current',val:'< 50'},
            {label:'Mains frequency',val:'50…60 Hz'},
            {label:'Maximum output power REM',val:'at steady state'},
            {label:'Efficiency in full-load',val:'88.5 %'}
          ]}
        ]}
      ]
      // setTimeout(()=>{
        console.log(this.variantList)
        setTimeout(()=>{
          this.getInitialAccordionHeight()
        },0)
      // },1000)
    },100)
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>