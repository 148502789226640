<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap fieldFailuers-wrap">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/selectRMAtype" 
        >My Service Point</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/rmaList" 
        >My Tickets</a>
        <span class="line">/</span>
        <a
          class="text text2"
          :href="'/#/fieldFailuresDetail?flag='+$route.query.flag+'&caseNo='+$route.query.caseNo" 
        >{{formCommon.crmCaseNo}}</a>
      </div>

      <div class="big-title">
        <span class="text1">Field/Application Incident</span>
        <span>{{formCommon.crmCaseNo}}</span>
      </div>
      
      <div class="line-title">
        <span class="text">General data</span>
      </div>
      <!-- 基础内容 -->
      <div class="form">

        <div class="square">
          <span class="text">Status:</span>
          <div class="line-field">{{formCommon.cqmStatus}}</div>
        </div>
        
        <div class="square">
          <span class="text">Case No.:</span>
          <div class="line-field">{{formCommon.crmCaseNo}}</div>
        </div>

        <div class="square" v-if="formCommon.rmaNo">
          <span class="text">RMA No.:</span>
          <div class="line-field">{{formCommon.rmaNo}}</div>
        </div>

        <div class="square">
          <span class="text">Date:</span>
          <div class="line-field">{{formatDateNoTime(formCommon.caseDate)}}</div>
        </div>

        <div class="square">
          <span class="text">Customer name:</span>
          <div class="line-field">{{formCommon.customerName}}</div>
        </div>

        <div class="square">
          <span class="text">Customer No.:</span>
          <div class="line-field">{{formCommon.customerAccountNo}}</div>
        </div>

        <div class="square">
          <span class="text">Customer Contact Name:</span>
          <div class="line-field">{{formCommon.contactName}}</div>
        </div>

        <div class="square">
          <span class="text">Customer Contact Email:</span>
          <div class="line-field">{{formCommon.contactEmail}}</div>
        </div>

        <div class="square">
          <span class="text">Request type:</span>
          <div class="line-field">{{getSelectText(requestTypeArr,formCommon.requestType)}}</div>
        </div>

        <div class="square">
          <span class="text">Request type desc:</span>
          <div class="line-field">{{formCommon.requestTypeDesc}}</div>
        </div>

        <div class="square">
          <span class="text">Your reference:</span>
          <div class="line-field">{{formCommon.yourReference}}</div>
        </div>

      </div>
      <!-- 列表内容 -->
      <div v-for="(item,i) in productList" :key="i">
        <div class="line-title">
          <span class="text">{{i+1}}° Product information</span>
        </div>
        <div class="form">

          <div class="square">
            <span class="text">Reference / Site Name / Location:</span>
            <div class="line-field">{{item.siteName}}</div>
          </div>

          <div class="square">
            <span class="text">Application:</span>
            <div class="line-field">{{getSelectText(applicationArr,item.application)}}</div>
          </div>

          <div class="square">
            <span class="text">Application detail:</span>
            <div class="line-field">{{item.applicationDetail}}</div>
          </div>

          <div class="square">
            <span class="text">Product covered:</span>
            <div class="line-field">{{getSelectText(driverCoveredArr,item.deviceCovered)}}</div>
          </div>

          <div class="square">
            <span class="text">Mains Power Supply Type:</span>
            <div class="line-field">{{getSelectText(powerGridSystemArr,item.powerGridSystem)}}</div>
          </div>

          <div class="square">
            <span class="text">Controlling device/system:</span>
            <div class="line-field">{{item.controllingSystem}}</div>
          </div>

          <div class="square">
            <span class="text">Supply / Input Voltage (V):</span>
            <div class="line-field">{{item.inputVoltage}}</div>
          </div>

          <div class="square">
            <span class="text">Output / LED module Current (mA):</span>
            <div class="line-field">{{item.outputCurrent}}</div>
          </div>

          <div class="square">
            <span class="text">Output / LED module Voltage (V):</span>
            <div class="line-field">{{item.outputVoltage}}</div>
          </div>

          <div class="square">
            <span class="text">Operating hours / day:</span>
            <div class="line-field">{{item.operatingHd}}</div>
          </div>

          <div class="square">
            <span class="text">Operating hours / year:</span>
            <div class="line-field">{{item.operatingHy}}</div>
          </div>

          <div class="square">
            <span class="text">Ambient temperature Ta (°C):</span>
            <div class="line-field">{{item.ambientTemperature}}</div>
          </div>

          <div class="square">
            <span class="text">Case temperature Tc (°C):</span>
            <div class="line-field">{{item.caseTemperature}}</div>
          </div>

          <div class="square">
            <span class="text">Problem:</span>
            <div class="line-field">{{getSelectText(problemArr,item.problem)}}</div>
          </div>

          <div class="square">
            <span class="text">Problem Details:</span>
            <div class="line-field">{{item.problemDetails}}</div>
          </div>

          <div class="square">
            <span class="text">Date of purchase:</span>
            <div class="line-field">{{formatDateNoTime(item.purchaseDate)}}</div>
          </div>

          <div class="square">
            <span class="text">Purchase invoice number:</span>
            <div class="line-field">{{item.purchaseInvoiceNumber}}</div>
          </div>

          <div class="square">
            <span class="text">Installation date:</span>
            <div class="line-field">{{formatDateNoTime(item.installationDate)}}</div>
          </div>

          <div class="square">
            <span class="text">First Failure date:</span>
            <div class="line-field">{{formatDateNoTime(item.firstFailDate)}}</div>
          </div>

          <div class="square">
            <span class="text">Installed quantity:</span>
            <div class="line-field">{{item.installedQty}}</div>
          </div>

          <div class="square">
            <span class="text">Product:</span>
            <div class="line-field">{{item.claimedProduct}}</div>
          </div>

          <div class="square">
            <span class="text">Claimed Qty:</span>
            <div class="line-field">{{item.claimedQty}}</div>
          </div>

          <div class="square">
            <span class="text">Quantity to be returned:</span>
            <div class="line-field">{{item.qtyToReturn}}</div>
          </div>

          <div class="square">
            <span class="text">Identcode:</span>
            <div class="line-field">{{item.identcode}}</div>
          </div>

          <div class="square">
            <span class="text">Date code:</span>
            <div class="line-field">{{item.dateCode}}</div>
          </div>

        </div>
      </div>
      <!-- 附件内容 -->
      <div class="file-wrap" v-if="fileList.length>0">
        <div class="title">File Name</div>
        <div class="file-line-wrap">
          <div class="file-line" v-for="item in fileList" :key="item.fileId">
            <span class="iconfont icon-file">&#xe601;</span>
            <span class="file-name" @click="handleDownload(item)">{{item.fileName}}</span>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import {api_claim_detail,api_claim_select,api_order_confirm_download} from '@/api/api'
import {getAction,downFileGet} from '@/api/tool'
import Vue from 'vue'

export default {
  name:'FieldFailuresDetail',
  data(){
    return {
      formCommon:{
        crmCaseNo:'',
        rmaNo:'',
        caseDate:'',
        cqmStatus:'',
        customerName:'',
        customerAccountNo:'',
        contactName:'',
        contactEmail:'',
        requestType:'',
        requestTypeDesc:'',
        yourReference:''
      },
      fileList:[],
      productList:[],
      requestTypeArr:[], //下拉
      applicationArr:[], //下拉
      driverCoveredArr:[], //下拉
      powerGridSystemArr:[], //下拉
      problemArr:[] //下拉
    }
  },
  methods:{
    /* 获取下拉数据 */
    getSelect(){
      let params = {
        formType:'fai_request_type,fai_app_type,fai_dc_type,fai_pgs_type,fai_problem_type'
      }
      getAction(api_claim_select,params).then(res=>{
        res.forEach((val)=>{
          if(val.formType=='fai_request_type'){
            this.requestTypeArr=val.claimSelect
          }
          if(val.formType=='fai_app_type'){
            this.applicationArr=val.claimSelect
          }
          if(val.formType=='fai_dc_type'){
            this.driverCoveredArr=val.claimSelect
          }
          if(val.formType=='fai_pgs_type'){
            this.powerGridSystemArr=val.claimSelect
          }
          if(val.formType=='fai_problem_type'){
            this.problemArr=val.claimSelect
          }
        })
      })
    },
    /* 获取详情 */
    getDetail(){
      let params = {
        flag:this.$route.query.flag,
        caseNo:this.$route.query.caseNo
      }
      this.allLoading=true
      getAction(api_claim_detail,params).then(res=>{
        this.allLoading=false
        this.formCommon={
          crmCaseNo:res.crmCaseNo,
          caseDate:res.caseDate,
          cqmStatus:res.cqmStatus,
          customerName:res.customerName,
          customerAccountNo:res.customerAccountNo,
          contactName:res.contactName,
          contactEmail:res.contactEmail,
          rmaNo:res.rmaNo,
          requestType:res.requestType,
          requestTypeDesc:res.requestTypeDesc,
          yourReference:res.yourReference
        }
        this.productList=res.lineVoList
        this.fileList=res.files
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载文件 */
    handleDownload(obj){
      let params = {
        filezIds:obj.filezId
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    }
  },
  mounted(){
    this.getSelect()
    this.getDetail()
  }
  
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>