<template>
  <div class="quick-wrap" v-show="show" @touchmove.prevent>
    <div class="quick-order">
      <div class="icon-close" @click="close">
        <span class="iconfont icon1">&#xe614;</span>
        <span class="iconfont icon2">&#xe606;</span>
      </div>
      <div class="quick-title">{{L.requestPricelist}}</div>
      <!-- 输入我们目录中的产品代码以实现快速订购。 -->
      <div class="remark">{{L.selectEffectivePrices}}</div>
      <div class="inp-wrap">
        <el-date-picker
          :clearable="false"
          v-model="searchDate"
          value-format="yyyy-MM-dd"
          format="dd-MM-yyyy"
          :picker-options="pickerOptions"
          type="date">
        </el-date-picker>
      </div>
      <div class="btn-wrap" @click="downloadPricelist" v-loading="loading">
        <span class="iconfont icon-cart">&#xe61c;</span>
        {{L.downloadPricelist}}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {api_download_pricelist} from '@/api/api'
import {downFilePost} from '@/api/tool'

export default {
  name:'RequestPricelist',
  data() {
    return {
      loading:false,
      show:false,
      searchDate:'',
      pickerOptions:{
        disabledDate: e => {
          let chooseDate = moment(new Date(e)).format('YYYY-MM-DD') //当选择的日期
          let chooseTime = new Date(chooseDate).valueOf() //选择的时间戳
          let date = moment(new Date()).format('YYYY-MM-DD') //当前日期
          let dateTime = new Date(date).valueOf() //当前日期时间戳
          //大于等于今天的时间，可以选择。反之不能选择
          if(chooseTime>=dateTime){
            return false
          }else{
            return true
          }
        }
      }
    }
  },
  methods:{
    open(){
      this.searchDate=moment(new Date).format('YYYY-MM-DD')
      this.show=true
    },
    close(){
      this.show=false
    },
    downloadPricelist(){
      let params = {
        searchDate:this.searchDate
      }
      this.loading=true
      downFilePost(api_download_pricelist,params).then(res=>{
        this.loading=false
        this.downloadFile(res)
      }).catch(res=>{
        if(res.response.status==304){
          this.$message.warning('The list you have queried is empty. Please select another date or contact your Customer Service.')
        }
        this.loading=false
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../public/css/public.less');
/* 快速下单 */
.quick-wrap{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 999;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
  .quick-order{
    border-radius: 2px;
    position: absolute;
    &:extend(.main-box-shadow);
    width:450px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    padding:40px;
    box-sizing: border-box;
    text-align: left;
    .icon-close{
      // background-color: red;
      position: absolute;
      right:14px;
      top:14px;
      .icon1{
        font-size: 24px;
        display: block;
        color:#888;
      }
      .icon2{
        font-size: 24px;
        display: none;
        color:@mainColor;
      }
      &:hover{
        cursor: pointer;
        .icon1{
          display: none;
        }
        .icon2{
          display: block;
        }
      }
    }
    .quick-title{
      font-size: 16px;
      font-weight: 600;
    }
    .remark{
      margin-top: 16px;
    }
    .inp-wrap{
      margin-top: 10px;
      
    }
    .btn-wrap{
      margin-top: 26px;
      text-align: center;
      width:100%;
      color:#fff;
      background-color: @mainColor;
      padding:8px 6px;
      box-sizing: border-box;
      border:1px solid @mainColor;
      border-radius: 2px;
      &:extend(.transtion-time);
      .icon-cart{
        margin-right: 6px;
      }
      &:hover{
        cursor: pointer;
        color:@mainColor;
        background-color: #fff;
        &:extend(.transtion-time);
      }
    }
  }
}

</style>