<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap order-details">
      <!-- 公告 -->
      <div class="main-wrap announcement" v-if="$store.state.bannerInfo.notice && $store.state.bannerInfo.notice.trim()">
        <span class="text">{{$store.state.bannerInfo.notice}}</span>
        <div class="left-line"></div>
        <div class="right-line"></div>
      </div>
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/myOrders" 
        >{{L.myOrder}}</a>
        <span class="line">/</span>
        <a
          class="text text2"
          :href="'/#/orderDetail?ebsorderNo='+$route.query.ebsorderNo" 
        >{{$route.query.ebsorderNo}}</a>
      </div>
      <div class="order-detail-title">
        {{L.order}} {{$route.query.ebsorderNo}}
      </div>
      <!-- 基本信息 -->
      <div class="basic-information">
        <div class="info-left order-detail-left">
          <!-- 客户信息 -->
          <div class="customer">
            <div class="title">
              {{L.customerInfo}}
            </div>
            <div class="content">
              <!-- sold to设置成false,暂时不显示 -->
              <div class="line" v-if="false">
                <div class="left">
                  <!-- 售至 -->
                  <span class="left-text1">{{L.soldTo}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.soldTo}}</span>
                </div>
              </div>
              <div class="line">
                <div class="left">
                  <!-- 发货至 -->
                  <span class="left-text1">{{L.shipTo}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.shipTo}}</span>
                </div>
              </div>
              <div class="line">
                <div class="left">
                  <!-- 开票至 -->
                  <span class="left-text1">{{L.billTo}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.billTo}}</span>
                </div>
              </div>
              <!-- payer设置成false,暂时不显示 -->
              <div class="line" v-if="false">
                <div class="left">
                  <!-- 付款方 -->
                  <span class="left-text1">{{L.payer}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.payer}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 采购订单信息 -->
          <div class="customer">
            <div class="title">
              <!-- 采购订单信息 -->
              {{L.purchaseOrderInfo}}
            </div>
            <div class="content">
              <div class="line">
                <div class="left">
                  <!-- 交货条款 -->
                  <span class="left-text1">{{L.incoterms}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.incoTerms}}</span>
                </div>
              </div>
              <div class="line">
                <div class="left">
                  <!-- 付款条款 -->
                  <span class="left-text1">{{L.paymentTerms}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.paymentTerms}}</span>
                </div>
              </div>
              <div class="line">
                <div class="left">
                  <!-- 采购订单编号 -->
                  <span class="left-text1">{{L.PONumber}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.poNo}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- comment -->
          <div class="customer" v-if="orderInfo.comment">
            <div class="title">
              Order Remarks
            </div>
            <div class="content">
              <div class="line">
                <div class="left">
                  <!-- 交货条款 -->
                  <span class="left-text1">Comment:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.comment}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-right order-detail-right">
          <!-- 订单汇总 -->
          <div class="order-summary">{{L.orderSummary}}</div>
          <div class="content">
            <div class="detail-info">
              <!-- 订单状态 -->
              <span class="text-left">{{L.orderStatus}}:</span>
              <span class="text-right">{{orderInfo.orderStatus}}</span>
            </div>
            <div class="detail-info">
              <!-- 订购日期 -->
              <span class="text-left">{{L.orderDate}}:</span>
              <span class="text-right">{{orderInfo.orderDate}}</span>
            </div>
            <div class="detail-info">
              <!-- 订单来源 -->
              <span class="text-left">{{L.placedVia}}:</span>
              <span class="text-right">{{orderInfo.placedVia}}</span>
            </div>
            <div class="detail-info">
              <!-- 下单方式 -->
              <span class="text-left">{{L.placedBy}}:</span>
              <span class="text-right">{{orderInfo.placedBy}}</span>
            </div>
          </div>        
          <div class="total-price">
            <div class="price-left">
              <!-- 不含税总金额 -->
              <div class="total">{{L.orderTotalAmount}}</div>
              <!-- 个产品项 -->
              <div class="item">{{orderInfo.itemCount}} {{L.orderltems}}</div>
            </div>
            <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
              <div class="price-right">{{formatMoney(orderInfo.totalPrice,orderInfo.currency)}}</div>
            </template>
          </div>
          <!-- 订单确认书(PDF) -->
          <div 
            class="btn-order-confirm" 
            :class="{'active-order':orderInfo.fileLocation && ($store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP'))}" 
            @click="orderConfirmation"
          >{{L.orderConfirmationBook}}</div>
          <!-- 再次订购 -->
          <div class="re-order" @click="reOrder">{{L.reOrder}}</div>
        </div>
      </div>
      <!-- 产品概述 -->
      <div class="product-overview">
        <!-- 大标题 -->
        <div class="title">
          <!-- 订单详情 -->
          <span class="text">{{L.productOverview}}</span>
          <!-- 个产品项 -->
          <span class="quantity">{{orderInfo.itemCount}} {{L.orderltems}}</span>
        </div>
        <!-- 一大行 -->
        <div class="line" v-for="item in orderList" :key="item.headVo && item.headVo.skuId">
          <!-- 内容标题 -->
          <div class="line-title">
            <div class="title-left"></div>
            <div class="title-right">
              <!-- 预计到货日期 -->
              <div class="date">{{L.deliveryDateEstimate}}:</div>
              <!-- 数量 -->
              <div class="quantity">{{L.quantity}}:</div>
              <div class="price"></div>
            </div>      
          </div>
          <!-- 主内容 -->
          <div class="main-content">
            <!-- 左边的 -->
            <div class="left">
              <div class="info">
                <div class="text">
                  <div class="text1">
                    <span v-if="item.canJump!=1" class="span1" href="javascript:;" @click="handleSkuName" v-html="item.productName"></span>
                    <a v-if="item.canJump==1" class="span1" :href="detailUrl(1,item.eanCode)" v-html="item.productName"></a>
                  </div>
                  <div class="text2">{{item.eanOrIc}} {{item.productNo}}</div>
                  <!-- quote No. -->
                  <!-- 隐藏quote -->
                  <div class="text2" v-if="item.quoteNumber">
                    <span>ERP Quote ID: </span>
                    <a class="quote-no" :href="'/#/quotesDetail?erpQuoteId='+item.quoteNumber">{{item.quoteNumber}}</a>
                  </div>
                  <!-- 需求的交货日期 -->
                  <div class="text2">{{L.requestedDeliveryDate}}: {{formatDate(item.requestDate)}}</div>
                </div>
              </div>
            </div>
            <!-- 右边的一行 -->
            <div class="right">
              <div class="right-line">
                <!-- 基本内容 -->
                <div class="basic-info">
                  <div class="date">
                    <span class="iconfont icon-date">&#xe807;</span>
                    <span class="text-date">{{formatDate(item.estimateDate)}}</span>
                  </div>
                  <div class="quantity">
                    {{item.quantity}}
                  </div>
                  <div class="price">
                    <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                      <div class="left-price">
                        <div class="price-num">{{formatMoney(item.lineAmount,orderInfo.currency)}}</div>
                        <div class="carton-box">({{formatMoney(item.linePrice,orderInfo.currency)}} {{L.per}} {{item.lineUnit}})</div>
                      </div>
                    </template>
                  </div>
                </div>
                <!-- 装箱内容 -->
                <div class="carton-box-remark" v-if="item.ifVisible==1">
                  <div class="line-title" v-for="item2,i in item.qtyMsg" :key="i">
                    <div class="date"></div>
                    <div class="quantity">{{item2}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 下载那一行 -->
          <div class="download-bottom">
            <div class="download">
              <!-- 交货单据 -->
              <div class="delivery-note text" v-if="item.deliverNoteFileLocation" @click="handleDelivery(item,1)">
                <span class="iconfont iconfont-pdf">&#xe60a;</span>
                <span>{{L.deliveryDocument}}</span>
              </div>
              <a class="delivery-note text" v-if="item.carrierTrackingLink" :href="item.carrierTrackingLink" target="_blank">
                <span class="iconfont iconfont-pdf">&#xe621;</span>
                <span>{{L.carrierTrackingLink}}</span>
              </a>
              <a
                v-if="item.inveoiceType=='A'"
                class="text"
                :href="'/#/invoiceDetail?invoiceHeaderId='+item.invoiceId+'&invoiceNo='+item.invoiceNumber" 
              >{{L.invocie}} {{item.invoiceNumber}}</a>
              <a
                v-if="item.inveoiceType=='B'"
                class="text"
                :href="'/#/creditNotesDetail?noteId='+item.creditNoteNumber" 
              >Credit {{item.creditNoteNumber}}</a>
              <a
                v-if="item.inveoiceType=='C'"
                class="text"
                :href="'/#/debitNotesDetail?noteId='+item.debitNoteNumber" 
              >Debit {{item.debitNoteNumber}}</a>
              <!-- 发票单据 -->
              <!-- <span class="text" v-if="item.invoiceFileLocation && ($store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP'))" @click="handleDelivery(item,2)">{{L.invoiceDocument}}</span> -->
            </div>
            <div class="status">
              <span class="status-text">{{item.lineStatus}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddCartErr ref="AddCartErr" />
    <AddCartResult ref="AddCartResult" />
  </div>
</template>

<script>
import {postAction} from '@/api/tool'
import {api_order_detail,api_quick_order,api_order_confirm_download} from '@/api/api'
import { getAction,downFileGet } from '../../api/tool'
import Vue from 'vue'
import AddCartErr from '@/components/AddCartErr'
import AddCartResult from '@/components/AddCartResult'

export default {
  name:'OrderDetail',
  components:{
    AddCartErr,
    AddCartResult
  },
  data(){
    return {
      // ouList:['OU-PL','OU-NL','OU-UK','OU-SK'], //这些OU的要展示公告
      orderInfo:{},
      orderList:[]
    }
  },
  methods:{
    /* 如果不是EAN的，点击skuName，报错提示 */
    handleSkuName(){
      this.$message.warning(this.L.sorryResult)
    },
    /* 获取order详情数据 */
    getOrderDetail(){
      let params={
        ebsorderNo:this.$route.query.ebsorderNo
      }
      this.allLoading=true
      postAction(api_order_detail,params).then(res=>{
        this.allLoading=false
        this.orderInfo=res
        this.orderList=res.orderItems
        console.log(this.orderList)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 再下一单,这里调快速下单的接口 */
    reOrder(){
      let str = ''
      this.orderList.forEach((val)=>{
        str=str + val.productNo + ' ' + val.quantity + '\n'
      })
      let params={
        quickContent:str
      }
      this.allLoading=true
      postAction(api_quick_order,params).then(res=>{
        this.allLoading=false
        this.getCartCount()
        //0说明全部成功
        if(res.errFlag==0){
          this.$refs.AddCartResult.open(res.errFlag)
        //1说明部分成功,2说明失败
        }else{
          this.$refs.AddCartResult.open(res.errFlag,res.errList)
        }
      }).catch(res=>{
        this.allLoading=false
        this.$message.error(res.msg)
      })
    },
    /* 从后端获取文件链接的数组,再下载文件到本地 */
    getLocationAndDownload(params){
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res,1)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 订单确认书 */
    orderConfirmation(){
      let params = {
        filezIds:this.orderInfo.fileLocation
      }
      if(!params.filezIds){
        return
      }
      if(!this.$store.state.userInfo.accountRules.includes('SP')){
        return
      }
      this.getLocationAndDownload(params)
    },
    /* Delivery Document & Invoice Document,pdf */
    handleDelivery(item,flag){
      let params = {
        filezIds:flag==1?item.deliverNoteFileLocation:item.invoiceFileLocation
      }
      if(!params.filezIds){
        return
      }
      this.getLocationAndDownload(params)
    }
  },
  mounted(){
    this.getOrderDetail()
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>

