<template>
  <div class="wrap" v-loading="allLoading">
    <!-- 语言设置 -->
    <div class="language" @mouseenter="mouseenter(0)" @mouseleave="mouseleave(0)">
      <span class="iconfont iconfont0">&#xe618;</span>
      <span class="text">{{$store.state.nowLanguage}}</span>
      <span class="iconfont down" :class="{'active-down':!show.showArrow}">&#xeb8b;</span>
      <transition
        @enter="enter"
        @afterEnter="afterEnter"
        @leave="leave"
        @afterLeave="afterLeave"
      >
        <div class="popover-wrap" v-show="!show.showArrow">
          <div
            v-for="item in $store.state.languageList" 
            :key="item.languageId"
            @click="changeLanguage(item)">{{item.languageName}}</div>
        </div>
      </transition>
    </div>
    <!-- 注册框框 -->
    <div class="main-wrap center register-width">
      <div class="img-wrap">
        <img src="../../assets/logo_en.png">
      </div>
      <!-- 发送邮件 -->
      <div class="title">- {{L.sendEmail}} -</div>
      <div class="content">
        <div class="left">
          <!-- 请提供以下细节。 -->
          <div class="please">{{L.pleaseProvide}}</div>
          <div class="email">
            <div class="text">
              <!-- 邮箱地址 -->
              <span>{{L.emailAddress}}</span>
            </div>
            <!-- 此信息是必需的。 -->
            <div v-show="show.showEmail" class="warn">{{L.infoNeed}}</div>
            <input type="text" v-model="email" :class="{'warn-border':show.showEmail}" @input="handleInput(1,$event)"/>
          </div>
        </div>
      </div>
      <div class="btn">
        <button @click="handleSubmit">{{L.continue}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css';
import {postAction,postFormAction} from '@/api/tool'
import {api_account_activation,api_send_change_password_address} from '@/api/api'

export default {
  name: 'Login',
  data(){
    return {
      email:'',
      show:{
        showEmail:false,
        showArrow:true
      }
    }
  },
  methods:{
    /* 鼠标进入，变成false，展示 */
    mouseenter(flag){
      if(flag==0){
        this.show.showArrow=false
      }
    },
    /* 鼠标移开 */
    mouseleave(flag){
      if(flag==0){
        this.show.showArrow=true
      }
    },
    handleInput(flag,e){
      if(flag==1){
        if(e.target.value){
          this.show.showEmail=false
        }else{
          this.show.showEmail=true
        }
      }
    },
    handleSubmit(){
      if(!this.email){
        this.show.showEmail=true
      }
      if(!this.email){
        this.$message({
          message: this.L.fillOutTry,
          type: 'error'
        });
        return
      }
      this.allLoading=true
      let params = {
        email:this.email,
        code:this.$route.query.code
      }
      //存在code，代表首次激活邮箱
      if(params.code){
        postAction(api_account_activation,params).then(()=>{
          this.allLoading=false
          this.goHasSendEmail()
        }).catch(()=>{
          this.allLoading=false
        })
      }else{
        //不存在code，代表发送更改密码的邮件
        postFormAction(api_send_change_password_address,params).then(()=>{
          this.allLoading=false
          this.goHasSendEmail()
        }).catch(()=>{
          this.allLoading=false
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>