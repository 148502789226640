<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap order-details">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/invoiceList" 
        >{{L.myInvoices}}</a>
        <span class="line">/</span>
        <a
          class="text text2"
          :href="'/#/invoiceDetail?invoiceHeaderId='+$route.query.invoiceHeaderId+'&invoiceNo='+$route.query.invoiceNo" 
        >{{$route.query.invoiceNo}}</a>
      </div>
      <div class="order-detail-title">
        {{L.invoice}} {{$route.query.invoiceNo}}
      </div>
      <!-- 基本信息 -->
      <div class="basic-information">
        <div class="info-left order-detail-left">
          <!-- 客户信息 -->
          <div class="customer">
            <div class="title">
              {{L.customerInfo}}
            </div>
            <div class="content">
              <div class="line" v-if="false">
                <div class="left">
                  <!-- 售至 -->
                  <span class="left-text1">{{L.soldTo}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.soldTo}}</span>
                </div>
              </div>
              <div class="line">
                <div class="left">
                  <!-- 发货至 -->
                  <span class="left-text1">{{L.billTo}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.billTo}}</span>
                </div>
              </div>
              <div class="line">
                <div class="left">
                  <!-- 开票至 -->
                  <span class="left-text1">{{L.shipTo}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.shipTo}}</span>
                </div>
              </div>
              <div class="line" v-if="orderInfo.deliveryTo">
                <div class="left">
                  <!-- 交货方 -->
                  <span class="left-text1">{{L.deliveryTo}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.deliveryTo}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 采购订单信息 -->
          <div class="customer">
            <div class="title">
              <!-- 采购订单信息 -->
              {{L.purchaseOrderInfo}}
            </div>
            <div class="content">
              <div class="line">
                <div class="left">
                  <!-- 交货条款 -->
                  <span class="left-text1">{{L.incoterms}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.incoterms}}</span>
                </div>
              </div>
              <div class="line">
                <div class="left">
                  <!-- 采购订单编号 -->
                  <span class="left-text1">{{L.PONumber}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.poReference}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-right order-detail-right">
          <!-- 订单汇总 -->
          <div class="order-summary">{{L.invoiceSummary}}</div>
          <div class="content">
            <div class="detail-info">
              <span class="text-left">{{L.invoiceStatus}}:</span>
              <span class="text-right">{{orderInfo.invoiceStatus}}</span>
            </div>
            <div class="detail-info">
              <span class="text-left">{{L.POReference}}:</span>
              <span class="text-right">{{orderInfo.poReference}}</span>
            </div>
            <div class="detail-info">
              <span class="text-left">{{L.invoiceDate}}:</span>
              <span class="text-right">{{formatDateNoTime(orderInfo.invoiceDate)}}</span>
            </div>
          </div>        
          <div class="total-price" v-if="haveSP">
            <div class="price-left">
              <div class="total">{{L.totalAmount}}</div>
            </div>
            <div class="price-right">{{formatMoney(orderInfo.totalAmount,orderInfo.currency)}}</div>
          </div>
          <div class="total-items">
            <div class="item">{{orderInfo.invoiceItems}} {{L.invoiceItems}}</div>
          </div>
          <div 
            class="btn-order-confirm" 
            :class="{'active-order':orderInfo.invoicePdf && haveSP}"
            @click="handlePdf"
          >{{L.invoicePDF}}</div>
          <!-- 再次订购 -->
          <div class="re-order" @click="reOrder">{{L.reOrder}}</div>
        </div>
      </div>
      <!-- 产品概述 -->
      <div class="product-overview">
        <!-- 大标题 -->
        <div class="title">
          <!-- 订单详情 -->
          <span class="text">{{L.productOverview}}</span>
          <!-- 个产品项 -->
          <span class="quantity">{{orderInfo.invoiceItems}} {{L.orderltems}}</span>
        </div>
        <!-- 一大行 -->
        <div class="line" v-for="item in orderList" :key="item.invoiceLineId">
          <!-- 内容标题 -->
          <div class="line-title">
            <div class="title-left"></div>
            <div class="title-right">
              <!-- 预计到货日期 -->
              <div class="date">{{L.deliveryDateEstimate}}:</div>
              <!-- 数量 -->
              <div class="quantity">{{L.quantity}}:</div>
              <div class="price"></div>
            </div>      
          </div>
          <!-- 主内容 -->
          <div class="main-content">
            <!-- 左边的 -->
            <div class="left">
              <div class="info">
                <div class="text">
                  <div class="text1">
                    <template v-if="item.eanOrIc">
                      <span v-if="item.canJump!=1" class="span1" href="javascript:;" @click="handleSkuName">{{item.productName}}</span>
                      <a v-if="item.canJump==1" class="span1" :href="detailUrl(1,item.eanCode)">{{item.productName}}</a>
                    </template>
                  </div>
                  <div class="text2">{{item.eanOrIc}} {{item.eanic}}</div>
                </div>
              </div>
            </div>
            <!-- 右边的一行 -->
            <div class="right">
              <div class="right-line">
                <!-- 基本内容 -->
                <div class="basic-info">
                  <div class="date">
                    <span class="iconfont icon-date">&#xe807;</span>
                    <span class="text-date">{{formatDate(item.deliveryDate)}}</span>
                  </div>
                  <div class="quantity">
                    {{item.quantity}}
                  </div>
                  <div class="price">
                    <div class="left-price" v-if="haveSP">
                      <div class="price-num">{{formatMoney(item.amount,orderInfo.currency)}}</div>
                      <div class="carton-box">({{formatMoney(item.sellUnitPrice,orderInfo.currency)}} {{L.per}} {{item.packType}})</div>
                    </div>
                  </div>
                </div>
                <!-- 装箱内容 -->
                <div class="carton-box-remark" v-if="item.qtyMsgList && item.qtyMsgList.length>=1">
                  <div class="line-title">
                    <div class="date"></div>
                    <div class="quantity">
                      <div class="line-one">
                        <span 
                          class="iconfont icon-arrow"
                          :class="{'icon-active':item.showCarton}"
                          @click="clickCartonFold(item)"
                        >&#xe66c;</span>
                        <span class="text">{{item.qtyMsgList[0]}}</span>
                      </div>
                      <transition
                        @enter="enter"
                        @afterEnter="afterEnter"
                        @leave="leave"
                        @afterLeave="afterLeave"
                      >
                      <div v-show="item.showCarton" class="line-more-wrap">
                        <div class="line-more" v-for="item2,i in item.qtyMsgList" :key="i">{{i>=1?item2:null}}</div>
                      </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 下载那一行 -->
          <div class="download-bottom">
            <div class="download">
              <a
                v-if="item.erpOrderId"
                class="text"
                :href="'/#/orderDetail?ebsorderNo='+item.erpOrderId" 
              >{{L.order}} {{item.erpOrderId}}</a>
              <div class="text" v-if="item.deliveryFileId" @click="handleDelivery(item)">
                <span class="iconfont iconfont-pdf">&#xe60a;</span>
                <span>{{L.deliveryDocument}}</span>
              </div>
              <a class="text" v-if="item.deliveryNoteLink" :href="item.deliveryNoteLink" target="_blank">
                <span class="iconfont iconfont-pdf">&#xe621;</span>
                <span>{{L.carrierTrackingLink}}</span>
              </a>
            </div>
            <div class="status">
              <span class="status-text">{{item.invoiceStatusLine}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddCartErr ref="AddCartErr" />
    <AddCartResult ref="AddCartResult" />
  </div>
</template>

<script>
import {postAction,getAction,downFileGet} from '@/api/tool'
import {api_invoice_detail,api_quick_order,api_order_confirm_download} from '@/api/api'
import Vue from 'vue'
import AddCartErr from '@/components/AddCartErr'
import AddCartResult from '@/components/AddCartResult'

export default {
  name:'InvoiceDetail',
  components:{
    AddCartErr,
    AddCartResult
  },
  data(){
    return {
      orderInfo:{},
      orderList:[]
    }
  },
  computed:{
    haveSP(){
      return this.$store.state.userInfo.accountRules && this.$store.state.userInfo.accountRules.includes('SP')
    }
  },
  methods:{
    mao(a){
      console.log(a)
    },
    /* 获取order详情数据 */
    getOrderDetail(){
      let params={
        invoiceHeaderId:this.$route.query.invoiceHeaderId
      }
      this.allLoading=true
      getAction(api_invoice_detail,params).then(res=>{
        this.allLoading=false
        this.orderInfo=res
        this.orderList=res.lineList
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 如果不是EAN的，点击skuName，报错提示 */
    handleSkuName(){
      this.$message.warning(this.L.sorryResult)
    },
    /* 再下一单,这里调快速下单的接口 */
    reOrder(){
      let str = ''
      this.orderList.forEach((val)=>{
        str=str + val.eanic + ' ' + val.quantity + '\n'
      })
      let params={
        quickContent:str
      }
      this.allLoading=true
      postAction(api_quick_order,params).then(res=>{
        this.allLoading=false
        this.getCartCount()
        //0说明全部成功
        if(res.errFlag==0){
          this.$refs.AddCartResult.open(res.errFlag)
        //1说明部分成功,2说明失败
        }else{
          this.$refs.AddCartResult.open(res.errFlag,res.errList)
        }
      }).catch(res=>{
        this.allLoading=false
        this.$message.error(res.msg)
      })
    },
    /* 下载pdf */
    handlePdf(){
      if(!(this.orderInfo.invoicePdf && this.haveSP)){
        return
      }
      let params = {
        filezIds:this.orderInfo.invoicePdf
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res,1)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 点击展开与折叠装箱信息 */
    clickCartonFold(item){
      this.orderList.forEach((val,i)=>{
        if(item.invoiceLineId===val.invoiceLineId){
          val.showCarton=!val.showCarton
          this.$set(this.orderList,i,val)
        }
      })
    },
    handleDelivery(item){
      let params = {
        filezIds:item.deliveryFileId
      }
      if(!params.filezIds){
        return
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res,1)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    }
  },
  mounted(){
    this.getOrderDetail()
  }
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>

