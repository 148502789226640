<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap my-orders">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text2"
          href="/#/invoiceList" 
        >{{L.myInvoices}}</a>
      </div>
      <div class="my-orders-title">{{L.myInvoices}}</div>
      <!-- 搜索 -->
      <div class="search-wrap">
        <el-form :model="search" ref="formSearch" :rules="rules" :show-message="false">
          <div class="square">
            <span class="text">{{L.invoiceDateFrom}}:</span>
            <el-form-item prop="dateFrom">
              <el-date-picker
                class="search-field"
                v-model="search.dateFrom"
                value-format="yyyy-MM-dd"
                format="dd-MM-yyyy"
                :picker-options="pickerOptions"
                type="date">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="square">
            <span class="text">{{L.invoiceDateTo}}:</span>
            <el-form-item prop="dateTo">
              <el-date-picker
                class="search-field"
                v-model="search.dateTo"
                value-format="yyyy-MM-dd"
                format="dd-MM-yyyy"
                :picker-options="pickerOptions"
                type="date">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="square">
            <span class="text">{{L.invoiceNo}}:</span>
            <el-form-item prop="invoiceNo">
              <el-input @keyup.enter.native="getList(2)" v-model="search.invoiceNo" class="search-field"></el-input>
            </el-form-item>
          </div>
          <div class="square">
            <span class="text">{{L.ERPOrderID}}:</span>
            <el-form-item prop="erpOrderId">
              <el-input @keyup.enter.native="getList(2)" v-model="search.erpOrderId" class="search-field"></el-input>
            </el-form-item>
          </div>
          <div class="square">
            <span class="text">{{L.POReference}}:</span>
            <el-form-item prop="poReference">
              <el-input @keyup.enter.native="getList(2)" v-model="search.poReference" class="search-field"></el-input>
            </el-form-item>
          </div>
          <div class="square">
            <span class="text">{{L.materialNo}}:</span>
            <el-form-item prop="product">
              <el-input @keyup.enter.native="getList(2)" v-model="search.product" class="search-field"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 按钮 -->
      <div class="btn-wrap">
        <div class="left">
          <div class="fill" @click="downloadList">{{L.invoiceOverview}}</div>
        </div>
        <div class="right">
          <!-- 清除过滤 -->
          <div class="null" @click="clearFilters">{{L.cleaFilters}}</div>
          <!-- 搜索 -->
          <div class="fill" @click="getList(2)">{{L.search}}</div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="table-wrap">
        <el-table
          @sort-change="sortChange"
          :default-sort="{prop:'invoiceNo',order:'descending'}"
          :data="orderList"
          style="width: 100%"
        >
          <el-table-column
            prop="invoiceNo"
            sortable="custom"
            :label="tableLable.invoiceNo">
              <template slot-scope="scope">
                <a 
                  :href="'/#/invoiceDetail?invoiceHeaderId='+scope.row.invoiceHeaderId+'&invoiceNo='+scope.row.invoiceNo" 
                  class="order-id"
                >{{scope.row.invoiceNo}}</a>
              </template>
          </el-table-column>
          <el-table-column
            prop="erpOrderId"
            sortable="custom"
            :label="tableLable.ERPOrderID">
          </el-table-column>
          <el-table-column
            prop="poReference"
            sortable="custom"
            :label="L.POReference"
          ></el-table-column>
          <el-table-column
            prop="invoiceDate"
            sortable="custom"
            :label="L.invoiceDate">
            <template slot-scope="scope">
              <span>
                {{ formatDate(scope.row.invoiceDate.split(' ')[0]) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="haveSP"
            prop="totalAmount"
            sortable="custom"
            :label="L.totalAmount"
          >
            <template slot-scope="scope">
              <span>{{ formatMoney(scope.row.totalAmount,scope.row.currency) }}</span>
            </template>
          </el-table-column>
          <!-- 表格没数据的时候展示空组件，要注意这里的slot=empty不能随意更改 -->
          <div slot="empty">
            <NoContent/>
          </div>
        </el-table>
        <Pagination :pageObj="paginationObj" @sizeChange="sizeChange" @currentChange="currentChange"/>
      </div>
    </div>
    
    
  </div>
</template>

<script>
import {postAction,downFilePost} from '@/api/tool'
import {api_invoice_list,api_invoice_download_list} from '@/api/api'
import NoContent from '@/components/NoContent'
import Pagination from '@/components/Pagination'
import moment from 'moment'

export default {
  name:'InvoiceList',
  components:{
    NoContent,
    Pagination
  },
  data(){
    return {
      search:{
        dateFrom:'',
        dateTo:'',
        invoiceNo:'',
        erpOrderId:'',
        poReference:'',
        product:''
      },
      rules:{
        // dateFrom:[{required:true,trigger:'blur'}],
        // dateTo:[{required:true,trigger:'blur'}]
      },
      orderList:[], //列表数据
      pickerOptions:{
        disabledDate: e => {
          let chooseDate = moment(new Date(e)).format('YYYY-MM-DD') //当选择的日期
          let chooseTime = new Date(chooseDate).valueOf() //选择的时间戳
          let date = moment(new Date()).format('YYYY-MM-DD') //当前日期
          let dateTime = new Date(date).valueOf() //当前日期时间戳
          //小于等于今天的时间，可以选择。反之不能选择
          if(chooseTime<=dateTime){
            return false
          }else{
            return true
          }
        }
      },//日期
      tableLable:{
        invoiceNo:'',
        ERPOrderID:'',
        POReference:'',
        invoiceDate:'',
        totalAmount:''
      } //表格的表头，因为这个地方切换语言的时候没办法正常渲染，所以需要监听语言包的变化，进行重新渲染
    }
  },
  computed:{
    haveSP(){
      return this.$store.state.userInfo.accountRules && this.$store.state.userInfo.accountRules.includes('SP')
    }
  },
  watch:{
    L(newVal){
      this.tableLable=newVal
    }
  },
  methods:{
    /* 获取列表，flag==2说明是要从第一页开始搜索的，否则会存在找不到数据的情况 */
    getList(flag=1){
      this.$refs.formSearch.validate(valid=>{
        if(!valid){
          this.$message.warning('Please complete the required fields.');
        }else{
          this.allLoading=true
          this.search.page=flag==2?1:this.paginationObj.currentPage
          this.search.number=this.paginationObj.pageSize
          this.search.sortInfo=this.sortInfo
          postAction(api_invoice_list,this.search).then((res)=>{
            this.paginationObj.total=Number(res.total)
            this.allLoading=false
            this.orderList=res.data
          }).catch(()=>{
            this.allLoading=false
          })
        }
      })
    },
    /* 下载列表 */
    downloadList(){
      this.allLoading=true
      downFilePost(api_invoice_download_list,this.search).then(res=>{
        this.allLoading=false
        this.downloadFile(res)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 重置搜索条件并且直接请求数据 */
    clearFilters(){
      this.search={
        dateFrom:'',
        dateTo:'',
        invoiceNo:'',
        erpOrderId:'',
        poReference:'',
        product:''
      }
      this.setDefaultDate()
      this.getList(2)
    },
    /* 设置默认日期,进到这个路由的时候要设置一下默认日期 */
    setDefaultDate(){
      let date = new Date()
      this.search.dateTo = moment(date).format('YYYY-MM-DD')
      this.search.dateFrom = moment(date).subtract(3,'months').format('YYYY-MM-DD') //回退3个月
    },
    /* 表格排序 */
    sortChange(obj){
      if(obj.order=="ascending"){
        this.sortInfo.order='ASC'
      }
      if(obj.order=="descending"){
        this.sortInfo.order='DESC'
      }
      if(obj.prop=="invoiceNo"){
        this.sortInfo.type=3
      }
      if(obj.prop=="erpOrderId"){
        this.sortInfo.type=4
      }
      if(obj.prop=="poReference"){
        this.sortInfo.type=5
      }
      if(obj.prop=="invoiceDate"){
        this.sortInfo.type=1
      }
      if(obj.prop=="totalAmount"){
        this.sortInfo.type=2
      }
      this.getList(2)
    }
  },
  mounted(){
    this.tableLable=this.L
    this.setDefaultDate() //注意这个方法要在getList之前，因为在最开始加载请求的时候，需要先把日期设置进去并且传给后端再去请求数据
    //最开始进来的时候就按照发票号降序排列的规则传给后端，默认进来就这样
    this.sortInfo.order='DESC'
    this.sortInfo.type=3
    this.getList(2)
  }
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>

