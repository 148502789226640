<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap my-account">
      <!-- 我的账户 -->
      <div class="my-account-title">{{L.myAccount}}</div>
      <!-- Account Information -->
      <div class="account-info">
        <!-- 账户信息 -->
        <div class="title">{{L.accountInformation}}</div>
        <div class="content">
          <div class="line">
            <!-- 客户公司名称 -->
            <div class="line-title">{{L.customerName}}:</div>
            <div class="line-content">
              <span>{{info.customerName}}</span>
            </div>
          </div>
          <div class="line">
            <!-- 客户地址 -->
            <div class="line-title">{{L.customerAddress}}:</div>
            <div class="line-content">
              <span>{{info.customerCountry}}</span>
              <!-- <span class="last">{{info.customerCity}}</span> -->
            </div>
          </div>
          <div class="line">
            <!-- 客户账号 -->
            <div class="line-title">{{L.customerID}}:</div>
            <div class="line-content">
              <span>{{info.customerID}}</span>
            </div>
          </div>
          <div class="line">
            <div class="line-title">
              <!-- 交货条款 -->
              <span>{{L.incoterms}}:</span>
              <el-tooltip 
                effect="dark"
                placement="top"
              >
              <div slot="content">
                {{L.diffIncoterms}}
              </div>
              <span class="iconfont icon-info">&#xed50;</span>
              </el-tooltip>
            </div>
            <div class="line-content">
              <span>{{info.incoTerms}}</span>
            </div>
          </div>
          <div class="line">
            <!-- 付款条款 -->
            <div class="line-title">{{L.paymentTerms}}:</div>
            <div class="line-content">
              <span>{{info.paymentTerms}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Contact Information -->
      <div class="account-info">
        <!-- 联系人信息 -->
        <div class="title">{{L.contactInfo}}</div>
        <div class="content">
          <div class="line">
            <!-- 名字 -->
            <div class="line-title">{{L.firstName}}:</div>
            <div class="line-content">
              <span>{{info.firstName}}</span>
            </div>
          </div>
          <div class="line">
            <!-- 姓 -->
            <div class="line-title">{{L.lastName}}:</div>
            <div class="line-content">
              <span>{{info.lastName}}</span>
            </div>
          </div>
          <div class="line">
            <!-- 邮箱地址 -->
            <div class="line-title">{{L.emailAddress}}:</div>
            <div class="line-content">
              <span>{{info.emailAddress}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Trading Partner Information -->
      <div class="account-info" v-show="info.partnerAddress || info.partnerPhone || info.partnerFax || info.partnerNif || info.partnerNif">
        <!-- 业务合作方信息 -->
        <div class="title">{{L.tradingPartner}}</div>
        <div class="trading-content">
          <div class="null-line"></div>
          <p class="address">{{info.partnerAddress}}</p>
          <div class="null-line"></div>
          <!-- 电话 -->
          <p v-show="info.partnerPhone">{{L.phone}}: {{info.partnerPhone}}</p>
          <!-- 传真 -->
          <p v-show="info.partnerFax">{{L.fax}}: {{info.partnerFax}}</p>
          <div class="null-line"></div>
          <p v-show="info.partnerNif">NIF: {{info.partnerNif}}</p>
          <div class="null-line"></div>
          <p v-show="info.partnerBrand">{{info.partnerBrand}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {postAction} from '@/api/tool'
import {api_cus_info} from '@/api/api'

export default {
  name:'MyAccount',
  data(){
    return {
      info:{}
    }
  },
  methods:{
    /* 获取用户信息 */
    getCusInfo(){
      this.allLoading=true
      postAction(api_cus_info).then(res=>{
        this.info=res
        this.allLoading=false
      }).catch(()=>{
        this.allLoading=false
      })
    }
  },
  mounted(){
    this.getCusInfo()
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>