<template>
  <div class="wrap" v-loading="allLoading">
    <!-- 语言设置 -->
    <div class="language" @mouseenter="mouseenter(0)" @mouseleave="mouseleave(0)">
      <span class="iconfont iconfont0">&#xe618;</span>
      <span class="text">{{$store.state.nowLanguage}}</span>
      <span class="iconfont down" :class="{'active-down':!show.showArrow}">&#xeb8b;</span>
      <!-- <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      > -->
      <transition
        @enter="enter"
        @afterEnter="afterEnter"
        @leave="leave"
        @afterLeave="afterLeave"
      >
        <div class="popover-wrap" v-show="!show.showArrow">
          <div 
            v-for="item in $store.state.languageList" 
            :key="item.languageId"
            @click="changeLanguage(item)"
          >
            {{item.languageName}}
          </div>
        </div>
      </transition>
    </div>
    <div class="main-wrap login-wrap">
      <!-- 登录框框 -->
      <div class="center login-width">
        <div class="img-wrap">
          <img src="../../assets/logo_en.png">
        </div>
        <!-- 请提供以下细节 -->
        <div class="title">{{L.pleaseProvide}}</div>
        <div class="email">
          <!-- 邮箱地址 -->
          <div class="text">{{L.emailAddress}}</div>
          <!-- 此信息是必需的 -->
          <div v-show="show.showEmail" class="warn">{{L.infoNeed}}</div>
          <div class="inp-wrap">
            <span class="iconfont icon-account">&#xe717;</span>
            <input type="text" v-model="email" :class="{'warn-border':show.showEmail}" @input="handleInput(1,$event)" @keyup.enter="enterEmail"/>
          </div>
        </div>
        <div class="email password">
          <!-- 密码 -->
          <div class="text">{{L.password}}</div>
          <div v-show="show.showPassword" class="warn">{{L.infoNeed}}</div>
          <div class="inp-wrap">
            <span class="iconfont icon-account icon-password">&#xe66d;</span>
            <input ref="password" type="password" v-model="password" :class="{'warn-border':show.showPassword}" @input="handleInput(2,$event)" @keyup.enter="handleLogin"/>
          </div>
        </div>
        <div class="btn">
          <!-- 登录 -->
          <button @click="handleLogin">{{L.login}}</button>
        </div>
        <div class="info">
          <div>
            <!-- 忘记密码 -->
            <span>{{L.forgetPassword}}</span>?
            <!-- 重置密码 -->
            <span class="underline" @click="goRegister">{{L.resetPassword}}</span>
          </div>
          <div>
            <!-- 访客模式进入 -->
            <span class="underline" @click="enterGuest">{{L.enterGuest}}</span>
          </div>
        </div>
      </div>
      <!-- 条款 -->
      <div class="terms login-width">
        <!-- 使用条款 -->
        <a class="term" :href="goTermEnterOrNoEnter('20001',1)" target="_blank">{{L.useTerm}}</a>
        <!-- 隐私政策 -->
        <a class="term" :href="goTermEnterOrNoEnter('20003',1)" target="_blank">{{L.privacyPolicy}}</a>
        <!-- Cookie政策 -->
        <a class="term" :href="goTermEnterOrNoEnter('20004',1)" target="_blank">{{L.cookiePolicy}}</a>
        <!-- 与我联系 -->
        <a class="term" :href="officialWebsite" target="_blank">{{L.contact}}</a>
        <!-- 英飞特 -->
        <span class="company">Ⓒ {{L.inventronics}}</span>
      </div>
    </div>
    <!-- 对话框-选择国家 -->
    <SelectCountry ref="SelectCountry" @afterChooseCountry="afterChooseCountry"/>
  </div>
</template>

<script>
import 'animate.css';
import {api_login,api_beside_img,api_home_banner} from '@/api/api'
import {postAction,getAction} from '@/api/tool'
import SelectCountry from '@/components/SelectCountry'

export default {
  name: 'Login',
  components:{SelectCountry},
  data(){
    return {
      email:'',
      password:'',
      show:{
        showEmail:false,
        showPassword:false,
        showArrow:true
      }
    }
  },
  methods:{
    /* 鼠标进入，变成false，展示 */
    mouseenter(flag){
      if(flag==0){
        this.show.showArrow=false
      }
    },
    /* 鼠标移开 */
    mouseleave(flag){
      if(flag==0){
        this.show.showArrow=true
      }
    },
    handleInput(flag,e){
      if(flag==1){
        if(e.target.value){
          this.show.showEmail=false
        }else{
          this.show.showEmail=true
        }
      }
      if(flag==2){
        if(e.target.value){
          this.show.showPassword=false
        }else{
          this.show.showPassword=true
        }
      }
    },
    /* 登录进入 */
    handleLogin(){
      if(!this.email){
        this.show.showEmail=true
      }
      if(!this.password){
        this.show.showPassword=true
      }
      if(!this.email || !this.password){
        //请填写所有必需的字段，然后重试。
        this.$message({
          message: this.L.fillOutTry,
          type: 'error'
        });
        return
      }
      this.allLoading=true
      let params = {
        account:this.email,
        password:this.encrypt(this.password)
      }
      postAction(api_login,params).then(res=>{
        this.allLoading=false
        this.$cookies.set('token',res.token,'1d')
        this.$cookies.set('user_id',res.customer.accountId,'1d')
        this.$store.dispatch('setUserInfo',res.customer)
        this.$store.dispatch('setCurrency',res.currency)
        this.$store.dispatch('setArea',res.area)
        this.$store.dispatch('setOU',res.ou)
        if(res.customer.policyAccept==1){
          //去主页之前先看下，是不是这个带了routeName的参数，带了的话去到对应的页面，没带直接去主页。注意：要跟其他几个涉及到的地方一起改
          if(this.$route.query.routeName){
            let newQuery = JSON.parse(JSON.stringify(this.$route.query))
            delete newQuery.routeName
            this.$router.push({
              path:'/'+this.$route.query.routeName,
              query:newQuery
            })
          }else{
            this.goCatalogue()
          }
          //登录成功以后拿一下侧边栏的数据
          getAction(api_beside_img).then(res=>{
            this.$store.dispatch('setBesideImgs',res.homeRight)
          })
          //登录成功以后拿一下banner的数据
          getAction(api_home_banner).then(res=>{
            this.$store.dispatch('setBannerInfo',res)
          })
        }else{
          //去主页之前先看下，是不是这个带了routeName的参数，带了的话去到对应的页面，没带直接去主页。注意：要跟其他几个涉及到的地方一起改
          if(this.$route.query.routeName){
            let newQuery = JSON.parse(JSON.stringify(this.$route.query))
            this.$router.push({
              path:'/acceptTerms',
              query:newQuery
            })
          }else{
            this.$router.push({
              path:'/acceptTerms'
            })
          }
        }
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 访客进入 */
    enterGuest(){
      //没有选择过国家的话，就先选择国家再进入
      if(!this.$cookies.get('select_country')){
        this.$refs.SelectCountry.openSelectCountry()
      }else{
        //存在国家的话，就直接去主页
        //去主页之前先看下，是不是这个带了routeName的参数，带了的话去到对应的页面，没带直接去主页。注意：要跟其他几个涉及到的地方一起改
        if(this.$route.query.routeName){
          let newQuery = JSON.parse(JSON.stringify(this.$route.query))
          delete newQuery.routeName
          this.$router.push({
            path:'/'+this.$route.query.routeName,
            query:newQuery
          })
        }else{
          this.goCatalogue()
        }
      }
    },
    /* 选择国家-确认 */
    afterChooseCountry(){
      //去主页之前先看下，是不是这个带了routeName的参数，带了的话去到对应的页面，没带直接去主页。注意：要跟其他几个涉及到的地方一起改
      if(this.$route.query.routeName){
        let newQuery = JSON.parse(JSON.stringify(this.$route.query))
        delete newQuery.routeName
        this.$router.push({
          path:'/'+this.$route.query.routeName,
          query:newQuery
        })
      }else{
        this.goCatalogue()
      }
    },
    /* email回车 */
    enterEmail(){
      this.$refs.password.focus()
    }
  },
  mounted(){
    this.$cookies.remove('token')
    this.$cookies.remove('user_id')
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>