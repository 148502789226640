<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap wishlist-detail">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <!-- 我的订单 -->
        <a
          class="text1"
          href="/#/wishlist" 
        >{{L.myWishlists}}</a>
        <span class="line">/</span>
        <a
          class="text2"
          :href="'/#/wishlistDetail?wishlistId='+$route.query.wishlistId" 
        >{{headObj.wishlistName}}</a>
      </div>
      <!-- 标题 -->
      <div class="product-title">
        <div class="title" v-show="!show.showEdit" @click="handleNameInp">
          <span class="text">{{headObj.wishlistName}}</span>
          <span class="iconfont icon_edit">&#xe609;</span>
        </div>
        <div class="title-inp" v-show="show.showEdit">
          <input type="text" v-model="headObj.wishlistName" ref="wishlistName">
          <span class="cancel" @click="handleCancel">{{L.cancel}}</span>
          <span class="save" @click="handleSave">{{L.save}}</span>
        </div>

      </div>
      <!-- 总价信息 -->
      <div class="total-wrap">
        <div class="one-line">{{L.wishlistSummary}}</div>
        <div class="two-line">
          <div class="total-price">
            <div class="total-price-line">
              <b class="price-text">{{L.totalAmount}}</b>
              <span class="num-line">({{headObj.productNum}} {{L.orderltems}}) :</span>
              <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                <span class="price">{{formatMoney(headObj.totalPrice)}}</span>
              </template>
            </div>
          </div>
          <div class="btn-wrap">
            <div class="not-allow" :class="{'btn':productList.length>0}" @click="addWishlistToCart">
              <span class="iconfont icon icon1">&#xe62c;</span>
              <span class="text">{{L.addWishlistCart}}</span>
            </div>
          </div>
          <div class="btn-wrap">
            <div class="not-allow" :class="{'btn':productList.length>0}" @click="downloadWishlist">
              <span class="iconfont icon">&#xe602;</span>
              <span class="text">{{L.downloadWishlist}}</span>
            </div>
          </div>
          <div class="btn-wrap">
            <!-- 隐藏quote -->
            <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SRQ')">
              <div class="not-allow" :class="{'btn':productList.length>0}" @click="handleRequestQuote">
                <span class="iconfont icon">&#xe64a;</span>
                <span class="text">{{L.requestQuo}}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- 产品概述 -->
      <div class="product-overview">
        <!-- 大标题 -->
        <div class="title">
          <div class="title-left">
            <el-checkbox v-model="headObj.isShare" @change="changeShare"><span class="text">{{L.shareWishlist}}</span></el-checkbox>
          </div>
          <div class="title-right">
            <span>{{L.createdBy}} {{$store.state.userInfo.username}}</span>
          </div>
        </div>
        <!-- 内容区-一块 -->
        <div class="line" v-for="item in productList" :key="item.eanPimid">
          <!-- 主内容 -->
          <div class="main-content">
            <!-- 一行信息 -->
            <div class="line-content">
              <div class="info">
                <div class="image">
                  <img :src="item.loopImageList && item.loopImageList[0]">
                </div>
                <div class="text">
                  <div class="text1" v-html="item.eanName"></div>
                  <div class="text2">EAN: {{item.eanPimid}}</div>
                </div>
              </div>
              <div class="quantity">
                <div class="add-number-wrap">
                  <AddNumber
                    paddingTopBottom="9px" 
                    v-model="item.skuCount" 
                    :step="item.countAscii" 
                    @blur="e=>blurCount(e,item)" 
                    @click="e=>blurCount(e,item)"
                    @focus="e=>focusCount(e,item)"
                  />
                </div>
              </div>
              <div class="price-num">
                <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                  <div class="line-total-price">{{formatMoney(item.skuAmount)}}</div>
                  <div class="unit-price">({{formatMoney(item.price)}} {{L.per}} {{item.packType}})</div>
                </template>
              </div>
              <div class="body-btn-wrap">
                <div class="btn" @click="addToCartSingle(item)">
                  <span class="iconfont icon_cart">&#xe62c;</span>
                  <span class="text">{{L.addCart}}</span>
                </div>
                <div class="delete">
                  <span class="iconfont icon_delete" @click="deleteAproduct(item)">&#xec7b;</span>
                </div>
              </div>
            </div>
            <!-- 装箱内容 -->
            <div class="carton-box-remark" v-show="item.qtyMsgList.length>=1">
              <div class="info"></div>
              <div class="quantity">
                <div class="line-one">
                  <span 
                    class="iconfont icon-arrow"
                    :class="{'icon-active':item.showCarton}"
                    @click="clickCartonFold(item)"
                  >&#xe66c;</span>
                  <span class="text">{{item.qtyMsgList[0]}}</span>
                </div>
                <transition
                  @enter="enter"
                  @afterEnter="afterEnter"
                  @leave="leave"
                  @afterLeave="afterLeave"
                >
                <div v-show="item.showCarton" class="line-more-wrap">
                  <div class="line-more" v-for="item2,i in item.qtyMsgList" :key="i">{{i>=1?item2:null}}</div>
                </div>
                </transition>
              </div>
              <div class="body-btn-wrap"></div>
            </div>
          </div>
        </div>
        <!-- 删除所有 -->
        <div class="delete-all" v-if="productList.length>0">
          <div class="delete-wrap" @click="deleteAll">
            <span class="text">{{L.clearWishlist}}</span>
            <span class="iconfont icon-clear">&#xec7b;</span>
          </div>
        </div>
      </div>
    </div>

    <AddCartErr ref="AddCartErr" />
    <AddCartResult ref="AddCartResult" />
    <RequestQuotation ref="RequestQuotation" @requestQuatation="requestQuatation" />
    <QuoteSuccess ref="QuoteSuccess" />

  </div>
</template>

<script>
import AddNumber from '@/components/AddNumber'
import AddCartErr from '@/components/AddCartErr'
import AddCartResult from '@/components/AddCartResult'
import RequestQuotation from '@/components/Quote/RequestQuotation'
import QuoteSuccess from '@/components/Quote/QuoteSuccess'

import {
  api_wishlist_detail,
  api_wishlist_edit,
  api_quick_order,
  api_wishlist_edit_product_num,
  api_wishlist_download_list,
  api_wishlist_change_share,
  api_wishlist_delete_a_product,
  api_quotation_wishlist,
  api_before_quote_judge_wishlist
} from '@/api/api'
import {getAction,postAction,downFilePost} from '@/api/tool'

export default {
  name:'WishlistDetail',
  components:{
    AddNumber,
    AddCartErr,
    AddCartResult,
    RequestQuotation,
    QuoteSuccess
  },
  data(){
    return {
      show:{
        showEdit:false
      },
      headObj:{}, //单头信息
      oldWishlistName:'', //在聚焦之前记录下，如果取消保存的话，name要变回去的
      productList:[]
    }
  },
  methods:{
    /* 获取详情信息 */
    getDetail(){
      let params = {
        wishlistId:this.$route.query.wishlistId
      }
      this.allLoading=true
      getAction(api_wishlist_detail,params).then(res=>{
        this.allLoading=false
        this.headObj=res
        this.headObj.isShare=this.headObj.isShare?true:false
        this.productList=res.wishlistDetailList
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 点击wishlist name的input框，自动聚焦 */
    handleNameInp(){
      this.show.showEdit=true
      this.oldWishlistName=this.headObj.wishlistName
      this.$nextTick(()=>{
        this.$refs.wishlistName.focus()
      })
    },
    /* 编辑wishlist name，取消按钮 */
    handleCancel(){
      this.show.showEdit=false
      this.headObj.wishlistName=this.oldWishlistName
    },
    /* 编辑wishlist name，保存按钮 */
    handleSave(){
      this.show.showEdit=false
      this.allLoading=true
      let params = {
        wishlistId: this.$route.query.wishlistId,
        wishlistName: this.headObj.wishlistName
      }
      postAction(api_wishlist_edit,params).then(()=>{
        this.allLoading=false
        this.$message.success(this.L.modifySuccess)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 数量聚焦 */
    focusCount(e,item){
      item.oldVal=e
    },
    /* 修改数量 */
    blurCount(e,item){
      let num = e
      let countAscii = item.countAscii //进制
      let moq = item.moq //最小值

      num = num%countAscii==0?num:(Math.floor(num/countAscii+1))*countAscii
      num = num>=moq?num:moq

      //1.新的数据和老的数据一样的话，就不要请求了。并且这里还要注意把div的数据放回去，否则会出错。
      //2.举例，进制是2，从4改成3，oldVal是4，num也被进制弄成了4，但是input框的数据还是3
      //3.要解决第二步，就要重新给skuCount赋个值，相当于skuCount已经被改掉了，但是这边如果相等又被return掉了，所以要重新赋个值即可
      this.productList.forEach((val,i)=>{
        if(val.eanPimid==item.eanPimid){
          val.skuCount=num
          this.$set(this.productList,i,val) //触发渲染
        }
      })
      //如果值是一样的，就不用请求了。要注意，先赋值，再return
      if(item.oldVal==num){
        return
      }

      let params = {
        wishlistId:this.$route.query.wishlistId,
        productId:item.eanPimid,
        skuCount:num
      }
      this.allLoading=true
      postAction(api_wishlist_edit_product_num,params).then(res=>{
        this.allLoading=false
        let resultNum = res.skuCount
        this.headObj.totalPrice=res.totalPrice
        //把后端的值赋进去
        this.productList.forEach((val,i)=>{
          if(val.eanPimid==item.eanPimid){
            val.skuCount=resultNum
            val.skuAmount=res.skuAmount
            val.price=res.price
            this.$set(this.productList,i,val) //触发渲染
          }
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 添加所有的产品到购物车 */
    addWishlistToCart(){
      if(this.productList.length<=0){
        return
      }
      let str = ''
      this.productList.forEach((val)=>{
        str=str + val.eanPimid + ' ' + val.skuCount + '\n'
      })
      let params={
        quickContent:str
      }
      this.allLoading=true
      postAction(api_quick_order,params).then(res=>{
        this.allLoading=false
        this.getCartCount()
        //0说明全部成功
        if(res.errFlag==0){
          this.$refs.AddCartResult.open(res.errFlag)
        //1说明部分成功,2说明失败
        }else{
          this.$refs.AddCartResult.open(res.errFlag,res.errList)
        }
      }).catch(res=>{
        this.allLoading=false
        this.$message.error(res.msg)
      })
    },
    /* 下载 */
    downloadWishlist(){
      if(this.productList.length<=0){
        return
      }
      this.allLoading=true
      let params = {
        wishlistId:this.$route.query.wishlistId
      }
      downFilePost(api_wishlist_download_list,params).then(res=>{
        this.allLoading=false
        this.downloadFile(res)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 修改share */
    changeShare(){
      let params = {
        wishlistId:this.$route.query.wishlistId,
        isShare:this.headObj.isShare?1:0
      }
      this.allLoading=true
      postAction(api_wishlist_change_share,params).then(()=>{
        this.allLoading=false
        this.$message.success(this.L.modifySuccess)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 删除单条数据 */
    deleteAproduct(item){
      let params = {
        wishlistId:this.$route.query.wishlistId,
        productId:item.eanPimid
      }
      this.allLoading=true
      postAction(api_wishlist_delete_a_product,params).then(()=>{
        this.allLoading=false
        this.$message.success(this.L.operateSuccess)
        this.getDetail()
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 加入购物车-单条数据 */
    addToCartSingle(item){
      let str = item.eanPimid + ' ' + item.skuCount
      let params={
        quickContent:str
      }
      this.allLoading=true
      postAction(api_quick_order,params).then(res=>{
        this.allLoading=false
        this.getCartCount()
        //0说明全部成功
        if(res.errFlag==0){
          this.$refs.AddCartResult.open(res.errFlag)
        //1说明部分成功,2说明失败
        }else{
          this.$refs.AddCartResult.open(res.errFlag,res.errList)
        }
      }).catch(res=>{
        this.allLoading=false
        this.$message.error(res.msg)
      })
    },
    /* 展示quotation弹窗 */
    handleRequestQuote(){
      if(this.productList.length<=0){
        return
      }
      let params = {
        wishlistId:this.$route.query.wishlistId
      }
      this.allLoading=true
      postAction(api_before_quote_judge_wishlist,params).then(res=>{
        this.allLoading=false
        if(res.code==200){
          this.$refs.RequestQuotation.open(2)
        }else{
          this.$refs.AddCartErr.open(res.message)
        }
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* quotation弹窗提交按钮触发父组件方法 */
    requestQuatation(params){
      this.$refs.RequestQuotation.loading=true
      params.wishlistId=this.$route.query.wishlistId
      postAction(api_quotation_wishlist,params).then(res=>{
        this.$refs.RequestQuotation.loading=false
        //大于0说明是失败的，等于0说明是成功的
        if(res.length>0){
          //失败
          this.$refs.AddCartErr.open(res)
        }else{
          //成功
          this.$refs.QuoteSuccess.open()
          this.$refs.RequestQuotation.close()
        }
      }).catch(res=>{
        this.$refs.RequestQuotation.loading=false
        this.$message.error(res.msg)
      })
    },
    /* 删除所有 */
    deleteAll(){
      this.$confirm(this.L.suerClearWishlist, {
        confirmButtonText: this.promptConfirmBtn,
        cancelButtonText: this.propmtCancelBtn,
        closeOnClickModal:this.promptCloseFlag,
        type: 'warning',
        customClass:'message-box',
        lockScroll:false,
      }).then(() => {
        let params = {
          wishlistId:this.$route.query.wishlistId
        }
        this.allLoading=true
        postAction(api_wishlist_delete_a_product,params).then(()=>{
          this.allLoading=false
          this.$message.success(this.L.operateSuccess)
          this.getDetail()
        }).catch(()=>{
          this.allLoading=false
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    clickCartonFold(item){
      this.productList.forEach((val,i)=>{
        if(item.eanPimid===val.eanPimid){
          val.showCarton=!val.showCarton
          this.$set(this.productList,i,val)
        }
      })
    }
  },
  mounted(){
    this.getDetail()
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>