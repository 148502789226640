<template>
  <div class="wishlist-select-wrap" v-loading="allLoading">
    <div class="icon-close" @click="closeModal">
      <span class="iconfont icon1">&#xe614;</span>
      <span class="iconfont icon2">&#xe606;</span>
    </div>
    <!-- 将产品添加到现有的收藏夹中 -->
    <div class="s-title">{{L.addToWishlist}}</div>
    <div class="multiple-select" @mouseleave="mouseleave">
      <!-- 订单状态-input框 -->
      <div class="inp-select-wrap" @click="clickChooseStatus">
        <div v-show="inpText" class="left-text">{{inpText}}</div>
        <div v-show="!inpText" class="left-text left-text-no-deta">{{L.pleaseSelectWishlist}}</div>
        <div class="arrow">
          <span class="iconfont icon_arrow" :class="{'active-arrow':show.showChoose}">&#xe627;</span>
        </div>
      </div>
      <!-- 弹框 -->
      <transition
        @enter="enter"
        @afterEnter="afterEnter"
        @leave="leave"
        @afterLeave="afterLeave"
      >
        <div class="choose-big" v-show="show.showChoose">
          <span class="triangle"></span>
          <div class="choose-wrap">
            <template v-if="selectArr.length>0">
              <div class="choose" @click="chooseStatus(item,i)" v-for="item,i in selectArr" :key="item.wishlistId">
                <div class="line1" :class="{'active-color':item.showTick}">{{item.wishlistName}}</div>
                <span class="iconfont icon_tick" v-show="item.showTick">&#xe8e6;</span>
              </div>
            </template>
            <template v-if="selectArr.length==0">
              <div class="no-data">{{L.noData}}</div>
            </template>
          </div>
        </div>
      </transition>    
    </div>
    <!-- 将产品添加到新的收藏夹中 -->
    <div class="s-title s-title2">{{L.addToNewWishlist}}</div>
    <div class="inp-wrap">
      <!-- 收藏夹名称 -->
      <span class="name-of">{{L.nameOfWishlist}} :</span>
      <input v-model="wishlistName" type="text">
    </div>
    <!-- 保存 -->
    <div class="save-btn" @click="handleSave">{{L.save}}</div>
  </div>
</template>

<script>
import {api_add_to_wishlist} from '@/api/api'
import {postAction} from '@/api/tool'

export default {
  name:'WishlistSelect',
  data() {
    return {
      show:{
        showChoose:false
      },
      wishlistName:'', //输入新的wishlist的名称
      inpText:'', //框子里的文字
      selectArr:[], //下拉数据
      nowProductItem:[], //外面的产品列表（一个或多个）
      chooseWishlistArr:[], //所选择的arr
      isCart:false //是不是从购物车加到wishlist的（因为从购物车加的话，还有个数量要传给后端）
    }
  },
  methods:{
    /* 设置外面的那个产品，从父组件过来的 */
    setNowProductItem(arr,selectArr,isCart=false){
      //重置下数据
      this.wishlistName=''
      this.inpText=''
      this.selectArr=JSON.parse(JSON.stringify(selectArr))
      this.nowProductItem=arr
      this.chooseWishlistArr=[]
      this.isCart=isCart
    },
    /* 关闭对话框 */
    closeModal(){
      this.$emit('closeWishlistDialogue',this.nowProductItem)
    },
    /* 鼠标离开，隐藏下拉框选择的窗子 */
    mouseleave(){
      this.show.showChoose=false
    },
    /* 鼠标点击，打开/关闭下拉框选择的窗子 */
    clickChooseStatus(){
      this.show.showChoose=!this.show.showChoose
    },
    /* 选择下拉列表中的其中一项 */
    chooseStatus(item,i){
        item.showTick=!item.showTick
        this.$set(this.selectArr,i,item) //触发渲染
      //无论哪种情况结束后，都需要重新设置inpText和search.orderStatus
      let newOrderStatusText=[] //打钩的文字，排成数组
      let chooseItemArr=[] //打钩的排成数组
      this.selectArr.forEach((val)=>{
        if(val.showTick){
          newOrderStatusText.push(val.wishlistName)
          chooseItemArr.push(val)
        }
      })
      this.chooseWishlistArr=chooseItemArr
      this.inpText=newOrderStatusText.join(',')
    },
    /* 保存 */
    handleSave(){
      let productIdList=[]
      let cartProduct = [] //如果是购物车的数据，要多传一个这个参数给后端
      this.nowProductItem.forEach((val)=>{
        productIdList.push(val.eanPimid)
      })
      let params = {
        productList:productIdList,
        wishList:[]
      }
      if(this.isCart){
        this.nowProductItem.forEach(val=>{
          let obj = {
            product:val.eanPimid,
            cartQty:0
          }
          val.cartLineList.forEach(val2=>{
            obj.cartQty+=Number(val2.skuCount)
          })
          cartProduct.push(obj)
        })
        params.cartProduct=cartProduct
      }
      //既没输入新的名称，又没选择列表，那就提示警告信息
      if(!(this.wishlistName.trim() || this.chooseWishlistArr.length>0)){
        this.$message.warning(this.L.pleaseSelectWhsiist)
        return
      }
      //输入了新的name
      if(this.wishlistName.trim()){
        params.wishList.push({
          isNewWishlist:1,
          wishlistId:'',
          wishlistName:this.wishlistName
        })
      }
      //选择的要加入的收藏夹
      if(this.chooseWishlistArr.length>0){
        this.chooseWishlistArr.forEach((val)=>{
          params.wishList.push({
            isNewWishlist:0,
            wishlistId:val.wishlistId,
            wishlistName:val.wishlistName
          })
        })
      }
      this.allLoading=true
      postAction(api_add_to_wishlist,params).then(()=>{
        this.allLoading=false
        this.closeModal()
        this.$message.success(this.L.successAddWishlist)
      }).finally(()=>{
        this.allLoading=false
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../public/css/public.less');
.wishlist-select-wrap{
  padding:30px 22px 26px 22px;
  box-shadow:0 0 18px 6px rgba(135, 131, 131, 0.5);
  background-color: #fff;
  border-radius: 4px;
  border:1px solid #ccc;
  .icon-close{
      // background-color: red;
      position: absolute;
      right:14px;
      top:14px;
      .icon1{
        font-size: 20px;
        display: block;
        color:#888;
      }
      .icon2{
        font-size: 20px;
        display: none;
        color:@mainColor;
      }
      &:hover{
        cursor: pointer;
        .icon1{
          display: none;
        }
        .icon2{
          display: block;
        }
      }
  }
  .s-title{
    font-size: 13px;
    font-weight: 600;
    // color:@;
  }
  .s-title2{
    margin-top: 20px;
  }
  .multiple-select{
  height:34px;
  width:100%;
  box-sizing: border-box;
  border:1px solid #DCDFE6;
  border-radius: 4px;
  position: relative;
  margin-top: 8px;
  /* 下拉-input框 */
  .inp-select-wrap{
    padding-right: 6px;
    // background-color: pink;
    display: flex;
    align-items: center;
    .left-text{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width:calc(100% - 30px);
      padding-left: 12px;
      color:#666;
      font-size: 13px;
      // background-color: pink;
    }
    .left-text-no-deta{
      color:#ccc;
    }
    .arrow{
      width:30px;
      // background-color: orange;
      text-align: right;
      .icon_arrow{
        line-height: 34px;
        // background-color: pink;
        display: inline-block;
        font-size: 16px;
        color:@mainColor;
        &:extend(.transtion-time);
      }
      .active-arrow{
        transform:rotate(180deg);
        &:extend(.transtion-time);
      }
    }
    &:hover{
      cursor: pointer;
    }
  }
  /* 弹框 */
  .choose-big{
    // background-color: rgba(0,0,0,0.1);
    overflow: hidden;
    &:extend(.transtion-time);
    width:calc(100% + 100px); //外层阴影用，否则被overflow:hidden挡住就看不到阴影部分了
    position: absolute;
    left:-50px;//外层阴影用，否则被overflow:hidden挡住就看不到阴影部分了
    top:100%;
    padding-top:13px;
    padding-bottom: 13px;
    z-index: 9;
    .triangle{
      position: absolute;
      left:85px;
      top:9px;
      z-index: 9;
      width:8px;
      height:8px;
      background-color: #fff;
      transform: rotate(45deg);
      border-left: 1px solid #DCDFE6;
      border-top: 1px solid #DCDFE6;
    }
    .choose-wrap{
      &:extend(.main-box-shadow);
      margin-left: 50px; //外层阴影用，否则被overflow:hidden挡住就看不到阴影部分了
      width:calc(100% - 100px); //外层阴影用，否则被overflow:hidden挡住就看不到阴影部分了
      overflow: auto;
      border:1px solid #DCDFE6;
      max-height:300px;
      background-color: #fff;
      // background-color: pink;
      box-sizing: border-box;
      border-radius: 4px;
      padding:6px 0;
      position: relative;
      .choose{
        padding:6px 12px;
        &:extend(.force-newline);
        display: flex;
        align-items: center;
        .line1{
          // background-color: orange;
          width:calc(100% - 30px);
          color:#666;
          font-size: 13px;
        }
        .active-color{
          color:@mainColor;
        }
        .icon_tick{
          // background-color: pink;
          width:30px;
          text-align: right;
          color:@mainColor;
        }
        &:hover{
          background-color: @lightOrange;
          cursor: pointer;
        }
      }
      .no-data{
        text-align: center;
        color:#ccc;
        font-size: 12px;
        padding:24px 10px;
      }
    }
  }
  
  }
  .inp-wrap{
    margin-top: 6px;
    display: flex;
    align-items: center;
    .name-of{
      margin-right: 20px;
    }
    input{
      flex-grow: 1;
      height:26px;
      padding:2px 8px;
      box-sizing: border-box;
      border-radius: 4px;
      border:1px solid #DCDFE6;
      &:focus{
        border-color:@mainColor;
      }
    }
  }
  .save-btn{
    width:100%;
    padding:7px 12px;
    background-color: @mainColor;
    box-sizing: border-box;
    color:#fff;
    margin-top: 16px;
    border-radius: 4px;
    text-align: center;
    &:extend(.transtion-time);
    border:1px solid @mainColor;
    &:hover{
      background-color: #fff;
      color:@mainColor;
      cursor: pointer;
      &:extend(.transtion-time);
    }
  }
}
</style>