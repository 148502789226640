<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap term-wrap">
      <div class="btn-wrap" v-show="$route.query.id=='20004'">
        <span class="btn" @click="showCookie">{{L.cookieSettingBtn}}</span>
      </div>
      <div class="ql-editor">
        <div v-html="info"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {api_terms} from '@/api/api'
import {postAction} from '@/api/tool'

export default {
  name:'Term',
  components:{
    
  },
  data(){
    return {
      info:''
    }
  },
  watch:{
    $route(){
      this.getInfo()
    }
  },
  methods:{
    getInfo(){
      let params={
        articleCode:this.$route.query.id,
        languageId:this.$cookies.get('languageId')
      }
      this.allLoading=true
      postAction(api_terms,params).then(res=>{
        this.allLoading=false
        this.info=res.languageArticle
      }).catch(()=>{
        this.allLoading=false
      })
    },
    showCookie(){
      this.$emit('showCookie')
    }
  },
  mounted(){
    this.getInfo()
  }
  
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('../../../public/css/quill.snow.css'); 
@import url('./index.less');
</style>