<template>
  <div class="wrap" v-loading="allLoading">
    <!-- 语言设置 -->
    <div class="language" @mouseenter="mouseenter(0)" @mouseleave="mouseleave(0)">
      <span class="iconfont iconfont0">&#xe618;</span>
      <span class="text">{{$store.state.nowLanguage}}</span>
      <span class="iconfont down" :class="{'active-down':!show.showArrow}">&#xeb8b;</span>
      <transition
        @enter="enter"
        @afterEnter="afterEnter"
        @leave="leave"
        @afterLeave="afterLeave"
      >
        <div class="popover-wrap" v-show="!show.showArrow">
          <div 
            v-for="item in $store.state.languageList" 
            :key="item.languageId"
            @click="changeLanguage(item)"
          >
            {{item.languageName}}
          </div>
        </div>
      </transition>
    </div>
    <!-- 注册框框 -->
    <div class="main-wrap center register-width">
      <div class="img-wrap">
        <img src="../../assets/logo_en.png">
      </div>
      <!-- 修改密码 -->
      <div class="title">- {{L.changePassword}} -</div>
      <div class="content">
        <div class="left">
          <div class="email">
            <div class="text">
              <!-- 新密码 -->
              <span>{{L.newPassword}}</span>
            </div>
            <!-- 此信息是必需的。 -->
            <div v-show="show.showPassword" class="warn">{{L.infoNeed}}</div>
            <input type="password" v-model="password" :class="{'warn-border':show.showPassword}" @input="handleInput(2,$event)"/>
          </div>
          <div class="email">
            <div class="text">
              <!-- 确认新密码 -->
              <span>{{L.confirmNewPassword}}</span>
            </div>
            <!-- 此信息是必需的。 -->
            <div v-show="show.showConfirmPassword" class="warn">{{L.infoNeed}}</div>
            <input type="password" v-model="confirmPassword" :class="{'warn-border':show.showConfirmPassword}" @input="handleInput(3,$event)"/>
          </div>
        </div>
        <div class="right">
          <!-- 密码标准 -->
          <div class="criteria-title">{{L.passwordCriteria}}</div>
          <div class="criteria">
            <div class="term">
              <!-- 6-64个字符。 -->
              <span class="text">{{L.long64}}</span>
              <span class="iconfont icon-right" v-show="show.showRule1">&#xe628;</span>
              <span class="iconfont icon-error" v-show="!show.showRule1">&#xe652;</span>
            </div>
            <div class="term">
              <!-- 包含至少这4个字符类型中的3种： -->
              <span class="text">{{L.contains3}}</span>
              <span class="iconfont icon-right" v-show="show.showRule2">&#xe628;</span>
              <span class="iconfont icon-error" v-show="!show.showRule2">&#xe652;</span>
            </div>
            <!-- 大写字母 (A, B, ... Z) -->
            <div class="term-remark">• {{L.uppercase}}</div>
            <!-- 小写字母 (a, b, ... z) -->
            <div class="term-remark">• {{L.lowercase}}</div>
            <!-- 数字 (1, 2, 3, 4, 5, 6, 7, 8, 9, 0) -->
            <div class="term-remark">• {{L.numbers}}</div>
            <!-- 特殊字符 -->
            <div class="term-remark">• {{L.nonalphanumber}}</div>
          </div>
        </div>
      </div>
      <div class="btn">
        <button class="continue" @click="handleSubmit">{{L.continue}}</button>
        <button class="cancel" @click="handleCancel">{{L.cancel}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css';
import {api_change_password,api_is_active} from '@/api/api'
import {postAction} from '@/api/tool'

export default {
  name: 'Login',
  data(){
    return {
      password:'',
      confirmPassword:'',
      show:{
        showPassword:false,
        showConfirmPassword:false,
        showArrow:true,
        showRule1:false,
        showRule2:false
      }
    }
  },
  watch:{
    password(newVal){
      this.passwordCheckSingle(newVal)
    }
  },
  methods:{
    /* 密码规则单行检测：1.长度是否为6-64。2.至少包含【小写、大写、数字、特殊字符】中的其中3种 */
    passwordCheckSingle(str){
      const rC = {
        lw: /[a-z]/, //小写
        uw: /[A-Z]/, //大写
        nw: /[0-9]/, //数字
        sw: /\W|_/ //特殊字符
      }
      function Reg(str,rStr){
        const reg = rStr
        if(reg.test(str)){
          return true;
        } else {
          return false;
        }
      }
      const tR = {
        l: Reg(str, rC.lw),
        u: Reg(str, rC.uw),
        n: Reg(str, rC.nw),
        s: Reg(str, rC.sw)
      }
      if(str && str.length >=6 && str.length <= 64){
        this.show.showRule1=true
      }else{
        this.show.showRule1=false
      }
      if( (tR.l && tR.u && tR.n)||(tR.l && tR.u && tR.s)||(tR.l && tR.n && tR.s)||(tR.u && tR.n && tR.s) ){
        this.show.showRule2=true
      }else{
        this.show.showRule2=false
      }
    },
    /* 鼠标进入，变成false，展示 */
    mouseenter(flag){
      if(flag==0){
        this.show.showArrow=false
      }
    },
    /* 鼠标移开 */
    mouseleave(flag){
      if(flag==0){
        this.show.showArrow=true
      }
    },
    handleInput(flag,e){
      if(flag==2){
        if(e.target.value){
          this.show.showPassword=false
        }else{
          this.show.showPassword=true
        }
      }
      if(flag==3){
        if(e.target.value){
          this.show.showConfirmPassword=false
        }else{
          this.show.showConfirmPassword=true
        }
      }
    },
    handleSubmit(){
      if(!this.password){
        this.show.showPassword=true
      }
      if(!this.confirmPassword){
        this.show.showConfirmPassword=true
      }
      if(!this.password || !this.confirmPassword){
        this.$message({
          message: this.L.fillOutTry,
          type: 'error'
        });
        return
      }
      if(!this.passwordCheck(this.password)){
        //密码的格式不正确。
        this.$message({
          message: this.L.passwordNotFormed,
          type: 'error'
        });
        return
      }
      if(!(this.password===this.confirmPassword)){
        //密码不一致
        this.$message({
          message: this.L.passwordInconsistent,
          type: 'error'
        });
        return
      }
      this.allLoading=true
      let params = {
        code:this.$route.query.code,
        password:this.encrypt(this.password)
      }
      postAction(api_change_password,params).then(()=>{
        this.allLoading=false
        //修改成功
        this.$message({
          message: this.L.modifySuccess,
          type: 'success'
        })
        this.goLogin()
      }).catch(()=>{
        this.allLoading=false
      })
    },
    handleCancel(){
      this.goLogin()
    },
    /* 判断首次激活进来的链接，是不是已经激活过了，如果已经激活过了，就直接去到登录页面 */
    firstActiveHasDone(){
      //如果链接中带type参数，并且type的值是first，说明这个链接是首次激活的链接。首次激活的链接要判断下是不是已经激活过了。
      if(this.$route.query.type && this.$route.query.type=='first'){
        let params = {
          code:this.$route.query.code
        }
        this.allLoading=true
        postAction(api_is_active,params).then(res=>{
          this.allLoading=false
          if(res=='actived'){
            this.goLogin()
          }else if(res=='reActive'){
            this.goRegister()
          }
        }).catch(()=>{
          this.allLoading=false
        })
      }
    }
  },
  mounted(){
    this.firstActiveHasDone()
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>