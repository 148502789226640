<template>
  <div class="wrap transfer-wrap" v-loading="allLoading" :element-loading-text="L.loading">
    <div class="main-wrap">
      <div class="content-wrap" v-if="showTimer">
        <div class="circle-tird">
          <div class="circle-second">
            <span class="circle-internal">{{time}}</span>
          </div>
        </div>
        <div class="jump-text">{{L.tokenNotExist}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {api_login_url_token} from '@/api/api'
import {postAction} from '@/api/tool'

export default {
  name:'Transfer',
  components:{
    
  },
  data(){
    return {
      time:5,
      timer:null,
      showTimer:false
    }
  },
  methods:{
    /* internal用户。如果链接中带有token，用这个token去请求用户的真实token存在cookie中，其余的流程不变 */
    getTokenFromUrl(){
      if(this.$route.query.token){
        let params = {
          token:this.$route.query.token
        }
        this.allLoading=true
        postAction(api_login_url_token,params).then(res=>{
          this.allLoading=false
          this.$cookies.set('token',res.token,'1d')
          this.$cookies.set('user_id',res.customer.accountId,'1d')
          this.$store.dispatch('setUserInfo',res.customer)
          this.$store.dispatch('setCurrency',res.currency)
          this.$store.dispatch('setArea',res.area)
          this.$store.dispatch('setOU',res.ou)
          // case No.存在，跳到claim的详情页。case No.不存在的话，跳到首页即可
          if(this.$route.query.caseNo){
            if(this.$route.query.flag==1){
              this.goEnquiryDetail(this.$route.query.caseNo)
            }
            if(this.$route.query.flag==2){
              this.goCommercialDetail(this.$route.query.caseNo)
            }
            if(this.$route.query.flag==3){
              this.goFieldFailuresDetail(this.$route.query.caseNo)
            }
            if(this.$route.query.flag==4){
              this.goFailuresProdDetail(this.$route.query.caseNo)
            }
          }else{
            this.$router.push({
              path:'/catalogue'
            })
          }
        }).catch(()=>{
          this.allLoading=false
          this.showTimer=true
          this.timer=setInterval(()=>{
            this.time--
            if(this.time<=1){
              this.showTimer=false
              clearInterval(this.timer)
              this.$router.push({
                path:'/login'
              })
            }
          },1000)
        })
      }else{
        this.showTimer=true
        this.timer=setInterval(()=>{
          this.time--
          if(this.time<=1){
            this.showTimer=false
            clearInterval(this.timer)
            this.$router.push({
              path:'/login'
            })
          }
        },1000)
      }
    }
  },
  mounted(){
    this.getTokenFromUrl()
  }
  
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('../../../public/css/quill.snow.css'); 
@import url('./index.less');
</style>