<template>
  <div class="wrap" v-loading="allLoading">
    <!-- 语言设置 -->
    <div class="language" @mouseenter="mouseenter(0)" @mouseleave="mouseleave(0)">
      <span class="iconfont iconfont0">&#xe618;</span>
      <span class="text">{{$store.state.nowLanguage}}</span>
      <span class="iconfont down" :class="{'active-down':!show.showArrow}">&#xeb8b;</span>
      <transition
        @enter="enter"
        @afterEnter="afterEnter"
        @leave="leave"
        @afterLeave="afterLeave"
      >
        <div class="popover-wrap" v-show="!show.showArrow">
          <div
            v-for="item in $store.state.languageList" 
            :key="item.languageId"
            @click="changeLanguage(item)">{{item.languageName}}</div>
        </div>
      </transition>
    </div>
    <!-- 注册框框 -->
    <div class="main-wrap center register-width">
      <div class="img-wrap">
        <img src="../../assets/logo_en.png">
      </div>
      <div class="content">
        {{L.emailHasSent}}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HasSendEmail',
  data(){
    return {
      show:{
        showArrow:true
      }
    }
  },
  methods:{
    /* 鼠标进入，变成false，展示 */
    mouseenter(flag){
      if(flag==0){
        this.show.showArrow=false
      }
    },
    /* 鼠标移开 */
    mouseleave(flag){
      if(flag==0){
        this.show.showArrow=true
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>