<template>
  <div class="pagination-wrap">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageObj.currentPage"
      :page-sizes="pageObj.pageSizes"
      :page-size="pageObj.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageObj.total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name:'Pagination',
  props:['pageObj'],
  data() {
    return {
      
    }
  },
  methods:{
    /* 改变每页多少条 */
    handleSizeChange(val) {
      this.$emit('sizeChange',val)
    },
    /* 切换第几页 */
    handleCurrentChange(val) {
      this.$emit('currentChange',val)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../public/css/public.less');
.pagination-wrap{
  margin-top: 24px;
  text-align: right;
  // background-color: pink;
}

</style>