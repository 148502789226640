<template>
  <div class="wrap" v-loading="allLoading">
    <!-- 语言设置 -->
    <div class="language" @mouseenter="mouseenter(0)" @mouseleave="mouseleave(0)">
      <span class="iconfont iconfont0">&#xe618;</span>
      <span class="text">{{$store.state.nowLanguage}}</span>
      <span class="iconfont down" :class="{'active-down':!show.showArrow}">&#xeb8b;</span>
      <transition
        @enter="enter"
        @afterEnter="afterEnter"
        @leave="leave"
        @afterLeave="afterLeave"
      >
        <div class="popover-wrap" v-show="!show.showArrow">
          <div 
            v-for="item in $store.state.languageList" 
            :key="item.languageId"
            @click="changeLanguage(item)"
          >{{item.languageName}}</div>
        </div>
      </transition>
    </div>
    <div class="main-wrap">
      <!-- 登录框框 -->
      <div class="center login-width">
        <div class="img-wrap">
          <img src="../../assets/logo_en.png">
        </div>
        <div class="content">
          <!-- 请提供以下细节。 -->
          <div class="title">{{L.pleaseProvide}}</div>
          <!-- 英飞特使用条款和隐私政策 -->
          <div class="inv">{{L.invUseTerm}}</div>
          <div class="choose-wrap">
            <el-checkbox v-model="checked"></el-checkbox>
            <!-- 我已阅读并同意本协议的使用条款和隐私政策。 -->
            <span class="text">{{L.agreeTerms}}</span>
          </div>
        </div>
        <div class="btn">
          <!-- 继续 -->
          <button class="continue" @click="handleSubmit">{{L.continue}}</button>
          <!-- 取消 -->
          <button class="cancel" @click="handleCancel">{{L.cancel}}</button>
        </div>
      </div>
      <!-- 条款 -->
      <div class="terms login-width">
        <a class="term" :href="goTermEnterOrNoEnter('20001',1)" target="_blank">{{L.useTerm}}</a>
        <a class="term" :href="goTermEnterOrNoEnter('20003',1)" target="_blank">{{L.privacyPolicy}}</a>
        <a class="term" :href="goTermEnterOrNoEnter('20004',1)" target="_blank">{{L.cookiePolicy}}</a>
        <a class="term" :href="officialWebsite" target="_blank">{{L.contact}}</a>
        <span class="company">Ⓒ {{L.inventronics}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css';
import {api_is_accept_terms_privacy} from '@/api/api'
import {postAction} from '@/api/tool'

export default {
  name: 'Login',
  data(){
    return {
      checked:false,
      show:{
        showArrow:true
      }
    }
  },
  methods:{
    /* 鼠标进入，变成false，展示 */
    mouseenter(flag){
      if(flag==0){
        this.show.showArrow=false
      }
    },
    /* 鼠标移开 */
    mouseleave(flag){
      if(flag==0){
        this.show.showArrow=true
      }
    },
    handleSubmit(){
      if(!this.checked){
        // 请先勾选然后点击继续
        this.$message.warning(this.L.pleaseCheck)
        return
      }
      this.allLoading=true
      let params = {
        policyAccept:this.checked?1:0
      }
      postAction(api_is_accept_terms_privacy,params).then(()=>{
        this.allLoading=false
        //去主页之前先看下，是不是这个带了routeName的参数，带了的话去到对应的页面，没带直接去主页。注意：要跟其他几个涉及到的地方一起改
        if(this.$route.query.routeName){
          let newQuery = JSON.parse(JSON.stringify(this.$route.query))
          delete newQuery.routeName
          this.$router.push({
            path:'/'+this.$route.query.routeName,
            query:newQuery
          })
        }else{
          this.goCatalogue()
        }
      }).catch(()=>{
        this.allLoading=false
      })
    },
    handleCancel(){
      this.$cookies.remove('token')
      this.$cookies.remove('user_id')
      this.goLogin()
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>