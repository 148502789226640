<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap my-orders">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/selectRMAtype" 
        >{{L.myService}}</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/rmaList" 
        >{{L.myTickets}}</a>
        <span class="line">/</span>
        <a
          class="text text2"
          href="/#/draftsRMAlist" 
        >{{L.drafts}}</a>
      </div>
      <!-- 大标题 -->
      <div class="my-orders-title">
        <div class="left-text">{{L.drafts}}</div>
      </div>
      <!-- 搜索 -->
      <div class="search-wrap">
        <div class="square">
          <span class="text">{{L.orderDateFrom}}:</span>
          <el-date-picker
            v-model="search.dateFrom"
            class="search-field"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            type="date">
          </el-date-picker>
        </div>
        <div class="square">
          <span class="text">{{L.orderDateTo}}:</span>
          <el-date-picker
            v-model="search.dateTo"
            class="search-field"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            type="date">
          </el-date-picker>
        </div>
        <div class="square">
          <span class="text">{{L.caseType}}:</span>
          <el-select :clearable="true" v-model="search.rmaType" class="search-field">
            <el-option
              v-for="item in RMAtypeArr"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>

      </div>
      <!-- 按钮 -->
      <div class="btn-wrap">
        <div class="left">
          <!-- 清除过滤 -->
          <div class="null" @click="cleaFilters">{{L.cleaFilters}}</div>
          <!-- 搜索 -->
          <div class="fill" @click="getList(2)">{{L.search}}</div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="table-wrap">
        <el-table
          @sort-change="sortChange"
          :default-sort="{prop:'caseDate',order:'descending'}"
          :data="list"
          style="width: 100%">
          <el-table-column
            prop="caseNo"
            sortable="custom"
            :label="tableLable.draftNo">
            <template slot-scope="scope">
              <div class="draft-no" @click="handleEdit(scope.row)">{{scope.row.caseNo}}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="caseDate"
            sortable="custom"
            :label="tableLable.createdDate">
          </el-table-column>
          <el-table-column
            prop="rmaType"
            :label="tableLable.caseType">
          </el-table-column>
          <el-table-column
            prop="operation"
            align="center"
            :label="tableLable.delete">
            <template slot-scope="scope">
              <div class="operation">
                <span class="iconfont icon-delete" @click="handleDelete(scope.row)">&#xec7b;</span>
              </div>
            </template>
          </el-table-column>
          <!-- 表格没数据的时候展示空组件，要注意这里的slot=empty不能随意更改 -->
          <div slot="empty">
            <NoContent/>
          </div>
        </el-table>
        <Pagination :pageObj="paginationObj" @sizeChange="sizeChange" @currentChange="currentChange"/>
      </div>
    </div>
    
    
  </div>
</template>

<script>
import NoContent from '@/components/NoContent'
import {api_claim_rma_list,api_claim_delete} from '@/api/api'
import {postAction} from '@/api/tool'
import Pagination from '@/components/Pagination'

export default {
  name:'Catalogue',
  components:{
    NoContent,
    Pagination
  },
  data(){
    return {
      RMAtypeArr:[
        {value:'1',label:'Commercial Enquiry'},
        {value:'2',label:'Commercial/Logistic Incident'},
        {value:'3',label:'Field/Application Incident'},
        {value:'4',label:'Production Incident'}
      ],
      search:{
        dateFrom:'',
        dateTo:'',
        rmaType:''
      },
      list:[], //列表数据
      tableLable:{
        draftNo:'',
        createdDate:'',
        caseType:'',
        delete:''
      }
    }
  },
  watch:{
    L(newVal){
      this.tableLable=newVal
    }
  },
  methods:{
    /* 表格排序 */
    sortChange(obj){
      if(obj.order=="ascending"){
        this.sortInfo.order='ASC'
      }
      if(obj.order=="descending"){
        this.sortInfo.order='DESC'
      }
      if(obj.prop=="caseNo"){
        this.sortInfo.type=3
      }
      if(obj.prop=="caseDate"){
        this.sortInfo.type=2
      }
      this.getList(2)
    },
    /* 获取列表数据 */
    getList(flag=1){
      this.allLoading=true
      this.search.page=flag==2?1:this.paginationObj.currentPage
      this.search.number=this.paginationObj.pageSize
      this.search.formType=2 //2代表草稿
      this.search.sortInfo=this.sortInfo
      postAction(api_claim_rma_list,this.search).then((res)=>{
        this.paginationObj.total=Number(res.total)
        this.allLoading=false
        this.list=res.data
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 重置 */
    cleaFilters(){
      this.search={
        dateFrom:'',
        dateTo:'',
        rmaType:''
      }
      this.getList(2)
    },
    handleEdit(obj){
      if(obj.flag==1){
        this.goEnquiry('draft',obj.caseNo)
      }
      if(obj.flag==2){
        this.goCommercial('draft',obj.caseNo)
      }
      if(obj.flag==3){
        this.goFieldFailuers('draft',obj.caseNo)
      }
      if(obj.flag==4){
        this.goFailuresProd('draft',obj.caseNo)
      }
    },
    handleDelete(obj){
      let params = {
        caseNo:obj.caseNo,
        flag:obj.flag
      }
      this.$confirm('Are you sure delete?', {
        confirmButtonText: this.promptConfirmBtn,
        cancelButtonText: this.propmtCancelBtn,
        closeOnClickModal:this.promptCloseFlag,
        type: 'warning',
        lockScroll:false,
        customClass:'message-box',
      }).then(() => {
        this.allLoading=true
        postAction(api_claim_delete,params).then(()=>{
          this.allLoading=false
          this.$message.success('Delete successful.')
          this.getList()
        }).catch(()=>{
          this.allLoading=false
        })
      }).catch(()=>{
        
      })
    }
  },
  mounted(){
    this.tableLable=this.L
    //最开始进来的时候就按照日期排序
    this.sortInfo.order='DESC'
    this.sortInfo.type=2
    this.getList(2)
  }
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>

