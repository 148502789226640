<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap my-orders">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text2"
          href="/#/debitNotesList" 
        >{{L.myDebitNotes}}</a>
      </div>
      <!-- 我的订单-标题 -->
      <div class="my-orders-title">{{L.myDebitNotes}}</div>
      <!-- 搜索 -->
      <div class="search-wrap">
        <el-form :model="search" ref="formSearch" :rules="rules" :show-message="false">
          <div class="square">
            <!-- 日期从 -->
            <span class="text">{{L.debitDateFrom}}:</span>
            <el-form-item prop="dateFrom">
              <el-date-picker
                class="search-field"
                v-model="search.dateFrom"
                value-format="yyyy-MM-dd"
                format="dd-MM-yyyy"
                :picker-options="pickerOptions"
                type="date">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="square">
            <!-- 日期到 -->
            <span class="text">{{L.debitDateTo}}:</span>
            <el-form-item prop="dateTo">
              <el-date-picker
                class="search-field"
                v-model="search.dateTo"
                value-format="yyyy-MM-dd"
                format="dd-MM-yyyy"
                :picker-options="pickerOptions"
                type="date">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="square">
            <!-- 编号 -->
            <span class="text">{{L.debitNoteID}}:</span>
            <el-form-item prop="noteId">
              <el-input @keyup.enter.native="getList(2)" v-model="search.noteId" class="search-field"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 按钮 -->
      <div class="btn-wrap">
        <div class="left">
          <!-- 清除过滤 -->
          <div class="null" @click="clearFilters">{{L.cleaFilters}}</div>
          <!-- 搜索 -->
          <div class="fill" @click="getList(2)">{{L.search}}</div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="table-wrap">
        <el-table
          :data="list"
          style="width: 100%"
          :default-sort="{prop:'invoiceNumber',order:'descending'}"
        >
          <el-table-column
            prop="invoiceNumber"
            :sortable="true"
            :label="tableLable.ERPDebitNoteID">
              <template slot-scope="scope">
                <a 
                  :href="'/#/debitNotesDetail?noteId='+scope.row.invoiceNumber+'&batchNumber='+batchNumber+'&dateFrom='+oldSearch.dateFrom+'&dateTo='+oldSearch.dateTo" 
                  class="order-id"
                >{{scope.row.invoiceNumber}}</a>
              </template>
          </el-table-column>
          <el-table-column
            prop="creationDate"
            sortable
            :label="tableLable.createdOn"
          >
            <template slot-scope="scope">
              <span>
                {{ formatDateNoTime(scope.row.creationDate) }}
              </span>
            </template>
          </el-table-column>
          <!-- 表格没数据的时候展示空组件，要注意这里的slot=empty不能随意更改 -->
          <div slot="empty">
            <NoContent/>
          </div>
        </el-table>
        <Pagination :pageObj="paginationObj" @sizeChange="sizeChange" @currentChange="currentChange"/>
      </div>
    </div>
    
    
  </div>
</template>

<script>
import {postAction} from '@/api/tool'
import {api_debit_list} from '@/api/api'
import NoContent from '@/components/NoContent'
import moment from 'moment'
import Pagination from '@/components/Pagination'

export default {
  name:'DebitNotesList',
  components:{
    NoContent,
    Pagination
  },
  data(){
    return {
      batchNumber:'',
      search:{
        dateFrom:'',
        dateTo:'',
        noteId:''
      },
      rules:{
        // dateFrom:[{required:true,trigger:'blur'}],
        // dateTo:[{required:true,trigger:'blur'}]
      },
      oldSearch:{
        dateFrom:'',
        dateTo:''
      },
      list:[], //列表数据
      pickerOptions:{
        disabledDate: e => {
          let chooseDate = moment(new Date(e)).format('YYYY-MM-DD') //当选择的日期
          let chooseTime = new Date(chooseDate).valueOf() //选择的时间戳
          let date = moment(new Date()).format('YYYY-MM-DD') //当前日期
          let dateTime = new Date(date).valueOf() //当前日期时间戳
          //小于等于今天的时间，可以选择。反之不能选择
          if(chooseTime<=dateTime){
            return false
          }else{
            return true
          }
        }
      },//日期
      tableLable:{
        ERPDebitNoteID:'',
        createdOn:''
      } //表格的表头，因为这个地方切换语言的时候没办法正常渲染，所以需要监听语言包的变化，进行重新渲染
    }
  },
  watch:{
    L(newVal){
      this.tableLable=newVal
    }
  },
  methods:{
    /* 获取列表 */
    getList(flag=1){
      this.$refs.formSearch.validate(valid=>{
        if(!valid){
          this.$message.warning('Please complete the required fields.');
        }else{
          this.search.page=flag==2?1:this.paginationObj.currentPage
          this.search.number=this.paginationObj.pageSize
          this.oldSearch.dateFrom=this.search.dateFrom
          this.oldSearch.dateTo=this.search.dateTo
          this.allLoading=true
          postAction(api_debit_list,this.search).then(res=>{
            this.allLoading=false
            this.paginationObj.total=Number(res.total)
            this.batchNumber=res.data.batchNumber
            this.list=res.data.headLines
          }).catch(()=>{
            this.allLoading=false
          })
        }
      })
    },
    /* 重置搜索条件并且直接请求数据 */
    clearFilters(){
      this.search={
        dateFrom:'',
        dateTo:'',
        noteId:''
      }
      this.setDefaultDate()
      this.getList(2)
    },
    /* 设置默认日期,进到这个路由的时候要设置一下默认日期 */
    setDefaultDate(){
      let date = new Date()
      this.search.dateTo = moment(date).format('YYYY-MM-DD')
      this.search.dateFrom = moment(date).subtract(3,'months').format('YYYY-MM-DD') //回退3个月
    }
  },
  mounted(){
    this.tableLable=this.L
    this.setDefaultDate() //注意这个方法要在getList之前，因为在最开始加载请求的时候，需要先把日期设置进去并且传给后端再去请求数据
    this.getList(2)
  }
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>

