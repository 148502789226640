<template>
  <div class="wrap">
    <div class="main-wrap my-account">
      <div class="support-title">Customer Support</div>
      <div class="tab">
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Catalogue',
  data(){
    return {
      
    }
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>