<template>
  <el-dialog
    v-loading="allLoading"
    :visible.sync="showCountryDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="true"
    custom-class="dialogue-country"
    width="50%">
    <div class="content">
      <!-- <div class="select-country">{{L.selectCountry}} :</div> -->
      <el-select :filterable="true" v-model="countryCode" :placeholder="L.selectCountry" class="select-country-inp">
        <el-option
          v-for="item in $store.state.countryList"
          :key="item.eshopCountry"
          :label="item.eshopCountry"
          :value="item.eshopUrl+';'+item.eshopCountry">
        </el-option>
      </el-select>
      <div slot="footer" class="footer">
        <el-button type="primary" @click="handleChooseCountry" class="btn">{{L.confirmSelect}}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import merge from 'webpack-merge'

export default {
  name:'SelectCountry',
  data() {
    return {
      needRefresh:0,
      showCountryDialog:false, //选择国家
      countryCode:'' //选中的国家
    }
  },
  methods:{
    /* 打开弹窗 */
    openSelectCountry(flag=0){
      this.needRefresh=flag
      this.showCountryDialog=true
      this.countryCode=this.$cookies.get('select_country')
    },
    handleChooseCountry(){
      if(!this.countryCode){
        this.$message.warning(this.L.pleaseSelectCountry)
        return
      }
      this.$cookies.set('select_country',this.countryCode,'180d')
      this.showCountryDialog=false
      this.$emit('afterChooseCountry')
      if(this.needRefresh==1){
        // let queryObj = this.$route.query
        // queryObj.Catalogue = this.countryCode.split(';')[0]
        // console.log('进来没')
        this.$router.push({
          // to:this.$route.path,
          query:merge(this.$route.query,{Catalogue:this.countryCode.split(';')[0]})
        })
        this.$router.go(0)
      }
    }
  }
}

</script>

<style lang="less" scoped>
/* 对话框-选择国家 */
  .dialogue-country{
    .content{
      // background-color: pink;
      margin-bottom:30px;
      .select-country{
        display: inline-block;
        width:80%;
        text-align: left;
        font-weight: 600;
      }
      .select-country-inp{
        margin-top: 20px;
        width:80%;
      }
      .footer{
        width:100%;
        margin-top:30px;
        .btn{
          white-space:pre-wrap;
          overflow: auto;
          width:80%;
          line-height: 20px;
        }
      }
    }
  }
</style>
