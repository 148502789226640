<template>
  <div>
    <!-- 价格-框 -->
    <div class="price-wrap">
      <div class="one-line">
        <!-- 请注意，您的发票信息来源于订单确认书。 -->
        {{L.pleaseNote}}
      </div>
      <div class="btn-warning" v-show="deliveryData.errorMessageTxt">
        <span class="iconfont icon-warning">&#xe6aa;</span>
        <!-- 警示 -->
        {{L.warning}}: {{deliveryData.errorMessageTxt}}
      </div>
      <div class="two-line">
        <div class="total-price">
          <!-- 不含税总金额 -->
          <b>{{L.totalAmount}}:</b>
          <!-- orderltems -->
          <span>({{$store.state.cartCount}} {{L.orderltems}})</span>
        </div>
        <div class="price">
          <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
            {{formatMoney(deliveryData.totalPrice)}}
          </template>
        </div>
        <div class="delivery-btn">
          <!-- 下一步 -->
          <!-- <div class="btn" @click="nextStep" :class="{'no-use':!deliveryData.generalSuccessfulFlag}">{{L.continueNextStep}}</div> -->
          <div class="btn" @click="nextStep">{{L.continueNextStep}}</div>
        </div>
      </div>
      <!-- 折扣 -->
      <div class="three-line-wrap" v-show="deliveryData.totalDiscount && deliveryData.totalDiscount.length>0">
        <div class="three-line" v-for="item,i in deliveryData.totalDiscount" :key="i">
          <div class="col1">
            <span>{{item.discountType}}</span>
            </div>
          <div class="col2">
            <span>{{item.discountPct || 'fixed'}}</span>
          </div>
          <div class="col3">
            <span>{{item.ifMinus?'-':''}} {{formatMoney(Math.abs(item.discountAmount))}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 我的购物车-标题 -->
    <div class="my-cart-title">
      <!-- 我的购物车 -->
      <div class="title">{{L.myShoppingCart}}</div>
    </div>
    <!-- 产品概述 -->
    <div class="product-overview">
      <!-- 大标题 -->
      <div class="title">
        <!-- 订单详情 -->
        <span class="text">{{L.productOverview}}</span>
        <!-- 个产品项 -->
        <span class="quantity">{{$store.state.cartCount}} {{L.orderltems}}</span>
      </div>

      <!-- 标准产品 -->
      <div class="standard-pro" v-show="deliveryData.orderItems && deliveryData.orderItems.length>0">
        <!-- <div class="standard-title">Standard Products</div> -->
        <div class="line" v-for="item in deliveryData.orderItems" :key="item.headVo && item.headVo.skuId">
          <!-- 大行的报警信息 -->
          <div class="big-line-warning">
            <div v-for="item3,i in item.headVo.errorMessageSet" :key="i">
              <span class="iconfont icon-warning" v-show="i==0">&#xe6aa;</span>
              <!-- 警示 -->
              <span v-show="i==0">{{L.warning}}: </span>
              <span class="text">{{item3}}</span>
            </div>
          </div>
          <!-- 内容标题 -->
          <div class="line-title">
            <div class="title-left"></div>
            <div class="title-right">
              <!-- 需求的交货日期 -->
              <div class="date">{{L.requestedDeliveryDate}}:</div>
              <!-- 数量 -->
              <div class="quantity">{{L.quantity}}:</div>
              <div class="price"></div>
            </div>      
          </div>
          <!-- 主内容 -->
          <div class="main-content">
            <!-- 左边的 -->
            <div class="left">
              <div class="info">
                <div class="image" :class="{'no-image':item.ifVisible!=1}">
                  <img v-show="item.ifVisible==1" :src="item.headVo.loopImageList && item.headVo.loopImageList[0]" alt="">
                </div>
                <div class="text">
                  <div class="text1">
                    <span v-if="item.canJump!=1" class="span1" href="javascript:;" @click="handleSkuName">
                      {{item.headVo && item.headVo.skuName}}
                    </span>
                    <a v-if="item.canJump==1" class="span1" :href="detailUrl(1,item.eanCode)">
                      {{item.headVo && item.headVo.skuName}}
                    </a>
                  </div>
                  <div class="text2">{{item.headVo && item.headVo.eanOrIc}} {{item.headVo && item.headVo.skuId}}</div>
                  <!-- 小图标 -->
                  <div class="small-pic" v-show="item.ifVisible==1">
                    <img class="img img1" :src="item.headVo && item.headVo.energyLogoImage" v-show="item.headVo && item.headVo.energyLogoImage"/>
                    <img class="big-img" :src="item.headVo && item.headVo.energyBigImage"/>
                    <img class="img" v-for="item2 in item.headVo.logoImageList" :key="item2" :src="item2"/>
                    <div class="icons" v-show="item.headVo.dataSheetLink">
                      <span class="iconfont icon-file" @click="downloadProduct(item.headVo.dataSheetLink,1)">&#xe60a;</span>
                      <span class="iconfont icon-download" @click="downloadProduct(item.headVo.dataSheetLink,0)">&#xe61c;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 右边的一行 -->
            <div class="right">
              <div class="right-line" v-for="item2 in item.linesVo" :key="item2.lineDetailId">
                <!-- 基本内容 -->
                <div class="basic-info">
                  <div class="date">
                    <span class="iconfont icon-date">&#xe807;</span>
                    <span class="text-date" :class="{'active-text':item2.deliveryDateStatus!='A'}">{{formatDate(item2.deliveryDate)}}</span>
                  </div>
                  <div class="quantity">
                    {{item2.skuCount}}
                  </div>
                  <!-- 钱 -->
                  <div class="price">
                    <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                      <div class="left-price" v-show="item2.skuNewAmount">
                        <div v-show="item2.skuAmountStatus=='A' || !Boolean(item2.skuAmountStatus)">
                          <div class="price-num">
                            {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                          </div>
                          <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} per {{item2.assemblyType}})</div>
                        </div>
                        <div v-show="item2.skuAmountStatus=='B'">
                          <div class="new-price">
                            <el-tooltip 
                              class="item" 
                              effect="dark"
                              placement="top"
                            >
                              <div slot="content">
                                <div v-for="item3,i in item2.discountVos" :key="i">
                                  {{item3.discountType}}:&nbsp;&nbsp;{{item3.discountPct}}&nbsp;&nbsp;{{item3.ifMinus?'-':''}} {{formatMoney(Math.abs(item3.discountAmount))}}
                                </div>
                              </div>
                              <span class="iconfont icon-info">&#xed50;</span>
                            </el-tooltip>
                            {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                          </div>
                          <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} per {{item2.assemblyType}})</div>
                        </div>
                        <div v-show="item2.skuAmountStatus=='C'">
                          <div class="price-num2">{{formatMoney(item2.skuOldAmount,item2.customerCurrency)}}</div>
                          <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} per {{item2.assemblyType}})</div>
                          <div class="new-price">
                            <el-tooltip 
                              class="item" 
                              effect="dark"
                              placement="top"
                            >
                              <div slot="content">
                                <div v-for="item3,i in item2.discountVos" :key="i">
                                  {{item3.discountType}}:&nbsp;&nbsp;{{item3.discountPct}}&nbsp;&nbsp;{{item3.ifMinus?'-':''}} {{formatMoney(Math.abs(item3.discountAmount))}}
                                </div>
                              </div>
                              <span class="iconfont icon-info">&#xed50;</span>
                            </el-tooltip>
                            {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                          </div>
                        </div>
                      </div>
                      <!-- 无论是null 还是'' 还是0 布尔值都是false，只要是false，就要展示Price on request -->
                      <div class="left-price" v-show="!item2.skuNewAmount">
                        <!-- 价格需求申请 -->
                        <div class="carton-box">{{L.priceRequest}}</div>
                      </div>
                    </template>
                  </div>
                </div>
                <!-- 装箱内容 -->
                <div class="carton-box-remark" v-show="item2.qtyMsgList.length>=1 && item.ifVisible==1">
                  <div class="line-title">
                    <div class="date"></div>
                    <div class="quantity">
                      <div class="line-one">
                        <span 
                          class="iconfont icon-arrow"
                          :class="{'icon-active':item2.showCarton}"
                          @click="clickCartonFold(item,item2,1)"
                        >&#xe66c;</span>
                        <span class="text">{{item2.qtyMsgList[0]}}</span>
                      </div>
                      <transition
                        @enter="enter"
                        @afterEnter="afterEnter"
                        @leave="leave"
                        @afterLeave="afterLeave"
                      >
                      <div v-show="item2.showCarton" class="line-more-wrap">
                        <div class="line-more" v-for="item3,i in item2.qtyMsgList" :key="i">{{i>=1?item3:null}}</div>
                      </div>
                      </transition>
                    </div>
                  </div>
                </div>
                <!-- 小行的报警信息 -->
                <div class="small-line-warning">
                  <div v-for="item3,i in item2.errMsgList" :key="i">
                    <span class="iconfont icon-warning" v-show="i==0">&#xe6aa;</span>
                    <!-- 警示 -->
                    <span v-show="i==0">{{L.warning}}: </span>
                    <span class="text">{{item3}}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- quote产品 -->
      <div class="standard-pro" v-show="deliveryData.quoteOrderItems && deliveryData.quoteOrderItems.length>0" v-for="obj in deliveryData.quoteOrderItems" :key="obj.quoteHeadId">
        <div class="standard-title">Quote {{obj.quoteNumber}}</div>
        <div class="line" v-for="item in obj.quoteItems" :key="item.headVo && item.headVo.skuId">
          <!-- 大行的报警信息 -->
          <div class="big-line-warning">
            <div v-for="item3,i in item.headVo.errorMessageSet" :key="i">
              <span class="iconfont icon-warning" v-show="i==0">&#xe6aa;</span>
              <!-- 警示 -->
              <span v-show="i==0">{{L.warning}}: </span>
              <span class="text">{{item3}}</span>
            </div>
          </div>
          <!-- 内容标题 -->
          <div class="line-title">
            <div class="title-left"></div>
            <div class="title-right">
              <!-- 需求的交货日期 -->
              <div class="date">{{L.requestedDeliveryDate}}:</div>
              <!-- 数量 -->
              <div class="quantity">{{L.quantity}}:</div>
              <div class="price"></div>
            </div>      
          </div>
          <!-- 主内容 -->
          <div class="main-content">
            <!-- 左边的 -->
            <div class="left">
              <div class="info">
                <div class="image" :class="{'no-image':item.ifVisible!=1}">
                  <img v-show="item.ifVisible==1" :src="item.headVo.loopImageList && item.headVo.loopImageList[0]" alt="">
                </div>
                <div class="text">
                  <div class="text1">
                    <span v-if="item.canJump!=1" class="span1" href="javascript:;" @click="handleSkuName">
                      {{item.headVo && item.headVo.skuName}}
                    </span>
                    <a v-if="item.canJump==1" class="span1" :href="detailUrl(1,item.eanCode)">
                      {{item.headVo && item.headVo.skuName}}
                    </a>
                  </div>
                  <div class="text2">{{item.headVo && item.headVo.eanOrIc}} {{item.headVo && item.headVo.skuId}}</div>
                  <!-- 小图标 -->
                  <div class="small-pic" v-show="item.ifVisible==1">
                    <img class="img img1" :src="item.headVo && item.headVo.energyLogoImage" v-show="item.headVo && item.headVo.energyLogoImage"/>
                    <img class="big-img" :src="item.headVo && item.headVo.energyBigImage"/>
                    <img class="img" v-for="item2 in item.headVo.logoImageList" :key="item2" :src="item2"/>
                    <div class="icons" v-show="item.headVo.dataSheetLink">
                      <span class="iconfont icon-file" @click="downloadProduct(item.headVo.dataSheetLink,1)">&#xe60a;</span>
                      <span class="iconfont icon-download" @click="downloadProduct(item.headVo.dataSheetLink,0)">&#xe61c;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 右边的一行 -->
            <div class="right">
              <div class="right-line" v-for="item2 in item.linesVo" :key="item2.lineDetailId">
                <!-- 基本内容 -->
                <div class="basic-info">
                  <div class="date">
                    <span class="iconfont icon-date">&#xe807;</span>
                    <span class="text-date" :class="{'active-text':item2.deliveryDateStatus!='A'}">{{formatDate(item2.deliveryDate)}}</span>
                  </div>
                  <div class="quantity">
                    {{item2.skuCount}}
                  </div>
                  <!-- 钱 -->
                  <div class="price">
                    <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                      <div class="left-price" v-show="item2.skuNewAmount">
                        <div v-show="item2.skuAmountStatus=='A' || !Boolean(item2.skuAmountStatus)">
                          <div class="price-num">
                            {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                          </div>
                          <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} per {{item2.assemblyType}})</div>
                        </div>
                        <div v-show="item2.skuAmountStatus=='B'">
                          <div class="new-price">
                            <el-tooltip 
                              class="item" 
                              effect="dark"
                              placement="top"
                            >
                              <div slot="content">
                                <div v-for="item3,i in item2.discountVos" :key="i">
                                  {{item3.discountType}}:&nbsp;&nbsp;{{item3.discountPct}}&nbsp;&nbsp;{{item3.ifMinus?'-':''}} {{formatMoney(Math.abs(item3.discountAmount))}}
                                </div>
                              </div>
                              <span class="iconfont icon-info">&#xed50;</span>
                            </el-tooltip>
                            {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                          </div>
                          <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} per {{item2.assemblyType}})</div>
                        </div>
                        <div v-show="item2.skuAmountStatus=='C'">
                          <div class="price-num2">{{formatMoney(item2.skuOldAmount,item2.customerCurrency)}}</div>
                          <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} per {{item2.assemblyType}})</div>
                          <div class="new-price">
                            <el-tooltip 
                              class="item" 
                              effect="dark"
                              placement="top"
                            >
                              <div slot="content">
                                <div v-for="item3,i in item2.discountVos" :key="i">
                                  {{item3.discountType}}:&nbsp;&nbsp;{{item3.discountPct}}&nbsp;&nbsp;{{item3.ifMinus?'-':''}} {{formatMoney(Math.abs(item3.discountAmount))}}
                                </div>
                              </div>
                              <span class="iconfont icon-info">&#xed50;</span>
                            </el-tooltip>
                            {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                          </div>
                        </div>
                      </div>
                      <!-- 无论是null 还是'' 还是0 布尔值都是false，只要是false，就要展示Price on request -->
                      <div class="left-price" v-show="!item2.skuNewAmount">
                        <!-- 价格需求申请 -->
                        <div class="carton-box">{{L.priceRequest}}</div>
                      </div>
                    </template>
                  </div>
                </div>
                <!-- 装箱内容 -->
                <div class="carton-box-remark" v-show="item2.qtyMsgList.length>=1 && item.ifVisible==1">
                  <div class="line-title">
                    <div class="date"></div>
                    <div class="quantity">
                      <div class="line-one">
                        <span 
                          class="iconfont icon-arrow"
                          :class="{'icon-active':item2.showCarton}"
                          @click="clickCartonFold(item,item2,2,obj)"
                        >&#xe66c;</span>
                        <span class="text">{{item2.qtyMsgList[0]}}</span>
                      </div>
                      <transition
                        @enter="enter"
                        @afterEnter="afterEnter"
                        @leave="leave"
                        @afterLeave="afterLeave"
                      >
                      <div v-show="item2.showCarton" class="line-more-wrap">
                        <div class="line-more" v-for="item3,i in item2.qtyMsgList" :key="i">{{i>=1?item3:null}}</div>
                      </div>
                      </transition>
                    </div>
                  </div>
                </div>
                <!-- 小行的报警信息 -->
                <div class="small-line-warning">
                  <div v-for="item3,i in item2.errMsgList" :key="i">
                    <span class="iconfont icon-warning" v-show="i==0">&#xe6aa;</span>
                    <!-- 警示 -->
                    <span v-show="i==0">{{L.warning}}: </span>
                    <span class="text">{{item3}}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import {api_cart_address} from '@/api/api'
import {postAction,downFileGet} from '@/api/tool'
import Vue from 'vue'

export default {
  name:'Delivery',
  components:{
    
  },
  props:['deliveryData'],
  data(){
    return {
      
    }
  },
  methods:{
    /* 下一步 */
    nextStep(){
      // if(!this.deliveryData.generalSuccessfulFlag){
      //   // 请联系管理员
      //   this.$message.warning(this.L.PleaseContactAdmin)
      //   return
      // }
      this.$emit('openLoading')
      postAction(api_cart_address).then(res=>{
        this.$emit('changeFocusFlag',3)
        this.$emit('setAddressInfo',res)
        this.$emit('closeLoading')
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 点击展开与折叠装箱信息 */
    clickCartonFold(item,item2,flag,quoteObj){
      if(flag==1){
        this.deliveryData.orderItems.forEach((val,i)=>{
          if(item.headVo.skuId===val.headVo.skuId){
            val.linesVo.forEach((val2,i2)=>{
              if(val2.lineDetailId===item2.lineDetailId){
                val.linesVo[i2].showCarton=!val.linesVo[i2].showCarton
                this.$set(this.deliveryData.orderItems,i,val)
              }
            })
          }
        })
      }
      if(flag==2){
        this.deliveryData.quoteOrderItems.forEach((val,i)=>{
          //找到第一层
          if(quoteObj.quoteHeadId===val.quoteHeadId){
            //找到第二层
            val.quoteItems.forEach((val2,i2)=>{
              if(val2.headVo.skuId===item.headVo.skuId){
                val2.linesVo.forEach((val3,i3)=>{
                  if(val3.lineDetailId===item2.lineDetailId){
                    val.quoteItems[i2].linesVo[i3].showCarton=!val.quoteItems[i2].linesVo[i3].showCarton
                    this.$set(this.deliveryData.quoteOrderItems,i,val)
                  }
                })
              }
            })
          }
        })
      }
    },
    /* 下载产品文件 */
    downloadProduct(src,flag){
      let url=src.includes('inventronicsglobal')?src.split(Vue.config.DAM_URL)[1]:src.split(Vue.config.OSR_URL)[1]
      url=this.decodeHtml('/'+url)
      this.$emit('openLoading')
      downFileGet(url).then(res=>{
        this.$emit('closeLoading')
        this.downloadFile(res,flag)
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 如果ifVisible标记为0，点击skuName，报错提示 */
    handleSkuName(){
      this.$message.warning(this.L.sorryResult)
    }

  }
}
</script>

<style lang="less" scoped>
@import url('../../../../public/css/public.less');
@import url('./index.less');
</style>