<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap my-orders">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text2"
          href="/#/myAccountStatement"
        >{{L.myAccountStatement}}</a>
      </div>
      <!-- 我的订单-标题 -->
      <div class="my-orders-title">{{L.myAccountStatement}}</div>
      <!-- 搜索 -->
      <div class="search-wrap">
        <!-- <div class="square">
          <span class="text">{{L.entriesStatus}} <span class="asterisk">*</span> :</span>
          <el-select v-model="statusCode" class="search-field" placeholder="">
            <el-option
              v-for="item in statusArr"
              :key="item.code"
              :label="item.value"
              :value="item.code">
            </el-option>
          </el-select>
        </div> -->
        <div class="square">
          <span class="text">{{L.itemsOpenOn}} :</span>
          <el-date-picker
            :clearable="false"
            class="search-field"
            v-model="requestDate"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            type="date">
          </el-date-picker>
        </div>
      </div>
      <!-- 必填 -->
      <!-- <div class="mandatory">
        <span class="asterisk">*</span>
        <span class="text">{{L.mandatoryField}}</span>
      </div> -->
      <!-- 按钮 -->
      <div class="btn-wrap">
        <div class="left">
          <div class="null" @click="clearFilters">{{L.cleaFilters}}</div>
          <div class="fill" @click="handleDownload">{{L.downloadAccountStatement}}</div>
        </div>
      </div>
    </div>
    
    
  </div>
</template>

<script>
import {postAction,downFileGet} from '@/api/tool'
import {api_account_stmt_download} from '@/api/api'
import Vue from 'vue'
import moment from 'moment'

export default {
  name:'MyAccountStatement',
  data(){
    return {
      // statusCode:'',
      requestDate:'',
      // statusArr:[]
    }
  },
  methods:{
    /* 下载 */
    handleDownload(){
      // if(!this.statusCode || !this.requestDate){
      //   this.$message.warning('Please complete the fields marked with *')
      //   return
      // }
      let params = {
        // statusCode:this.statusCode,
        requestDate:this.requestDate
      }
      this.allLoading=true
      postAction(api_account_stmt_download,params).then(res=>{
        let url='/'+res.split(Vue.config.YUNPAN_URL)[1]
        url=url.replace('PDF?','pdf?')
        downFileGet(url).then(res=>{
          this.allLoading=false
          this.downloadFile(res,1)
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 重置搜索条件并且直接请求数据 */
    clearFilters(){
      // this.statusCode='all'
      this.setDefaultDate()
    },
    /* 设置默认日期,进到这个路由的时候要设置一下默认日期 */
    setDefaultDate(){
      let date = new Date()
      this.requestDate = moment(date).format('YYYY-MM-DD')
    },
    /* 获取下拉数据 */
    // getSelectData(){
    //   postAction(api_account_stmt_status_list).then(res=>{
    //     this.statusArr=res
    //   })
    // }
  },
  mounted(){
    this.clearFilters()
    // this.getSelectData()
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>

