<template>
  <div class="quick-wrap" v-show="show" @touchmove.prevent>
    <div class="quick-order">
      <div class="icon-close" @click="close">
        <span class="iconfont icon1">&#xe614;</span>
        <span class="iconfont icon2">&#xe606;</span>
      </div>
      <div class="remark">{{L.reuqestForQuo}}</div>
      <div class="remark2">{{L.successQuo}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name:'QuoteSuccess',
  data() {
    return {
      show:false
    }
  },
  methods:{
    open(){
      this.show=true
    },
    close(){
      this.show=false
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
/* 快速下单 */
.quick-wrap{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 999;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
  .quick-order{
    border-radius: 2px;
    position: absolute;
    &:extend(.main-box-shadow);
    width:500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    padding:40px;
    box-sizing: border-box;
    text-align: left;
    .icon-close{
      // background-color: red;
      position: absolute;
      right:14px;
      top:14px;
      .icon1{
        font-size: 24px;
        display: block;
        color:#888;
      }
      .icon2{
        font-size: 24px;
        display: none;
        color:@mainColor;
      }
      &:hover{
        cursor: pointer;
        .icon1{
          display: none;
        }
        .icon2{
          display: block;
        }
      }
    }
    .remark{
      text-align: left;
      font-weight: 800;
      font-size: 16px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 15px;
    }
    .remark2{
      margin-top: 18px;
      font-weight: 800;
      color:#333;
      font-weight: 100;
    }
  }
}

</style>