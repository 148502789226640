<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap order-details">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/debitNotesList" 
        >{{L.myDebitNotes}}</a>
        <span class="line">/</span>
        <a
          class="text text2"
          :href="'/#/debitNotesDetail?noteId='+$route.query.noteId" 
        >{{$route.query.noteId}}</a>
      </div>
      <div class="order-detail-title">
        {{L.debitNote}} {{$route.query.noteId}}
      </div>
      <!-- 基本信息 -->
      <div class="basic-information">
        <div class="info-left order-detail-left">
          <div class="customer">
            <div class="title">
              {{L.customerInfo}}
            </div>
            <div class="content">
              <div class="line">
                <div class="left">
                  <span class="left-text1">{{L.soldTo}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.soldTo}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 产品概述 -->
          <div class="product-overview">
            <!-- 大标题 -->
            <div class="title">
              <!-- 订单详情 -->
              <span class="text">{{L.productOverview}}</span>
              <!-- 个产品项 -->
              <span class="quantity">{{orderInfo.itemCount}} Items</span>
            </div>
            <!-- 一大行 -->
            <div class="line" v-for="item in orderList" :key="item.invoiceLine">
              <div class="info">
                <div class="text">
                  <div class="text1">
                    <span v-if="item.canJump!=1" class="span1" href="javascript:;" @click="handleSkuName">{{item.productName}}</span>
                    <a v-if="item.canJump==1" class="span1" :href="detailUrl(1,item.eanCode)">{{item.productName}}</a>
                  </div>
                  <div class="text2">{{item.eanOrIc}} {{item.itemNumber}}</div>
                </div>
                <div class="text" v-if="item.referenceOrder">
                  <a
                    class="text3"
                    :href="'/#/orderDetail?ebsorderNo='+item.referenceOrder" 
                  >{{L.order}} {{item.referenceOrder}}</a>
                </div>
                <div class="text" v-if="item.referenceDelivery">
                  <div class="text3" @click="donwloadDeliveryNote(item)">{{L.referenceDelivery}}</div>
                </div>
                <div class="text" v-if="item.referenceInvoiceId">
                  <a
                    class="text3"
                    :href="'/#/invoiceDetail?invoiceHeaderId='+item.referenceInvoiceId+'&invoiceNo='+item.referenceInvoice" 
                  >{{L.invocie}} {{item.referenceInvoice}}</a>
                </div>
              </div>
              <div class="money">
                {{formatMoney(item.amount,orderInfo.currency)}}
              </div>
            </div>
          </div>
        </div>
        <div class="info-right order-detail-right">
          <!-- 订单汇总 -->
          <div class="order-summary">{{L.debitNote}}</div>
          <div class="content">
            <div class="detail-info">
              <span class="text-left">{{L.postingStatus}}:</span>
              <span class="text-right">{{orderInfo.postingStatus}}</span>
            </div>
            <div class="detail-info">
              <span class="text-left">{{L.createdDate}}:</span>
              <span class="text-right">{{formatDateNoTime(orderInfo.creationDate)}}</span>
            </div>
          </div>
          <div class="total-price">
            <div class="price-left">
              <div class="total">Total Price</div>
              <div class="item">{{orderInfo.itemCount}} {{L.items}}</div>
            </div>
            <div class="price-right">{{formatMoney(orderInfo.totalPrice,orderInfo.currency)}}</div>
          </div>
          <div 
            class="btn-order-confirm"
            :class="{'active-order':orderInfo.invoicePdf}"
            @click="handlePdf"
          >{{L.downloadDebitMemo}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {api_debit_detail,api_order_confirm_download} from '@/api/api'
import { downFileGet,postAction,getAction } from '@/api/tool'
import Vue from 'vue'

export default {
  name:'DebitNotesDetail',
  components:{
    
  },
  data(){
    return {
      orderInfo:{},
      orderList:[]
    }
  },
  methods:{
    /* 如果不是EAN的，点击skuName，报错提示 */
    handleSkuName(){
      this.$message.warning(this.L.sorryResult)
    },
    /* 获取order详情数据 */
    getOrderDetail(){
      let params={
        noteId:this.$route.query.noteId,
        batchNumber:this.$route.query.batchNumber,
        dateFrom:this.$route.query.dateFrom=='null'?'':this.$route.query.dateFrom,
        dateTo:this.$route.query.dateTo=='null'?'':this.$route.query.dateTo
      }
      this.allLoading=true
      postAction(api_debit_detail,params).then(res=>{
        this.allLoading=false
        this.orderInfo=res
        this.orderList=res.details
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载delivery note */
    donwloadDeliveryNote(item){
      let params = {
        filezIds:item.referenceDelivery
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res,1)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载PDF */
    handlePdf(){
      if(!this.orderInfo.invoicePdf){
        return
      }
      let params = {
        filezIds:this.orderInfo.invoicePdf
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res,1)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    }
  },
  mounted(){
    this.getOrderDetail()
  }
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>

