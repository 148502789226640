<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap fieldFailuers-wrap">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/selectRMAtype" 
        >{{L.myService}}</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/rmaList" 
        >{{L.myTickets}}</a>
        <span class="line">/</span>
        <a
          class="text text2"
          :href="'/#/enquiryDetail?flag='+$route.query.flag+'&caseNo='+$route.query.caseNo" 
        >{{formCommon.crmCaseNo}}</a>
      </div>

      <div class="big-title">
        <span class="text1">{{L.commercialEnquiry}}</span>
        <span>{{formCommon.crmCaseNo}}</span>
      </div>
      
      <div class="line-title">
        <span class="text">{{L.generalData}}</span>
      </div>
      <!-- 基础内容 -->
      <div class="form">

        <div class="square">
          <span class="text">{{L.status}}:</span>
          <div class="line-field">{{formCommon.formStatus}}</div>
        </div>

        <div class="square">
          <span class="text">{{L.caseNo}}:</span>
          <div class="line-field">{{formCommon.crmCaseNo}}</div>
        </div>

        <div class="square">
          <span class="text">{{L.createdDate}}:</span>
          <div class="line-field">{{formatDateNoTime(formCommon.caseDate)}}</div>
        </div>

        <div class="square">
          <span class="text">{{L.customerName}}:</span>
          <div class="line-field">{{formCommon.customerName}}</div>
        </div>

        <div class="square">
          <span class="text">{{L.customerNo}}:</span>
          <div class="line-field">{{formCommon.customerAccountNo}}</div>
        </div>

        <div class="square">
          <span class="text">{{L.customerContact}}:</span>
          <div class="line-field">{{formCommon.contactName}}</div>
        </div>

        <div class="square">
          <span class="text">{{L.customerContactEmail}}:</span>
          <div class="line-field">{{formCommon.contactEmail}}</div>
        </div>

        <div class="square square-long2">
          <span class="text">{{L.addAddiEmail}}:</span>
          <div class="line-field">{{formCommon.crmEmailCopyTo}}</div>
        </div>

        <div class="square square-long2">
          <span class="text">{{L.subject}}:</span>
          <div class="line-field">{{formCommon.subject}}</div>
        </div>

        <div class="square square-long3">
          <span class="text">{{L.description}}:</span>
          <div class="line-field">{{formCommon.description}}</div>
        </div>

      </div>

      <!-- 附件内容 -->
      <div class="file-wrap" v-if="fileList.length>0">
        <div class="title">{{L.fileList}}</div>
        <div class="file-line-wrap">
          <div class="file-line" v-for="item in fileList" :key="item.fileId">
            <span class="iconfont icon-file">&#xe601;</span>
            <span class="file-name" @click="handleDownload(item)">{{item.fileName}}</span>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import {api_claim_detail,api_order_confirm_download} from '@/api/api'
import {getAction,downFileGet} from '@/api/tool'
import Vue from 'vue'

export default {
  name:'CommercialDetail',
  data(){
    return {
      formCommon:{
        crmCaseNo:'',
        caseDate:'',
        formStatus:'',
        customerName:'',
        customerAccountNo:'',
        contactName:'',
        contactEmail:'',
        subject:'',
        description:'',
        crmEmailCopyTo:''
      },
      fileList:[]
    }
  },
  methods:{
    /* 获取详情 */
    getDetail(){
      let params = {
        flag:this.$route.query.flag,
        caseNo:this.$route.query.caseNo
      }
      this.allLoading=true
      getAction(api_claim_detail,params).then(res=>{
        this.allLoading=false
        this.formCommon={
          crmCaseNo:res.crmCaseNo,
          caseDate:res.caseDate,
          formStatus:res.formStatus,
          customerName:res.customerName,
          customerAccountNo:res.customerAccountNo,
          contactName:res.contactName,
          contactEmail:res.contactEmail,
          subject:res.subject,
          description:res.description,
          crmEmailCopyTo:res.crmEmailCopyTo
        }
        this.fileList=res.files
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载文件 */
    handleDownload(obj){
      let params = {
        filezIds:obj.filezId
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    }
  },
  mounted(){
    this.getDetail()
  }
  
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>