<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap my-orders">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <!-- 我的报价单 -->
        <a
          class="text2"
          href="/#/quotesList" 
        >{{L.myQuotes}}</a>
      </div>
      <!-- 我的订单-标题 -->
      <div class="my-orders-title">{{L.myQuotes}}</div>
      <!-- 搜索 -->
      <div class="search-wrap">
        <el-form :model="search" ref="formSearch" :rules="rules" :show-message="false">
          <div class="square">
            <!-- 日期从 -->
            <span class="text">{{L.orderDateFrom}}</span>
            <el-form-item prop="validFrom">
              <el-date-picker
                class="search-field"
                v-model="search.validFrom"
                value-format="yyyy-MM-dd"
                format="dd-MM-yyyy"
                :picker-options="pickerOptions"
                type="date">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="square">
            <!-- 日期到 -->
            <span class="text">{{L.orderDateTo}}</span>
            <el-form-item prop="validTo">
              <el-date-picker
                class="search-field"
                v-model="search.validTo"
                value-format="yyyy-MM-dd"
                format="dd-MM-yyyy"
                :picker-options="pickerOptions"
                type="date">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="square">
            <!-- 编号 -->
            <span class="text">{{L.erpQuoId}}</span>
            <el-form-item prop="quoteNumber">
              <el-input @keyup.enter.native="getList" v-model="search.quoteNumber" class="search-field"></el-input>
            </el-form-item>
          </div>
          <div class="square">
            <span class="text">{{L.quoReference}}</span>
            <el-form-item prop="quoteReference">
              <el-input @keyup.enter.native="getList" v-model="search.quoteReference" class="search-field"></el-input>
            </el-form-item>
          </div>
          <!-- 订单状态-选择框 -->
          <div class="square order-status">
            <!-- 订单状态 -->
            <span class="text">{{L.quoStatus}}</span>
            <div class="select-status" @mouseleave="mouseleave(1)">
              <el-form-item prop="quoteStatus">
                <!-- 订单状态-input框 -->
                <div class="inp-wrap" @click="clickChooseStatus">
                  <div class="left-text">{{statusText}}</div>
                  <div class="arrow">
                    <span class="iconfont icon_arrow" :class="{'active-arrow':show.showChoose}">&#xeb8b;</span>
                  </div>
                </div>
              </el-form-item>
              <!-- 弹框 -->
              <transition
                @enter="enter"
                @afterEnter="afterEnter"
                @leave="leave"
                @afterLeave="afterLeave"
              >
                <div class="choose-big" v-show="show.showChoose">
                  <span class="triangle"></span>
                  <div class="choose-wrap">
                    <div class="choose" @click="chooseStatus(item,i)" v-for="item,i in statusArr" :key="item.statusCode">
                      <div class="line1" :class="{'active-color':item.showTick}">{{item.statusName}}</div>
                      <span class="iconfont icon_tick" v-show="item.showTick">&#xe8e6;</span>
                    </div>
                  </div>
                </div>
              </transition>    
            </div>
          </div>
        </el-form>
      </div>
      <!-- 按钮 -->
      <div class="btn-wrap">
        <div class="left">
          <!-- 订单概述 (xlsx) -->
          <div class="fill" @click="downloadList">Quote overview (xlsx)</div>
          <div class="fill" @click="downloadDetail">Quote details (xlsx)</div>
        </div>
        <div class="right">
          <!-- 清除过滤 -->
          <div class="null" @click="clearFilters">{{L.cleaFilters}}</div>
          <!-- 搜索 -->
          <div class="fill" @click="getList">{{L.search}}</div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="table-wrap">
        <el-table
          :default-sort="{prop:'erpQuoteId',order:'descending'}"
          :data="list"
          style="width: 100%"
        >
          <el-table-column
            prop="erpQuoteId"
            :sortable="true"
            :label="tableLable.erpQuoId">
              <template slot-scope="scope">
                <a 
                  :href="'/#/quotesDetail?erpQuoteId='+scope.row.quoteNumber" 
                  class="order-id"
                >{{scope.row.quoteNumber}}</a>
              </template>
          </el-table-column>
          <el-table-column
            prop="quoteReference"
            sortable
            :label="tableLable.quoReference">
          </el-table-column>
          <el-table-column
            prop="validFrom"
            sortable
            :label="tableLable.orderDateFrom"
          >
            <template slot-scope="scope">
              <span>
                {{ formatDate(scope.row.validFrom) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="validTo"
            sortable
            :label="tableLable.orderDateTo"
          >
            <template slot-scope="scope">
              <span>
                {{ formatDate(scope.row.validTo) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="haveSP"
            prop="totalAmount"
            sortable
            :label="tableLable.totalAmount"
          >
            <template slot-scope="scope">
              <span>{{ formatMoney(scope.row.totalAmount) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="quoteStatus"
            :label="tableLable.quoStatus">
          </el-table-column>
          <!-- 表格没数据的时候展示空组件，要注意这里的slot=empty不能随意更改 -->
          <div slot="empty">
            <NoContent/>
          </div>
        </el-table>
      </div>
    </div>
    
    
  </div>
</template>

<script>
import {postAction,downFilePost} from '@/api/tool'
import {api_quote_list,api_quote_status,api_quote_list_download,api_quote_detail_download} from '@/api/api'
import NoContent from '@/components/NoContent'
// import moment from 'moment'

export default {
  name:'QuotesList',
  components:{
    NoContent
  },
  data(){
    return {
      show:{
        showChoose:false
      },
      search:{
        validFrom:'',
        validTo:'',
        quoteNumber:'',
        quoteReference:'',
        quoteStatus:[]
      },
      rules:{
        // validFrom:[{required:true,trigger:'blur'}],
        // validTo:[{required:true,trigger:'blur'}]
      },
      statusText:'', //状态，用逗号隔开(搜索那边的状态)
      list:[], //列表数据
      statusArr:[], //status下拉数据
      pickerOptions:{
        disabledDate: e => {
          // let chooseDate = moment(new Date(e)).format('YYYY-MM-DD') //当选择的日期
          // let chooseTime = new Date(chooseDate).valueOf() //选择的时间戳
          // let date = moment(new Date()).format('YYYY-MM-DD') //当前日期
          // let dateTime = new Date(date).valueOf() //当前日期时间戳
          // //小于等于今天的时间，可以选择。反之不能选择
          // if(chooseTime<=dateTime){
          //   return false
          // }else{
          //   return true
          // }
          console.log(e)
          return false
        }
      },//日期
      tableLable:{
        orderDateFrom:'',
        orderDateTo:'',
        erpQuoId:'',
        quoReference:'',
        quoStatus:''
      } //表格的表头，因为这个地方切换语言的时候没办法正常渲染，所以需要监听语言包的变化，进行重新渲染
    }
  },
  computed:{
    haveSP(){
      return this.$store.state.userInfo.accountRules && this.$store.state.userInfo.accountRules.includes('SP')
    }
  },
  watch:{
    L(newVal){
      this.tableLable=newVal
    }
  },
  methods:{
    mouseleave(flag){
      if(flag==1){
        this.show.showChoose=false
      }
    },
    /* 单击order status的下拉框，上面的 */
    clickChooseStatus(){
      this.show.showChoose=true
    },
    /* 选择下拉列表中的其中一项 */
    chooseStatus(item,i){
      //点击all的，如果是全选，或者全不选
      if(item.statusCode=='ALL'){
        let finalResult = !item.showTick
        this.statusArr.forEach((val,i)=>{
          val.showTick=finalResult
          this.$set(this.statusArr,i,val) //触发渲染
        })
      }else{
        //点击除了all之外的
        item.showTick=!item.showTick
        this.$set(this.statusArr,i,item) //触发渲染
        let allFlag = true //标记是true，说明默认是全部都勾上的
        this.statusArr.forEach((val)=>{
          //不是all的里面如果有一个是false,那all的那个选择就是false
          if(!val.showTick && val.statusCode!='ALL'){
            allFlag=false
          }
        })
        //标记完成后，找到all的那个钩子，去勾上或者不勾上
        this.statusArr.forEach((val,i3)=>{
          if(val.statusCode=='ALL'){
            val.showTick=allFlag
            this.$set(this.statusArr,i3,val) //触发渲染
          }
        })
      }
      //无论哪种情况结束后，都需要重新设置statusText和search.quoteStatus
      let newStatusText=[] //打钩的文字，排成数组
      let newStatusCode=[] //打钩的id，排成数组
      this.statusArr.forEach((val)=>{
        if(val.showTick){
          newStatusText.push(val.statusName)
          newStatusCode.push(val.statusCode)
        }
      })
      this.statusText=newStatusText.join(',')
      this.search.quoteStatus=newStatusCode
    },
    /*
      方法-传入需要选中的数组
      1.把下拉框的值自动勾选上
      2.把下拉框的input框自动填入
      3.把搜索quoteStatus参数进行更正
    */
    setSelectStatus(arr){
      let chooseArr = arr
      let newStatusText=[] //打钩的文字，排成数组
      let newStatusCode=[] //打钩的id，排成数组
      this.statusArr.forEach((val,i)=>{
        if(chooseArr.includes(val.statusCode)){
          val.showTick=true
          newStatusText.push(val.statusName)
          newStatusCode.push(val.statusCode)
          this.$set(this.statusArr,i,val) //触发渲染，把下拉框的值自动勾选上
        }else{
          val.showTick=false
        }
      })
      this.statusText=newStatusText.join(',') //把下拉框的input框自动填入
      this.search.quoteStatus=newStatusCode //把搜索quoteStatus参数进行更正
    },
    /* 获取订单列表 */
    getList(){
      this.$refs.formSearch.validate(valid=>{
        if(!valid){
          this.$message.warning('Please complete the required fields.');
        }else{
          this.allLoading=true
          postAction(api_quote_list,this.search).then(res=>{
            this.allLoading=false
            this.list=res
            // this.setSelectStatus(res.status) 反向勾选状态的逻辑先不要
          }).catch(()=>{
            this.allLoading=false
          })
        }
      })
    },
    /* 下载列表 */
    downloadList(){
      this.allLoading=true
      downFilePost(api_quote_list_download,this.search).then(res=>{
        this.allLoading=false
        this.downloadFile(res)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载详情 */
    downloadDetail(){
      this.allLoading=true
      downFilePost(api_quote_detail_download,this.search).then(res=>{
        this.allLoading=false
        this.downloadFile(res)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 重置搜索条件并且直接请求数据 */
    clearFilters(){
      this.search={
        validFrom:'',
        validTo:'',
        quoteNumber:'',
        quoteReference:'',
        quoteStatus:[]
      }
      //清空status的数据和下拉框的打钩
      this.statusText=''
      this.statusArr.forEach((val,i)=>{
        this.statusArr[i].showTick=false
      })
      this.setDefaultDate()
    },
    handleClearFilters(){
      this.clearFilters()
      this.getList()
    },
    /* 设置默认日期,进到这个路由的时候要设置一下默认日期 */
    setDefaultDate(){
      // let date = new Date()
      // this.search.validTo = moment(date).format('YYYY-MM-DD')
      this.search.validTo = ''
      // this.search.validFrom = moment(date).subtract(3,'months').format('YYYY-MM-DD') //回退3个月
      this.search.validFrom = ''
    },
    /* 获取order status和ship to的下拉数据 */
    getSelectData(){
      postAction(api_quote_status).then(res=>{
        this.statusArr=res
        this.search.quoteStatus=[]
      })
    }
  },
  mounted(){
    this.tableLable=this.L
    this.setDefaultDate() //注意这个方法要在getList之前，因为在最开始加载请求的时候，需要先把日期设置进去并且传给后端再去请求数据
    this.getSelectData()
    this.getList()
  }
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>

