<template>
  <div class="accordion-wrap"> 
    <div class="line" v-for="item in list" :key="item.id">
      <div class="title" @click="handleAccordionLine(item)">
        <span class="text">{{item.title}}</span>
        <span class="iconfont icon_arrow">&#xe627;</span>
      </div>
      <div class="content" ref="contentRef" :data-lineid="item.id" :data-show="item.show">
        <div class="content-line" v-for="item2,i in item.content" :key="i">
          <div class="short-line">-</div>
          <div class="text">{{item2}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Accordion',
  props:{
    list:{
      type: [Array],
      required: true
    }
  },
  methods:{
    //获取手风琴每一行的高度
    getAccordionLineHeight(){
      let objs = this.$refs.contentRef
      if(Array.isArray(objs) && objs.length>0){
        objs.forEach((val)=>{
          val.dataset.height=Number(val.offsetHeight)+"px"
          val.style.height="0px"
        })
      }
    },
    //点击手风琴中的其中一行
    handleAccordionLine(item){
      let objs = this.$refs.contentRef
      if(Array.isArray(objs) && objs.length>0){
        objs.forEach((val)=>{
          if(item.id==val.dataset.lineid){
            if(val.dataset.show==0){
              //展示
              val.style.height=val.dataset.height
              val.dataset.show=1
              //改变右边箭头的方向
              val.previousElementSibling.children[1].style.transform="rotate(0deg)"
              val.style.borderBottom='1px solid #ccc';
            }else{
              //不展示
              val.style.overflow='hidden'
              val.style.height='0px'
              val.dataset.show=0
              //改变右边箭头的方向
              val.previousElementSibling.children[1].style.transform="rotate(-90deg)"
              val.style.borderBottom='none'
            }
          }
        })
      }
    }
  },
  mounted(){
    this.getAccordionLineHeight()
  }
}


</script>


<style lang="less" scoped>
@import url('../../public/css/public.less');
.accordion-wrap{
  .line{
    .title{
      // background-color: orange;
      padding:14px 0;
      border-bottom: 1px solid #ccc;
      &:hover{
        cursor: pointer;
      }
      .text{
        display: inline-block;
        vertical-align: middle;
        // background-color: orange;
        font-weight: 600;
        width: calc(100% - 20px);
        line-height: 20px;
      }
      .icon_arrow{
        font-size: 17px;
        // background-color: pink;
        color:@mainColor;
        display: inline-block;
        vertical-align: middle;
        // width:50px;
        text-align: right;
        transform:rotate(-90deg);
        &:extend(.transtion-time);
      }
    }
    .content{
      // background-color: pink;
      height:auto;
      overflow: hidden;
      transition: all 0.7s;
      // background-color: pink;
      .content-line{
        margin-top:10px;
        // background-color: gray;
        display: flex;
        &:last-child{
          margin-bottom:10px;
        }
        .short-line{
          width:20px;
          display: flex;
          line-height: 20px; //跟下面文字行高保持一致
          color:@mainColor;
        }
        .text{
          width:calc(100% - 20px);
          line-height: 20px;
        }
      }
    }
  }
}

</style>