<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap fieldFailuers-wrap">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/selectRMAtype" 
        >My Service Point</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/rmaList" 
        >My Tickets</a>
        <span class="line">/</span>
        <a
          class="text text2"
          :href="'/#/commercialDetail?flag='+$route.query.flag+'&caseNo='+$route.query.caseNo" 
        >{{formCommon.crmCaseNo}}</a>
      </div>

      <div class="big-title">
        <span class="text1">Commercial/Logistic Incident</span>
        <span>{{formCommon.crmCaseNo}}</span>
      </div>
      
      <div class="line-title">
        <span class="text">General data</span>
      </div>
      <!-- 基础内容 -->
      <div class="form">

        <div class="square">
          <span class="text">Status:</span>
          <div class="line-field">{{formCommon.formStatus}}</div>
        </div>
        
        <div class="square">
          <span class="text">Case No.:</span>
          <div class="line-field">{{formCommon.crmCaseNo}}</div>
        </div>

        <div class="square">
          <span class="text">Date:</span>
          <div class="line-field">{{formatDateNoTime(formCommon.caseDate)}}</div>
        </div>

        <div class="square">
          <span class="text">Customer name:</span>
          <div class="line-field">{{formCommon.customerName}}</div>
        </div>

        <div class="square">
          <span class="text">Customer No.:</span>
          <div class="line-field">{{formCommon.customerAccountNo}}</div>
        </div>

        <div class="square">
          <span class="text">Customer Contact Name:</span>
          <div class="line-field">{{formCommon.contactName}}</div>
        </div>

        <div class="square">
          <span class="text">Customer Contact Email:</span>
          <div class="line-field">{{formCommon.contactEmail}}</div>
        </div>

      </div>
      <!-- 列表内容 -->
      <div v-for="(item,i) in productList" :key="i">
        <div class="line-title">
          <span class="text">{{i+1}}° Product information</span>
        </div>
        <div class="form">

          <div class="square">
            <span class="text">Type:</span>
            <div class="line-field">{{getSelectText(typeArr,item.incidentType)}}</div>
          </div>

          <div class="square">
            <span class="text">Problem:</span>
            <div class="line-field">{{item.problem}}</div>
          </div>

          <div class="square">
            <span class="text">Order No.:</span>
            <div class="line-field">{{item.orderNo}}</div>
          </div>

          <div class="square">
            <span class="text">Date of purchase:</span>
            <div class="line-field">{{formatDateNoTime(item.purchaseDate)}}</div>
          </div>

          <div class="square">
            <span class="text">Delivery date:</span>
            <div class="line-field">{{formatDateNoTime(item.deliveryDate)}}</div>
          </div>

          <div class="square">
            <span class="text">Delivery note:</span>
            <div class="line-field">{{item.deliveryNote}}</div>
          </div>

          <div class="square">
            <span class="text">Product:</span>
            <div class="line-field">{{item.claimedProduct}}</div>
          </div>

          <div class="square">
            <span class="text">Claimed Qty:</span>
            <div class="line-field">{{item.claimedQty}}</div>
          </div>

          <div class="square">
            <span class="text">Identcode:</span>
            <div class="line-field">{{item.identcode}}</div>
          </div>

          <div class="square">
            <span class="text">Date code:</span>
            <div class="line-field">{{item.dateCode}}</div>
          </div>

        </div>
      </div>
      <!-- 附件内容 -->
      <div class="file-wrap" v-if="fileList.length>0">
        <div class="title">File Name</div>
        <div class="file-line-wrap">
          <div class="file-line" v-for="item in fileList" :key="item.fileId">
            <span class="iconfont icon-file">&#xe601;</span>
            <span class="file-name" @click="handleDownload(item)">{{item.fileName}}</span>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import {api_claim_detail,api_claim_select,api_order_confirm_download} from '@/api/api'
import {getAction,downFileGet} from '@/api/tool'
import Vue from 'vue'

export default {
  name:'CommercialDetail',
  data(){
    return {
      formCommon:{
        crmCaseNo:'',
        caseDate:'',
        formStatus:'',
        customerName:'',
        customerAccountNo:'',
        contactName:'',
        contactEmail:''
      },
      fileList:[],
      productList:[],
      typeArr:[] //下拉数据
    }
  },
  methods:{
    /* 获取下拉数据 */
    getSelect(){
      let params = {
        formType:'ce_type'
      }
      getAction(api_claim_select,params).then(res=>{
        res.forEach((val)=>{
          if(val.formType=='ce_type'){
            this.typeArr=val.claimSelect
          }
        })
      })
    },
    /* 获取详情 */
    getDetail(){
      let params = {
        flag:this.$route.query.flag,
        caseNo:this.$route.query.caseNo
      }
      this.allLoading=true
      getAction(api_claim_detail,params).then(res=>{
        this.allLoading=false
        this.formCommon={
          crmCaseNo:res.crmCaseNo,
          caseDate:res.caseDate,
          formStatus:res.formStatus,
          customerName:res.customerName,
          customerAccountNo:res.customerAccountNo,
          contactName:res.contactName,
          contactEmail:res.contactEmail
        }
        this.productList=res.lineVoList
        this.fileList=res.files
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载文件 */
    handleDownload(obj){
      let params = {
        filezIds:obj.filezId
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    }
  },
  mounted(){
    this.getSelect()
    this.getDetail()
  }
  
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>